import React, { useState } from 'react';
import LoginContent from './LoginContent';
import RegisterContent from './RegisterContent';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';


export default function Login({ setToken,setUser }) {
     const [currentForm, setCurrentForm] = useState(true);

     const toggleForm = () => {
          setCurrentForm((prevState) => !prevState);
     };

     return (
          <>
               <Navbar />
               <div className="login">

                 {currentForm ? <LoginContent onToggle={toggleForm} setToken={setToken} setUser={setUser} /> : <RegisterContent onToggle={toggleForm} />}

               </div>
               <Footer />
          </>
     )
}
