import React, { useState } from 'react';
import male from '../images/male.png';
import female from '../images/female.png';
import others from '../images/others.png';
import kids from '../images/kids.png';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import spinner from '../images/spinner.gif'; // Import your spinner image

import RythuBandham from './RythuBandham';
const API_URL = 'https://manamvanam.com/api';


const RegisterContent = ({ onToggle }) => {
     const [selectedFile, setSelectedFile] = useState(null);
     // const [step, setStep] = useState(1);

     // const nextStep = () => {
     //      setStep(step + 1);
     // };

     // const prevStep = () => {
     //      setStep(step - 1);
     // };

     const [formData, setFormData] = useState({
          firstName: '',
          lastName: '',
          //date: '',
          gender: '',
          phoneNumber: '',
          email: '',
          // address: '',
          // city: '',
          // state: '',
          // country: '',
          // zipcode: '',
          password: '',
          // profileImage: null,
     });

     const [errors, setErrors] = useState({
          firstName: '',
          lastName: '',
          //date: '',
          gender: '',
          phoneNumber: '',
          email: '',
          // address: '',
          // city: '',
          // state: '',
          // country: '',
          // zipcode: '',
          password: '',
          // profileImage: '',
     });

     const [submitted, setSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // State to manage loader visibility

     const validateInput = (name, value) => {
          const errorMessages = {
               firstName: 'First name is required and should only contain characters with no symbols or spaces.',
               lastName: 'Last name should only contain characters with no symbols or spaces.',
               //date: 'Date is required.',
               gender: 'Gender is required.',
               phoneNumber: 'Phone number should only contain numbers, spaces, and "+" with a maximum of 15 digits.',
               email: 'Invalid email address.',
               // address: 'Address is required.',
               // city: 'City is required.',
               // state: 'State is required.',
               // country: 'Country is required.',
               // zipcode: 'Invalid zipcode.',
               password: 'Password is required and should be at least 8 characters long.',
          };

          switch (name) {
               case 'firstName':
                    if (!value.trim()) {
                         return errorMessages.firstName;
                    }
                    if (!/^[a-zA-Z]+$/.test(value)) {
                         return errorMessages.firstName;
                    }
                    break;

               case 'lastName':
                    if (value && !/^[a-zA-Z]+$/.test(value)) {
                         return errorMessages.lastName;
                    }
                    break;
               //case 'date':
               //     if (!value.trim()) {
               //          return errorMessages.date;
               //     }
               //     break;
               case 'gender':
                    if (!value) {
                         return errorMessages.gender;
                    }
                    break;
               case 'phoneNumber':
                    if (!value.trim()) {
                         return errorMessages.phoneNumber;
                    }
                    if (!/^[\d+ ]{0,15}$/.test(value)) {
                         return errorMessages.phoneNumber;
                    }
                    break;
               case 'email':
                    if (!/\S+@\S+\.\S+/.test(value)) {
                         return errorMessages.email;
                    }
                    break;
               // case 'address':
               // case 'city':
               // case 'state':
               // case 'country':
               //      if (!value.trim()) {
               //           return errorMessages[name];
               //      }
               //      break;
               // case 'zipcode':
               //      if (!/^\d{6}$/.test(value)) {
               //           return errorMessages.zipcode;
               //      }
               //      break;
               case 'password':
                    if (!value.trim()) {
                         return errorMessages.password;
                    }
                    if (value.length < 8) {
                         return errorMessages.password;
                    }
                    break;
               default:
                    break;
          }
          return '';
     };

     const handleChange = (e) => {
          const { name, value, type, checked } = e.target;
          const newValue =
               type === 'checkbox'
                    ? formData.interestedIn.includes(value)
                         ? formData.interestedIn.filter((item) => item !== value)
                         : [...formData.interestedIn, value]
                    : value;

          // Validate the last name field if it's entered (not empty)
          const errorMessage = name === 'lastName' && value.trim() ? validateInput(name, newValue) : '';

          setErrors({ ...errors, [name]: errorMessage });
          setFormData({ ...formData, [name]: newValue });
          if (e.target.files != undefined
               && e.target.files.length > 0)
               setSelectedFile(e.target.files[0]);

     };

     const handleSubmit = async (e) => {
          e.preventDefault();
         setIsLoading(true); // Show loader
          // Validate all form fields on submit
          const newErrors = {};
          Object.keys(formData).forEach((fieldName) => {
               const errorMessage = validateInput(fieldName, formData[fieldName]);
               newErrors[fieldName] = errorMessage;
          });
         console.log(newErrors);
          setErrors(newErrors);

          // Check if there are any errors in the form
          const formHasErrors = Object.values(newErrors).some((error) => error !== '');

          if (!formHasErrors) {
               // Perform form submission logic here (e.g., send data to the server)
               //const submitFormData = new FormData();
               //submitFormData.append('profileImageFile', selectedFile);
               //submitFormData.append('userData', JSON.stringify({
               //    "FirstName": formData.firstName,
               //    "LastName": formData.lastName,
               //    "DOB": formData.date,
               //    "EmailAddress": formData.email,
               //    "Gender": formData.gender,
               //    "PhoneNumber": formData.phoneNumber,
               //    "HomeAddress": formData.address,
               //    "City": formData.city,
               //    "State": formData.state,
               //    "Country": formData.country,
               //    "PostalCode": formData.zipcode,
               //    "ProfilePictureUrl": formData.profileImage,
               //    "Password": formData.password
               //}));
               //const response =await axios.post(`${API_URL}/RegisterUser`, submitFormData, {
               //    headers: {
               //        'Content-Type': 'multipart/form-data'
               //    }
              //});
              //const submitJsonData = {
              //    "FirstName": formData.firstName,
              //    "LastName": formData.lastName,
              //    "DOB": "",
              //    "EmailAddress": formData.email,
              //    "Gender": formData.gender,
              //    "PhoneNumber": formData.phoneNumber,
              //    "HomeAddress": "",
              //    "City": "",
              //    "State": "",
              //    "Country": "",
              //    "PostalCode": "",
              //    "ProfilePictureUrl": "",
              //    "Password": formData.password
              //};
               const submitFormData = new FormData();
               submitFormData.append('profileImageFile', selectedFile);

               // Append each field individually as FormData
               submitFormData.append('FirstName', formData.firstName);
               submitFormData.append('LastName', formData.lastName);
               //submitFormData.append('DOB', formData.date);
               submitFormData.append('EmailAddress', formData.email);
               submitFormData.append('Gender', formData.gender);
               submitFormData.append('PhoneNumber', formData.phoneNumber);
               //     submitFormData.append('HomeAddress', formData.address);
               //     submitFormData.append('City', formData.city);
               //     submitFormData.append('State', formData.state);
               //     submitFormData.append('Country', formData.country);
               //     submitFormData.append('PostalCode', formData.zipcode);
               //     submitFormData.append('ProfilePictureUrl', formData.profileImage);
               submitFormData.append('Password', formData.password);
               var response = null;
               var responseErrors = null;
              try {

                  response = await axios.post(`${API_URL}/RegisterUser`, submitFormData, {
                      headers: {
                          'Content-Type': 'multipart/form-data'
                      }
                  });
                  //response = await axios.post(`${API_URL}/RegisterUser`, submitJsonData, {
                  //    headers: {
                  //        'Content-Type': 'multipart/form-data'
                  //    }
                  //});
                  console.log(response);
                  if (response.status === 200) {
                      console.log('User registered:', response.data);
                      // Display a success message to the user
                      setSubmitted(true);
                      setFormData({
                          firstName: '',
                          lastName: '',
                          gender: '',
                          phoneNumber: '',
                          email: '',
                          password: '',
                      });
                  } else {
                      console.error('User registration failed:', response.data);
                      // Display an error message to the user
                      alert('User registration failed. Please try again.');
                      responseErrors = response.data.errors;
                      if (responseErrors != null) {

                          setErrors({
                              firstName: responseErrors.FirstName?.[0],
                              lastName: responseErrors.LastName?.[0],
                              //date: responseErrors.DOB?.[0],
                              phoneNumber: responseErrors.PhoneNumber?.[0],
                              email: responseErrors.EmailAddress?.[0],
                              password: responseErrors.Password?.[0],
                              //profileImage: responseErrors.ProfilePictureUrl?.[0]
                          });
                      }
                  }
              } catch (e) {
                  console.log(e);
                  responseErrors = e.response.data.errors;
                  if (responseErrors != null) {
                      setErrors({
                          firstName: responseErrors.FirstName?.[0],
                          lastName: responseErrors.LastName?.[0],
                          //date: responseErrors.DOB?.[0],
                          phoneNumber: responseErrors.PhoneNumber?.[0],
                          email: responseErrors.EmailAddress?.[0],
                          password: responseErrors.Password?.[0],
                          // profileImage: responseErrors.ProfilePictureUrl?.[0]
                      });
                  }
              } finally {
                  setIsLoading(false); // Hide loader
              }

          } else {
              setIsLoading(false); // Hide loader if there are validation errors
          }
     };

     // const renderStepOne = () => {
     //      return (
     //           <>

     //           </>
     //      );
     // };

     // const renderStepTwo = () => {
     //      return (
     //           <>

     //           </>
     //      );
     // };

     // const renderForm = () => {
     //      switch (step) {
     //           case 1:
     //                return renderStepOne();
     //           case 2:
     //                return renderStepTwo();
     //           default:
     //                return null;
     //      }
     // };

     return (
         <>
             {isLoading && (
                 <div className="loader-main">
                     <div className="loader-sub">
                         <img src={spinner} alt="Loading..." />
                     </div>
                 </div>
             )}
               <div className="row no-gutters w-100">
                    <div className="bg-right col-lg-6">
                         <div className="register-form d-grid text-center">
                              <span className="tagline">Donate</span>
                              <h1>Register your account</h1>
                              <p className="mb-30">Already registered ? <NavLink className="cust-link" onClick={onToggle}><b>Signin Here</b></NavLink></p>
                              <form className="form-reg" onSubmit={handleSubmit} >
                                   {/* {renderForm()} */}
                                   <div className="row">
                                        <div className="col">
                                             <div className="form-group text-left">
                                                  <label>First Name</label>
                                                  <input
                                                       type="text"
                                                       className={`form-control mb-0 ${errors.firstName ? 'is-invalid' : ''}`}
                                                       id="firstName"
                                                       name="firstName"
                                                       value={formData.firstName}
                                                       onChange={handleChange}
                                                       placeholder="Enter your Name"
                                                  />
                                                  {errors.firstName && <div className="invalid-feedback">{errors.firstName}</div>}
                                             </div>
                                        </div>
                                        <div className="col">
                                             <div className="form-group text-left">
                                                  <label>Last Name</label>
                                                  <input
                                                       type="text"
                                                       className={`form-control mb-0 ${errors.lastName ? 'is-invalid' : ''}`}
                                                       id="lastName"
                                                       name="lastName"
                                                       value={formData.lastName}
                                                       onChange={handleChange}
                                                       placeholder="Enter your Name"
                                                  />
                                                  {errors.lastName && <div className="invalid-feedback">{errors.lastName}</div>}
                                             </div>
                                        </div>
                                   </div>
                                   <div className="row">
                                        {/* <div className="col">
                                             <div className="form-group text-left">
                                                  <label>DOB</label>
                                                  <input
                                                       type="date"
                                                       className={`form-control mb-0 ${errors.date ? 'is-invalid' : ''}`}
                                                       id="date"
                                                       name="date"
                                                       placeHolder='DOB'
                                                       value={formData.date}
                                                       onChange={handleChange}
                                                  />
                                                  {errors.date && <div className="invalid-feedback">{errors.date}</div>}
                                             </div>
                                        </div> */}
                                        <div className="col">
                                             <div className="form-group text-center">
                                                  <div className="d-flex justify-content-left">
                                                       <label className="mr-10 pt-10">Gender : </label>
                                                       {/* Male Radio Button */}
                                             <label className="mr-10 pt-10">
                                                            <input
                                                                 type="radio"
                                                                 name="gender"
                                                                 value="male"
                                                                 checked={formData.gender === 'male'}
                                                                 onChange={handleChange}
                                                                 //style={{ display: 'none' }} // Hide the actual radio button
                                                            />
                                                 {/*           <img*/}
                                                 {/*                src={male} // Replace with the actual path to the male image*/}
                                                 {/*                alt="Male"*/}
                                                 {/*                style={{*/}
                                                 {/*                     width: '25px',*/}
                                                 {/*                     marginRight: "5px",*/}
                                                 {/*                     cursor: 'pointer',*/}
                                                 {/*                     filter: formData.gender === 'male' ? 'brightness(0) saturate(100%) invert(58%) sepia(74%) saturate(413%) hue-rotate(42deg) brightness(96%) contrast(84%)' : 'none',*/}
                                                 {/*                }}*/}
                                                 {/*                onClick={() => handleChange({ target: { name: 'gender', value: 'male' } })}*/}
                                                 {/*/>*/}
                                                 <span>Male</span>
                                                       </label>

                                                       {/* Female Radio Button */}
                                             <label className="mr-10 pt-10">
                                                            <input
                                                                 type="radio"
                                                                 name="gender"
                                                                 value="female"
                                                                 checked={formData.gender === 'female'}
                                                                 onChange={handleChange}
                                                                // style={{ display: 'none' }} // Hide the actual radio button
                                                            />
                                                 {/*           <img*/}
                                                 {/*                src={female}  // Replace with the actual path to the female image*/}
                                                 {/*                alt="Female"*/}
                                                 {/*                style={{*/}
                                                 {/*                     width: '25px',*/}
                                                 {/*                     marginRight: "5px",*/}
                                                 {/*                     cursor: 'pointer',*/}
                                                 {/*                     filter: formData.gender === 'female' ? 'brightness(0) saturate(100%) invert(58%) sepia(74%) saturate(413%) hue-rotate(42deg) brightness(96%) contrast(84%)' : 'none',*/}
                                                 {/*                }}*/}
                                                 {/*                onClick={() => handleChange({ target: { name: 'gender', value: 'female' } })}*/}
                                                 {/*/>*/}
                                                 <span>Female</span>
                                                       </label>

                                                       {/* Other Radio Button */}
                                             <label className="mr-10 pt-10">
                                                            <input
                                                                 type="radio"
                                                                 name="gender"
                                                                 value="other"
                                                                 checked={formData.gender === 'other'}
                                                                 onChange={handleChange}
                                                                 //style={{ display: 'none' }} // Hide the actual radio button
                                                            />
                                                 {/*           <img*/}
                                                 {/*                src={others}  // Replace with the actual path to the other image*/}
                                                 {/*                alt="Other"*/}
                                                 {/*                style={{*/}
                                                 {/*                     width: '25px',*/}
                                                 {/*                     marginRight: "5px",*/}
                                                 {/*                     cursor: 'pointer',*/}
                                                 {/*                     filter: formData.gender === 'other' ? 'brightness(0) saturate(100%) invert(58%) sepia(74%) saturate(413%) hue-rotate(42deg) brightness(96%) contrast(84%)' : 'none',*/}
                                                 {/*                }}*/}
                                                 {/*                onClick={() => handleChange({ target: { name: 'gender', value: 'other' } })}*/}
                                                 {/*/>*/}
                                                 <span>Other</span>
                                                       </label>
                                                  </div>
                                                  {errors.gender && <div className="invalid-feedback d-block text-left">{errors.gender}</div>}
                                             </div>
                                        </div>
                                        <div className="col">
                                             <div className="form-group text-left">
                                                  <label>Phone Number</label>
                                                  <input
                                                       type="text"
                                                       className={`form-control mb-0 ${errors.phoneNumber ? 'is-invalid' : ''}`}
                                                       id="phoneNumber"
                                                       name="phoneNumber"
                                                       value={formData.phoneNumber}
                                                       onChange={handleChange}
                                                       placeholder="Enter number"
                                                  />
                                                  {errors.phoneNumber && <div className="invalid-feedback">{errors.phoneNumber}</div>}
                                             </div>
                                        </div>
                                   </div>
                                   <div className="row">

                                        <div className="col">
                                             <div className="form-group text-left">
                                                  <label>Email</label>
                                                  <input
                                                       type="email"
                                                       className={`form-control mb-0 ${errors.email ? 'is-invalid' : ''}`}
                                                       id="email"
                                                       name="email"
                                                       value={formData.email}
                                                       onChange={handleChange}
                                                       placeholder="Enter Email"
                                                  />
                                                  {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                             </div>
                                        </div>
                                        <div className="col">
                                             <div className="form-group text-left">
                                                  <label>Enter Password</label>
                                                  <input
                                                       type="password"
                                                       className={`form-control mb-0 ${errors.password ? 'is-invalid' : ''}`}
                                                       id="password"
                                                       name="password"
                                                       value={formData.password}
                                                       onChange={handleChange}
                                                       placeholder="Enter Password"
                                                  />
                                                  {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                                             </div>
                                        </div>
                                   </div>
                                   {/* <div className="text-right p-15">
                                        <button type="button" className="btn btn-primary" onClick={nextStep}>
                                             Next
                                        </button>
                                   </div> */}
                                   {/* <div className="row">
                                        <div className="col">
                                             <div className="form-group text-left">
                                                  <input
                                                       type="text"
                                                       className={`form-control mb-0 ${errors.address ? 'is-invalid' : ''}`}
                                                       id="address"
                                                       name="address"
                                                       value={formData.address}
                                                       onChange={handleChange}
                                                       placeholder="Address"
                                                  />
                                                  {errors.address && <div className="invalid-feedback">{errors.address}</div>}
                                             </div>
                                        </div>
                                   </div>
                                   <div className="row">
                                        <div className="col">
                                             <div className="form-group text-left">
                                                  <input
                                                       type="text"
                                                       className={`form-control mb-0 ${errors.city ? 'is-invalid' : ''}`}
                                                       id="city"
                                                       name="city"
                                                       value={formData.city}
                                                       onChange={handleChange}
                                                       placeholder="City"
                                                  />
                                                  {errors.city && <div className="invalid-feedback">{errors.city}</div>}
                                             </div>
                                        </div>

                                        <div className="col">
                                             <div className="form-group text-left">
                                                  <input
                                                       type="text"
                                                       className={`form-control mb-0 ${errors.state ? 'is-invalid' : ''}`}
                                                       id="state"
                                                       name="state"
                                                       value={formData.state}
                                                       onChange={handleChange}
                                                       placeholder="State"
                                                  />
                                                  {errors.state && <div className="invalid-feedback">{errors.state}</div>}
                                             </div>
                                        </div>
                                   </div>
                                   <div className="row">
                                        <div className="col">
                                             <div className="form-group text-left">
                                                  <input
                                                       type="text"
                                                       className={`form-control mb-0 ${errors.country ? 'is-invalid' : ''}`}
                                                       id="country"
                                                       name="country"
                                                       value={formData.country}
                                                       onChange={handleChange}
                                                       placeholder="Country"
                                                  />
                                                  {errors.country && <div className="invalid-feedback">{errors.country}</div>}
                                             </div>
                                        </div>

                                        <div className="col">
                                             <div className="form-group text-left">
                                                  <input
                                                       type="text"
                                                       className={`form-control mb-0 ${errors.zipcode ? 'is-invalid' : ''}`}
                                                       id="zipcode"
                                                       name="zipcode"
                                                       value={formData.zipcode}
                                                       onChange={handleChange}
                                                       placeholder="Zipcode"
                                                  />
                                                  {errors.zipcode && <div className="invalid-feedback">{errors.zipcode}</div>}
                                             </div>
                                        </div>
                                   </div> */}
                                   <div className="row">


                                        {/* <div className="col">
                                             <div className="form-group text-left">
                                                  <input
                                                       type="file"
                                                       className={`form-control mb-0 ${errors.profileImage ? 'is-invalid' : ''}`}
                                                       id="profileImage"
                                                       name="profileImage"
                                                       onChange={handleChange}
                                                  />
                                                  {errors.profileImage && <div className="invalid-feedback">{errors.profileImage}</div>}
                                             </div>
                                        </div> */}
                                   </div>
                                   <div className="text-right p-15">
                                        {/* <button type="button" className="btn btn-outline" onClick={prevStep}>
                              Previous
                         </button> */}
                                        <button type="submit" className="btn btn-primary">
                                             Submit
                                        </button>
                                   </div>
                              </form>

                              {/* Popup to show after form submission */}
                              {submitted && (
                                   <div className="modal d-block">
                                        <div className="modal-dialog">
                                             <div className="modal-content">
                                                  <div className="modal-header">
                                                       <h5 className="modal-title">Form Submitted!</h5>
                                                       <button type="button" className="close" onClick={() => setSubmitted(false)}>
                                                            &times;
                                                       </button>
                                                  </div>
                                                  <div className="modal-body">
                                                       <p>Thank you for registering. You can now login to your portal</p>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              )}
                         </div></div >
                    <div className="bg-left col-lg-6">
                         <div className="background-image" >
                              <h3 className="centered-h2">"It's not how much you give, but how much love we put into giving"</h3>
                              <p className="centered-p"><b>- Mother Teresa</b></p>
                              <img className="bottom-image" src={kids} alt="Kids" />
                         </div>
                    </div>

               </div >
          </>
     )
}

export default RegisterContent;
