import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import { NavLink, useLocation } from 'react-router-dom';
import { useTabContext } from '../components/TabContext';
//import servicesData from '../components/services_data.json';
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2'; // Import Pie from react-chartjs-2
import ChartDataLabels from 'chartjs-plugin-datalabels';
import arrow from '../images/arrow.png';
import RythubandhamBefore from '../images/Rythubandham-before.png';
import RythubandhamAfter from '../images/Rythubandham-after.png';
import GirijanabandhamBefore from '../images/Girijanabandham-before.png';
import GirijanabandhamAfter from '../images/Girijanabandham-after.png';
import GramabandhamBefore from '../images/Gramabandham-before.png';
import GramabandhamAfter from '../images/Gramabandham-after.png';
import JanahitabandhamBefore from '../images/Janahitabandham-before.png';
import JanahitabandhamAfter from '../images/Janahitabandham-after.png';
import ParyavaranambandhamBefore from '../images/Paryavaranambandham-before.png';
import ParyavaranambandhamAfter from '../images/Paryavaranambandham-after.png';
import axios from 'axios';

const SERVICE_API_URL = 'https://manamvanam.com/api/ServiceDataWithHome';
//const SERVICE_API_URL = '/api/ServiceDataWithHome';
export default function Home() {
    const { activeTab, handleTabSelect } = useTabContext();
    
    const [activeTabs, setActiveTabs] = useState({
        home: 'spent',
        rythubandham: 'spent',
        girijanabandham: 'spent',
        gramabandham: 'spent',
        janahitabandham: 'spent',
        paryavaranambandham: 'spent',
    });
    const [servicesData, setServiceData] = useState([]);
    const [rythubandhamData, setRythubandhamData] = useState({});


    const [servicesDataObj, setServiceDataObj] = useState({
        homeData: null,
        homeSpendData: null,
        homeRaisedData: null,
        rythubandhamData: null,
        rythubandhamSpendData: null,
        rythubandhamRaisedData: null,
        girijanabandhamData: null,
        girijanabandhamSpendData: null,
        girijanabandhamRaisedData: null,
        gramabandhamData: null,
        gramabandhamSpendData: null,
        gramabandhamRaisedData: null,
        janahitabandhamData: null,
        janahitabandhamSpendData: null,
        janahitabandhamRaisedData: null,
        paryavaranambandhamData: null,
        paryavaranambandhamSpendData: null,
        paryavaranambandhamRaisedData: null,
    });
    const location = useLocation();

    useEffect(() => {
        // When on any of the specified pages, reset the activeTab to 'home'
        if (
            [
                '/aboutus',
                '/contactus',
                '/rythubandham',
                '/girijanabandham',
                '/gramabandham',
                '/janahitabandham',
                '/paryavaranambandham',
                '/events',
                '/gallery',
                '/volunteer',
                '/login',
                '/search',
            ].includes(location.pathname)
        ) {
            handleTabSelect('home');
        }
    }, [location.pathname, handleTabSelect]);

    useEffect(() => {
        axios.get(`${SERVICE_API_URL}`)
            .then(response => {
                const fetchedServicesData = response.data;
                setServiceData(fetchedServicesData);

            })
            .catch(error => {
                // Handle errors
            });
    }, []);

    useEffect(() => {
        // Get the data for each category from the servicesData
        if (servicesData.length > 0) {
            var homeDataObj = servicesData.find((service) => service.master_service_id === 0);
            var rythubandhamDataObj = servicesData.find((service) => service.master_service_id === 1);
            var girijanabandhamDataObj = servicesData.find((service) => service.master_service_id === 2);
            var gramabandhamDataObj = servicesData.find((service) => service.master_service_id === 3);
            var janahitabandhamDataObj = servicesData.find((service) => service.master_service_id === 4);
            var paryavaranambandhamDataObj = servicesData.find((service) => service.master_service_id === 5);

            var homeSpendDataObj = {
                labels: homeDataObj?.spend_data?.labels,
                datasets: [
                    {
                        data: homeDataObj?.spend_data.datasets[0].data,
                        backgroundColor: homeDataObj?.spend_data.datasets[0].backgroundColor,
                        hoverBackgroundColor: homeDataObj?.spend_data.datasets[0].hoverBackgroundColor,
                    },
                ],
            };

            var homeRaisedDataObj = {
                labels: homeDataObj?.raised_data.labels,
                datasets: [
                    {
                        data: homeDataObj?.raised_data.datasets[0].data,
                        backgroundColor: homeDataObj?.raised_data.datasets[0].backgroundColor,
                        hoverBackgroundColor: homeDataObj?.raised_data.datasets[0].hoverBackgroundColor,
                    },
                ],
            };

            var rythubandhamSpendDataObj = {
                labels: rythubandhamDataObj?.spend_data.labels,
                datasets: [
                    {
                        data: rythubandhamDataObj?.spend_data.datasets[0].data,
                        backgroundColor: rythubandhamDataObj?.spend_data.datasets[0].backgroundColor,
                        hoverBackgroundColor: rythubandhamDataObj?.spend_data.datasets[0].hoverBackgroundColor,
                    },
                ],
            };

            var rythubandhamRaisedDataObj = {
                labels: rythubandhamDataObj?.raised_data.labels,
                datasets: [
                    {
                        data: rythubandhamDataObj?.raised_data.datasets[0].data,
                        backgroundColor: rythubandhamDataObj?.raised_data.datasets[0].backgroundColor,
                        hoverBackgroundColor: rythubandhamDataObj?.raised_data.datasets[0].hoverBackgroundColor,
                    },
                ],
            };

            var girijanabandhamSpendDataObj = {
                labels: girijanabandhamDataObj?.spend_data.labels,
                datasets: [
                    {
                        data: girijanabandhamDataObj?.spend_data.datasets[0].data,
                        backgroundColor: girijanabandhamDataObj?.spend_data.datasets[0].backgroundColor,
                        hoverBackgroundColor: girijanabandhamDataObj?.spend_data.datasets[0].hoverBackgroundColor,
                    },
                ],
            };

            var girijanabandhamRaisedDataObj = {
                labels: girijanabandhamDataObj?.raised_data.labels,
                datasets: [
                    {
                        data: girijanabandhamDataObj?.raised_data.datasets[0].data,
                        backgroundColor: girijanabandhamDataObj?.raised_data.datasets[0].backgroundColor,
                        hoverBackgroundColor: girijanabandhamDataObj?.raised_data.datasets[0].hoverBackgroundColor,
                    },
                ],
            };

            var gramabandhamSpendDataObj = {
                labels: gramabandhamDataObj.spend_data.labels,
                datasets: [
                    {
                        data: gramabandhamDataObj?.spend_data.datasets[0].data,
                        backgroundColor: gramabandhamDataObj?.spend_data.datasets[0].backgroundColor,
                        hoverBackgroundColor: gramabandhamDataObj?.spend_data.datasets[0].hoverBackgroundColor,
                    },
                ],
            };

            var gramabandhamRaisedDataObj = {
                labels: gramabandhamDataObj?.raised_data.labels,
                datasets: [
                    {
                        data: gramabandhamDataObj?.raised_data.datasets[0].data,
                        backgroundColor: gramabandhamDataObj?.raised_data.datasets[0].backgroundColor,
                        hoverBackgroundColor: gramabandhamDataObj?.raised_data.datasets[0].hoverBackgroundColor,
                    },
                ],
            };

            var janahitabandhamSpendDataObj = {
                labels: janahitabandhamDataObj?.spend_data.labels,
                datasets: [
                    {
                        data: janahitabandhamDataObj?.spend_data.datasets[0].data,
                        backgroundColor: janahitabandhamDataObj?.spend_data.datasets[0].backgroundColor,
                        hoverBackgroundColor: janahitabandhamDataObj?.spend_data.datasets[0].hoverBackgroundColor,
                    },
                ],
            };

            var janahitabandhamRaisedDataObj = {
                labels: janahitabandhamDataObj?.raised_data.labels,
                datasets: [
                    {
                        data: janahitabandhamDataObj?.raised_data.datasets[0].data,
                        backgroundColor: janahitabandhamDataObj?.raised_data.datasets[0].backgroundColor,
                        hoverBackgroundColor: janahitabandhamDataObj?.raised_data.datasets[0].hoverBackgroundColor,
                    },
                ],
            };

            var paryavaranambandhamSpendDataObj = {
                labels: paryavaranambandhamDataObj?.spend_data.labels,
                datasets: [
                    {
                        data: paryavaranambandhamDataObj?.spend_data.datasets[0].data,
                        backgroundColor: paryavaranambandhamDataObj?.spend_data.datasets[0].backgroundColor,
                        hoverBackgroundColor: paryavaranambandhamDataObj?.spend_data.datasets[0].hoverBackgroundColor,
                    },
                ],
            };

            var paryavaranambandhamRaisedDataObj = {
                labels: paryavaranambandhamDataObj?.raised_data.labels,
                datasets: [
                    {
                        data: paryavaranambandhamDataObj?.raised_data.datasets[0].data,
                        backgroundColor: paryavaranambandhamDataObj?.raised_data.datasets[0].backgroundColor,
                        hoverBackgroundColor: paryavaranambandhamDataObj?.raised_data.datasets[0].hoverBackgroundColor,
                    },
                ],
            };


            setServiceDataObj({
                homeData: homeDataObj,
                homeSpendData: homeSpendDataObj,
                homeRaisedData: homeRaisedDataObj,
                rythubandhamData: rythubandhamDataObj,
                rythubandhamSpendData: rythubandhamSpendDataObj,
                rythubandhamRaisedData: rythubandhamRaisedDataObj,
                girijanabandhamData: girijanabandhamDataObj,
                girijanabandhamSpendData: girijanabandhamSpendDataObj,
                girijanabandhamRaisedData: girijanabandhamRaisedDataObj,
                gramabandhamData: gramabandhamDataObj,
                gramabandhamSpendData: gramabandhamSpendDataObj,
                gramabandhamRaisedData: gramabandhamRaisedDataObj,
                janahitabandhamData: janahitabandhamDataObj,
                janahitabandhamSpendData: janahitabandhamSpendDataObj,
                janahitabandhamRaisedData: janahitabandhamRaisedDataObj,
                paryavaranambandhamData: paryavaranambandhamDataObj,
                paryavaranambandhamSpendData: paryavaranambandhamSpendDataObj,
                paryavaranambandhamRaisedData: paryavaranambandhamRaisedDataObj,
            }); // Store the fetched data in state
            console.log(servicesDataObj);
        }
    }, [servicesData]);

    const combineData = () => {
        if (servicesDataObj.rythubandhamData) {
            const spendData = servicesDataObj.rythubandhamData.spend_data.datasets[0].data;
            const raisedData = servicesDataObj.rythubandhamData.raised_data.datasets[0].data;

            const combinedData = spendData.map((spend, index) => spend + raisedData[index]);

            return combinedData;
        }

        return [];
    };

    const combinedChartData = {
        labels: servicesDataObj.rythubandhamData?.spend_data.labels || [],
        datasets: [{
            data: combineData(),
            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
            hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']
        }]
    };


    const handleRaisedTabSelect = (tab, category) => {
        setActiveTabs((prevActiveTabs) => ({
            ...prevActiveTabs,
            [category]: tab,
        }));
    };

    const truncateString = (str, limit) => {
        if (str === undefined) return '';
        const words = str.split(' ');
        if (words.length <= limit) return str;
        return words.slice(0, limit).join(' ') + '...';
    };






    const createCustomLegend = (datasets, labels) => {
        let legendHTML = '';

        if (datasets?.length) {
            for (let i = 0; i < datasets[0].data?.length; i++) {
                legendHTML += `
        <li>
          <span style="background-color: ${datasets[0].backgroundColor[i]};"></span>
          ${labels[i]}
        </li>
      `;
            }
        }

        return `
    <ul>
      ${legendHTML}
    </ul>
  `;
    };

    return (
        <>
            {/* Custom Navbar for Home */}
            <Navbar />
            <div className="home-page mobileone">
                {/* <div className="arrow">
                         <span></span>
                         <span></span>
                         <span></span>
                    </div> */}
                <div className="res-container">
                    <div className="home-top d-grid">
                        <div className="tab-content">
                            <div className={`tab-pane h-100 fade ${activeTab === 'home' ? 'show active' : ''}`}>
                                <div className="row h-100">
                                    <div className="col-30">
                                        <div className="d-flex text-left hp-main">
                                            <div className='hp-child1'>
                                                <span className="tagline">Overview</span>
                                                <h1 className="mb-20 cust-underline">{servicesDataObj.homeData?.service_headline}</h1>

                                            </div>
                                            <div className='hp-child2'>
                                                <p className="mb-30" style={{ textAlign: 'justify' }}>{servicesDataObj.homeData?.service_description}</p>
                                            </div>
                                            <div className='hp-child3'>
                                                <NavLink className="btn btn-outline" to="/aboutus">
                                                    View More
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-40 p-relative h-100">
                                        <img src={servicesDataObj.homeData?.service_banner_image} alt="home" className="cust-home-banner" />
                                    </div>
                                    <div className={`chart col-30`}>
                                        <div className="d-grid text-center m-auto w-100 mw-100">
                                            <div className="mb-30 w-100 mw-100">
                                                {activeTabs.home === 'spent' && (
                                                    <div className="chart-container w-100 mw-100" style={{ width: '100%', height: '30vh', marginBottom: '10vh' }}>
                                                        {servicesDataObj.homeSpendData ? <Doughnut
                                                            data={servicesDataObj.homeSpendData}
                                                            plugins={[ChartDataLabels]}
                                                            options={{
                                                                plugins: {
                                                                    datalabels: {
                                                                        color: '#fff',
                                                                        formatter: (value) => value,
                                                                    },
                                                                    legend: {
                                                                        display: false,
                                                                    },
                                                                },
                                                                animation: {
                                                                    duration: 0,
                                                                },
                                                            }}
                                                            id="myDoughnutChart"
                                                        /> : ''}
                                                        <div dangerouslySetInnerHTML={{ __html: createCustomLegend(servicesDataObj.homeSpendData?.datasets, servicesDataObj.homeSpendData?.labels) }} />
                                                    </div>
                                                )}

                                                {activeTabs.home === 'raised' && (
                                                    <div className="chart-container w-100 mw-100" style={{ width: '100%', height: '30vh', marginBottom: '10vh' }}>
                                                        {servicesDataObj.homeRaisedData ? <Doughnut
                                                            data={servicesDataObj.homeRaisedData}
                                                            plugins={[ChartDataLabels]}
                                                            options={{
                                                                plugins: {
                                                                    datalabels: {
                                                                        color: '#fff',
                                                                        formatter: (value) => value,
                                                                    },
                                                                    legend: {
                                                                        display: false,
                                                                    },
                                                                },
                                                                animation: {
                                                                    duration: 0,
                                                                },
                                                            }}
                                                        /> : ''}
                                                        <div dangerouslySetInnerHTML={{ __html: createCustomLegend(servicesDataObj.homeRaisedData?.datasets, servicesDataObj.homeRaisedData?.labels) }} />
                                                    </div>
                                                )}
                                            </div>
                                            <div>
                                                <ul className="nav nav-tabs mt-30">
                                                    <li className="nav-item">
                                                        <button
                                                            className={`nav-link ${activeTabs.home === 'spent' ? 'active' : ''}`}
                                                            onClick={() => handleRaisedTabSelect('spent', 'home')}
                                                        >
                                                            Spend
                                                        </button>
                                                    </li>
                                                    <li className="nav-item">
                                                        <button
                                                            className={`nav-link ${activeTabs.home === 'raised' ? 'active' : ''}`}
                                                            onClick={() => handleRaisedTabSelect('raised', 'home')}
                                                        >
                                                            Raised
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {/* Content for Rythubandham Tab */}
                            <div className={`tab-pane h-100 fade ${activeTab === 'rythubandham' ? 'show active' : ''}`}>
                                <div className="row h-100">
                                    <div className="col-30">
                                        <div className="d-flex text-left hp-main">
                                            <div className='hp-child1'>
                                                <span className="tagline">{servicesDataObj.rythubandhamData?.service_title}</span>
                                                <h1 className="mb-20 cust-underline">{servicesDataObj.rythubandhamData?.service_headline}</h1>

                                            </div>
                                            <div className='hp-child2'>
                                                <p className="mb-30" style={{ textAlign: 'justify' }}>{servicesDataObj.rythubandhamData?.service_description}</p>
                                            </div>
                                            <div className='hp-child3'>
                                                <NavLink className="btn btn-outline" to="/rythubandham_component">
                                                    View More
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-40">
                                        <img src={servicesDataObj.rythubandhamData?.service_banner_image} alt="Rythu Bandham" />
                                    </div>
                                    <div className="chart col-30">
                                        <div className="d-grid text-center m-auto">
                                           
                                            <div className="mb-30">
                                                {/* Render the Spend and Raised charts based on the active tab */}

                                                {activeTabs.rythubandham === 'spent' && (
                                                    <div className="chart-container cc-5" style={{ width: '100%', height: '30vh', marginBottom: '5vh' }}>
                                                        {servicesDataObj.rythubandhamSpendData ? <Doughnut
                                                            data={servicesDataObj.rythubandhamSpendData}
                                                            plugins={[ChartDataLabels]}
                                                            options={{
                                                                plugins: {
                                                                    datalabels: {
                                                                        color: '#fff',
                                                                        formatter: (value) => value,
                                                                    },
                                                                    legend: {
                                                                        display: false,
                                                                    },
                                                                },
                                                                animation: {
                                                                    duration: 0,
                                                                },
                                                            }}
                                                        /> : ''}
                                                        <div dangerouslySetInnerHTML={{ __html: createCustomLegend(servicesDataObj.rythubandhamSpendData?.datasets, servicesDataObj.rythubandhamSpendData?.labels) }} />
                                                    </div>
                                                )}
                                                {activeTabs.rythubandham === 'raised' && (
                                                    <div className="chart-container cc-5" style={{ width: '100%', height: '30vh', marginBottom: '5vh' }}>
                                                        {servicesDataObj.rythubandhamRaisedData ? <Doughnut
                                                            data={servicesDataObj.rythubandhamRaisedData}
                                                            plugins={[ChartDataLabels]}
                                                            options={{
                                                                plugins: {
                                                                    datalabels: {
                                                                        color: '#fff',
                                                                        formatter: (value) => value,
                                                                    },
                                                                    legend: {
                                                                        display: false,
                                                                    },
                                                                },
                                                                animation: {
                                                                    duration: 0,
                                                                },
                                                            }}
                                                        /> : ''}
                                                        <div dangerouslySetInnerHTML={{ __html: createCustomLegend(servicesDataObj.rythubandhamRaisedData?.datasets, servicesDataObj.rythubandhamRaisedData?.labels) }} />
                                                    </div>
                                                )}
                                            </div>
                                            <div>
                                                <ul className="nav nav-tabs mt-30">
                                                    <li className="nav-item">
                                                        <button
                                                            className={`nav-link ${activeTabs.rythubandham === 'spent' ? 'active' : ''}`}
                                                            onClick={() => handleRaisedTabSelect('spent', 'rythubandham')}
                                                        >
                                                            Spend
                                                        </button>
                                                    </li>
                                                    <li className="nav-item">
                                                        <button
                                                            className={`nav-link ${activeTabs.rythubandham === 'raised' ? 'active' : ''}`}
                                                            onClick={() => handleRaisedTabSelect('raised', 'rythubandham')}
                                                        >
                                                            Raised
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`tab-pane h-100 fade ${activeTab === 'girijanabandham' ? 'show active' : ''}`}>
                                <div className="row h-100">
                                    <div className="col-30">
                                        <div className="d-flex text-left hp-main">
                                            <div className='hp-child1'>
                                                <span className="tagline">{servicesDataObj.girijanabandhamData?.service_title}</span>
                                                <h1 className="mb-20 cust-underline">{servicesDataObj.girijanabandhamData?.service_headline}</h1>

                                            </div>
                                            <div className='hp-child2'>
                                                <p className="mb-30" style={{ textAlign: 'justify' }}>{servicesDataObj.girijanabandhamData?.service_description}</p>
                                            </div>
                                            <div className='hp-child3'>
                                                <NavLink className="btn btn-outline" to="/girijanabandham_component">
                                                    View More
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-40">
                                        <img src={servicesDataObj.girijanabandhamData?.service_banner_image} alt="Girijana Bandham" />
                                    </div>
                                    <div className="chart col-30">
                                        <div className="d-grid text-center m-auto">
                                            <div className="mb-30">
                                                {/* Render the Spend and Raised charts based on the active tab */}
                                                {activeTabs.girijanabandham === 'spent' && (
                                                    <div className="chart-container cc-5" style={{ width: '100%', height: '30vh', marginBottom: '5vh' }}>
                                                        {servicesDataObj.girijanabandhamSpendData ? <Doughnut
                                                            data={servicesDataObj.girijanabandhamSpendData}
                                                            plugins={[ChartDataLabels]}
                                                            options={{
                                                                plugins: {
                                                                    datalabels: {
                                                                        color: '#fff',
                                                                        formatter: (value) => value,
                                                                    },
                                                                    legend: {
                                                                        display: false,
                                                                    },
                                                                },
                                                                animation: {
                                                                    duration: 0,
                                                                },
                                                            }}
                                                        /> : ''}
                                                        <div dangerouslySetInnerHTML={{ __html: createCustomLegend(servicesDataObj.girijanabandhamSpendData?.datasets, servicesDataObj.girijanabandhamSpendData?.labels) }} />
                                                    </div>
                                                )}
                                                {activeTabs.girijanabandham === 'raised' && (
                                                    <div className="chart-container cc-5" style={{ width: '100%', height: '30vh', marginBottom: '5vh' }}>
                                                        {servicesDataObj.girijanabandhamRaisedData ? <Doughnut
                                                            data={servicesDataObj.girijanabandhamRaisedData}
                                                            plugins={[ChartDataLabels]}
                                                            options={{
                                                                plugins: {
                                                                    datalabels: {
                                                                        color: '#fff',
                                                                        formatter: (value) => value,
                                                                    },
                                                                    legend: {
                                                                        display: false,
                                                                    },
                                                                },
                                                                animation: {
                                                                    duration: 0,
                                                                },
                                                            }}
                                                        /> : ''}
                                                        <div dangerouslySetInnerHTML={{ __html: createCustomLegend(servicesDataObj.girijanabandhamRaisedData?.datasets, servicesDataObj.girijanabandhamRaisedData?.labels) }} />
                                                    </div>
                                                )}
                                            </div>
                                            <div>
                                                <ul className="nav nav-tabs mt-30">
                                                    <li className="nav-item">
                                                        <button
                                                            className={`nav-link ${activeTabs.girijanabandham === 'spent' ? 'active' : ''}`}
                                                            onClick={() => handleRaisedTabSelect('spent', 'girijanabandham')}
                                                        >
                                                            Spend
                                                        </button>
                                                    </li>
                                                    <li className="nav-item">
                                                        <button
                                                            className={`nav-link ${activeTabs.girijanabandham === 'raised' ? 'active' : ''}`}
                                                            onClick={() => handleRaisedTabSelect('raised', 'girijanabandham')}
                                                        >
                                                            Raised
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Content for Gramabandham Tab */}
                            <div className={`tab-pane h-100 fade ${activeTab === 'gramabandham' ? 'show active' : ''}`}>
                                <div className="row h-100">
                                    <div className="col-30">
                                        <div className="d-flex text-left hp-main">
                                            <div className='hp-child1'>
                                                <span className="tagline">{servicesDataObj.gramabandhamData?.service_title}</span>
                                                <h1 className="mb-20 cust-underline">{servicesDataObj.gramabandhamData?.service_headline}</h1>
                                            </div>
                                            <div className='hp-child2'>
                                                <p className="mb-30" style={{ textAlign: 'justify' }}>{servicesDataObj.gramabandhamData?.service_description}</p>
                                            </div>
                                            <div className='hp-child3'>
                                                <NavLink className="btn btn-outline" to="/gramabandham_component">
                                                    View More
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-40">
                                        <img src={servicesDataObj.gramabandhamData?.service_banner_image} alt="Grama Bandham" />
                                    </div>
                                    <div className="chart col-30">
                                        <div className="d-grid text-center m-auto">
                                            <div className="mb-30">
                                                {/* Render the Spend and Raised charts based on the active tab */}
                                                {activeTabs.gramabandham === 'spent' && (
                                                    <div className="chart-container cc-5" style={{ width: '100%', height: '30vh', marginBottom: '5vh' }}>
                                                        {servicesDataObj.gramabandhamSpendData ? <Doughnut
                                                            data={servicesDataObj.gramabandhamSpendData}
                                                            plugins={[ChartDataLabels]}
                                                            options={{
                                                                plugins: {
                                                                    datalabels: {
                                                                        color: '#fff',
                                                                        formatter: (value) => value,
                                                                    },
                                                                    legend: {
                                                                        display: false,
                                                                    },
                                                                },
                                                                animation: {
                                                                    duration: 0,
                                                                },
                                                            }}
                                                        /> : ''}
                                                        <div dangerouslySetInnerHTML={{ __html: createCustomLegend(servicesDataObj.gramabandhamSpendData?.datasets, servicesDataObj.gramabandhamSpendData?.labels) }} />
                                                    </div>
                                                )}
                                                {activeTabs.gramabandham === 'raised' && (
                                                    <div className="chart-container cc-5" style={{ width: '100%', height: '30vh', marginBottom: '5vh' }}>
                                                        {servicesDataObj.gramabandhamRaisedData ? <Doughnut
                                                            data={servicesDataObj.gramabandhamRaisedData}
                                                            plugins={[ChartDataLabels]}
                                                            options={{
                                                                plugins: {
                                                                    datalabels: {
                                                                        color: '#fff',
                                                                        formatter: (value) => value,
                                                                    },
                                                                    legend: {
                                                                        display: false,
                                                                    },
                                                                },
                                                                animation: {
                                                                    duration: 0,
                                                                },
                                                            }}
                                                        /> : ''}
                                                        <div dangerouslySetInnerHTML={{ __html: createCustomLegend(servicesDataObj.gramabandhamRaisedData?.datasets, servicesDataObj.gramabandhamRaisedData?.labels) }} />
                                                    </div>
                                                )}
                                            </div>
                                            <div>
                                                <ul className="nav nav-tabs mt-30">
                                                    <li className="nav-item">
                                                        <button
                                                            className={`nav-link ${activeTabs.gramabandham === 'spent' ? 'active' : ''}`}
                                                            onClick={() => handleRaisedTabSelect('spent', 'gramabandham')}
                                                        >
                                                            Spend
                                                        </button>
                                                    </li>
                                                    <li className="nav-item">
                                                        <button
                                                            className={`nav-link ${activeTabs.gramabandham === 'raised' ? 'active' : ''}`}
                                                            onClick={() => handleRaisedTabSelect('raised', 'gramabandham')}
                                                        >
                                                            Raised
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Content for Janahitabandham Tab */}
                            <div className={`tab-pane h-100 fade ${activeTab === 'janahitabandham' ? 'show active' : ''}`}>
                                <div className="row h-100">
                                    <div className="col-30">
                                        <div className="d-flex text-left hp-main">
                                            <div className='hp-child1'>
                                                <span className="tagline">{servicesDataObj.janahitabandhamData?.service_title}</span>
                                                <h1 className="mb-20 cust-underline">{servicesDataObj.janahitabandhamData?.service_headline}</h1>

                                            </div>
                                            <div className='hp-child2'>
                                                <p className="mb-30" style={{ textAlign: 'justify' }}>{servicesDataObj.janahitabandhamData?.service_description}</p>
                                            </div>
                                            <div className='hp-child3'>
                                                <NavLink className="btn btn-outline" to="/janahitabandham_component">
                                                    View More
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-40">
                                        <img src={servicesDataObj.janahitabandhamData?.service_banner_image} alt="Janahita Bandham" />
                                    </div>
                                    <div className="chart col-30">
                                        <div className="d-grid text-center m-auto">
                                            <div className="mb-30">
                                                {/* Render the Spend and Raised charts based on the active tab */}
                                                {activeTabs.janahitabandham === 'spent' && (
                                                    <div className="chart-container cc-5" style={{ width: '100%', height: '30vh', marginBottom: '5vh' }}>
                                                        {servicesDataObj.janahitabandhamSpendData ? <Doughnut
                                                            data={servicesDataObj.janahitabandhamSpendData}
                                                            plugins={[ChartDataLabels]}
                                                            options={{
                                                                plugins: {
                                                                    datalabels: {
                                                                        color: '#fff',
                                                                        formatter: (value) => value,
                                                                    },
                                                                    legend: {
                                                                        display: false,
                                                                    },
                                                                },
                                                                animation: {
                                                                    duration: 0,
                                                                },
                                                            }}
                                                        /> : ''}
                                                        <div dangerouslySetInnerHTML={{ __html: createCustomLegend(servicesDataObj.janahitabandhamSpendData?.datasets, servicesDataObj.janahitabandhamSpendData?.labels) }} />
                                                    </div>
                                                )}
                                                {activeTabs.janahitabandham === 'raised' && (
                                                    <div className="chart-container cc-5" style={{ width: '100%', height: '30vh', marginBottom: '5vh' }}>
                                                        {servicesDataObj.janahitabandhamRaisedData ? <Doughnut
                                                            data={servicesDataObj.janahitabandhamRaisedData}
                                                            plugins={[ChartDataLabels]}
                                                            options={{
                                                                plugins: {
                                                                    datalabels: {
                                                                        color: '#fff',
                                                                        formatter: (value) => value,
                                                                    },
                                                                    legend: {
                                                                        display: false,
                                                                    },
                                                                },
                                                                animation: {
                                                                    duration: 0,
                                                                },
                                                            }}
                                                        /> : ''}
                                                        <div dangerouslySetInnerHTML={{ __html: createCustomLegend(servicesDataObj.janahitabandhamRaisedData?.datasets, servicesDataObj.janahitabandhamRaisedData?.labels) }} />
                                                    </div>
                                                )}
                                            </div>
                                            <div>
                                                <ul className="nav nav-tabs mt-30">
                                                    <li className="nav-item">
                                                        <button
                                                            className={`nav-link ${activeTabs.janahitabandham === 'spent' ? 'active' : ''}`}
                                                            onClick={() => handleRaisedTabSelect('spent', 'janahitabandham')}
                                                        >
                                                            Spend
                                                        </button>
                                                    </li>
                                                    <li className="nav-item">
                                                        <button
                                                            className={`nav-link ${activeTabs.janahitabandham === 'raised' ? 'active' : ''}`}
                                                            onClick={() => handleRaisedTabSelect('raised', 'janahitabandham')}
                                                        >
                                                            Raised
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Content for Paryavaranambandham Tab */}
                            <div className={`tab-pane h-100 fade ${activeTab === 'paryavaranambandham' ? 'show active' : ''}`}>
                                <div className="row h-100">
                                    <div className="col-30">
                                        <div className="d-flex text-left hp-main">
                                            <div className='hp-child1'>
                                                <span className="tagline">{servicesDataObj.paryavaranambandhamData?.service_title}</span>
                                                <h1 className="mb-20 cust-underline">{servicesDataObj.paryavaranambandhamData?.service_headline}</h1>

                                            </div>
                                            <div className='hp-child2'>
                                                <p className="mb-30" style={{ textAlign: 'justify' }}>{servicesDataObj.paryavaranambandhamData?.service_description}</p>
                                            </div>
                                            <div className='hp-child3'>
                                                <NavLink className="btn btn-outline" to="/paryavaranambandham_component">
                                                    View More
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-40">
                                        <img src={servicesDataObj.paryavaranambandhamData?.service_banner_image} alt="Paryavaranam Bandham" />
                                    </div>
                                    <div className="chart col-30">
                                        <div className="d-grid text-center m-auto">
                                            <div className="mb-30">
                                                {/* Render the Spend and Raised charts based on the active tab */}
                                                {activeTabs.paryavaranambandham === 'spent' && (
                                                    <div className="chart-container cc-5" style={{ width: '100%', height: '30vh', marginBottom: '5vh' }}>
                                                        {servicesDataObj.paryavaranambandhamSpendData ? <Doughnut
                                                            data={servicesDataObj.paryavaranambandhamSpendData}
                                                            plugins={[ChartDataLabels]}
                                                            options={{
                                                                plugins: {
                                                                    datalabels: {
                                                                        color: '#fff',
                                                                        formatter: (value) => value,
                                                                    },
                                                                    legend: {
                                                                        display: false,
                                                                    },
                                                                },
                                                                animation: {
                                                                    duration: 0,
                                                                },
                                                            }}
                                                        /> : ''}
                                                        <div dangerouslySetInnerHTML={{ __html: createCustomLegend(servicesDataObj.paryavaranambandhamSpendData?.datasets, servicesDataObj.paryavaranambandhamSpendData?.labels) }} />
                                                    </div>
                                                )}
                                                {activeTabs.paryavaranambandham === 'raised' && (
                                                    <div className="chart-container cc-5" style={{ width: '100%', height: '30vh', marginBottom: '5vh' }}>
                                                        {servicesDataObj.paryavaranambandhamRaisedData ? <Doughnut
                                                            data={servicesDataObj.paryavaranambandhamRaisedData}
                                                            plugins={[ChartDataLabels]}
                                                            options={{
                                                                plugins: {
                                                                    datalabels: {
                                                                        color: '#fff',
                                                                        formatter: (value) => value,
                                                                    },
                                                                    legend: {
                                                                        display: false,
                                                                    },
                                                                },
                                                                animation: {
                                                                    duration: 0,
                                                                },
                                                            }}
                                                        /> : ''}
                                                        <div dangerouslySetInnerHTML={{ __html: createCustomLegend(servicesDataObj.paryavaranambandhamRaisedData?.datasets, servicesDataObj.paryavaranambandhamRaisedData?.labels) }} />
                                                    </div>
                                                )}
                                            </div>
                                            <div>
                                                <ul className="nav nav-tabs mt-30">
                                                    <li className="nav-item">
                                                        <button
                                                            className={`nav-link ${activeTabs.paryavaranambandham === 'spent' ? 'active' : ''}`}
                                                            onClick={() => handleRaisedTabSelect('spent', 'paryavaranambandham')}
                                                        >
                                                            Spend
                                                        </button>
                                                    </li>
                                                    <li className="nav-item">
                                                        <button
                                                            className={`nav-link ${activeTabs.paryavaranambandham === 'raised' ? 'active' : ''}`}
                                                            onClick={() => handleRaisedTabSelect('raised', 'paryavaranambandham')}
                                                        >
                                                            Raised
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="home-bottom">
                        <ul className="nav nav-tabs home-tab">
                            <li className="nav-item d-none">
                                <button className={`nav-link btn btn-link ${activeTab === 'home' ? 'active' : ''}`} onClick={() => handleTabSelect('home')}>
                                    Home
                                </button>
                            </li>
                            <li className="nav-item">
                                <button className={`nav-link btn btn-link ${activeTab === 'rythubandham' ? 'active' : ''}`} onClick={() => handleTabSelect('rythubandham')}>
                                    <img src={activeTab === 'rythubandham' ? RythubandhamAfter : RythubandhamBefore} alt={servicesDataObj.rythubandhamData?.service_title} />
                                    <h4 className="head">{servicesDataObj.rythubandhamData?.service_title}</h4>
                                    <p>{servicesDataObj.rythubandhamData?.service_tagline}</p>
                                </button>
                            </li>
                            <li className="nav-item">
                                <button className={`nav-link btn btn-link ${activeTab === 'girijanabandham' ? 'active' : ''}`} onClick={() => handleTabSelect('girijanabandham')}>
                                    <img src={activeTab === 'girijanabandham' ? GirijanabandhamAfter : GirijanabandhamBefore} alt={servicesDataObj.girijanabandhamData?.service_title} />
                                    <h4 className="head">{servicesDataObj.girijanabandhamData?.service_title}</h4>
                                    <p>{servicesDataObj.girijanabandhamData?.service_tagline}</p>
                                </button>
                            </li>
                            <li className="nav-item">
                                <button className={`nav-link btn btn-link ${activeTab === 'gramabandham' ? 'active' : ''}`} onClick={() => handleTabSelect('gramabandham')}>
                                    <img src={activeTab === 'gramabandham' ? GramabandhamAfter : GramabandhamBefore} alt={servicesDataObj.gramabandhamData?.service_title} />
                                    <h4 className="head">{servicesDataObj.gramabandhamData?.service_title}</h4>
                                    <p>{servicesDataObj.gramabandhamData?.service_tagline}</p>
                                </button>
                            </li>
                            <li className="nav-item">
                                <button className={`nav-link btn btn-link ${activeTab === 'janahitabandham' ? 'active' : ''}`} onClick={() => handleTabSelect('janahitabandham')}>
                                    <img src={activeTab === 'janahitabandham' ? JanahitabandhamAfter : JanahitabandhamBefore} alt={servicesDataObj.janahitabandhamData?.service_title} />
                                    <h4 className="head">{servicesDataObj.janahitabandhamData?.service_title}</h4>
                                    <p>{servicesDataObj.janahitabandhamData?.service_tagline}</p>
                                </button>
                            </li>
                            <li className="nav-item">
                                <button className={`nav-link btn btn-link ${activeTab === 'paryavaranambandham' ? 'active' : ''}`} onClick={() => handleTabSelect('paryavaranambandham')}>
                                    <img src={activeTab === 'paryavaranambandham' ? ParyavaranambandhamAfter : ParyavaranambandhamBefore} alt={servicesDataObj.paryavaranambandhamData?.service_title} />
                                    <h4 className="head">{servicesDataObj.paryavaranambandhamData?.service_title}</h4>
                                    <p>{servicesDataObj.paryavaranambandhamData?.service_tagline}</p>
                                </button>
                            </li>
                            {/* Add more nav links as needed */}
                        </ul>
                    </div>
                </div>
            </div >
        </>
    );
}