import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';



export default function Protected() {
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (!token) {
            navigate('/login'); // Use navigate to redirect
        }
    }, [navigate, token]);

    if (!token) {
        return null;
    }

    //const user = jwtDecode(token);
    const isTokenExpired = (token) => {
        const decodedToken = jwtDecode(token);
        return Date.now() >= decodedToken.exp * 1000;
    };

    if (isTokenExpired(token)) {
        localStorage.removeItem("token");
        navigate('/login');
    }

    return "";
}

