import React, { useEffect, useState } from 'react';
import AdminNavbar from '../components/adminNavbar';
import jsonData from '../../components/john.json';

import 'chart.js/auto';
import { Bar, Doughnut } from 'react-chartjs-2';

export default function AdminDashboard({ setToken }) {

     const { name, donations } = jsonData;


     const labels = donations.map(donation => donation.date);
     const datasets = [
          'RythuBandham', 'GirijanaBandham', 'GramaBandham', 'JanahitaBandham', 'ParyavaranamBandham'
     ];

     const data = {
          labels: labels,
          datasets: datasets.map((dataset, index) => ({
               label: dataset,
               data: donations.map(donation => donation[dataset]),
               backgroundColor: [
                    '#544bfc',
                    '#5793f3',
                    '#de453f',
                    '#f09430',
                    '#b24bfc',
               ][index],
               hoverBackgroundColor: [
                    '#544bfc',
                    '#5793f3',
                    '#de453f',
                    '#f09430',
                    '#b24bfc',
               ][index],
               barThickness: 10,
          })),
     };

     const options = {
          scales: {
               y: {
                    beginAtZero: true,
                    suggestedMin: 0,      // Set the minimum value of the y-axis
                    suggestedMax: 150,    // Set the maximum value of the y-axis
                    ticks: {
                         stepSize: 50,        // Set the step size to 50
                    },
               },
          },
          plugins: {
               datalabels: {
                    display: true,
                    color: 'white',
                    font: {
                         weight: 'bold',
                    },
                    formatter: (value) => (value >= 1000 ? `${value / 1000}K` : value),
               },
               legend: {
                    display: true,
               },
          },
          elements: {
               bar: {
                    borderRadius: {
                         topLeft: 25,
                         topRight: 25,
                    },
                    borderSkipped: 'bottom', // Skip the bottom border to achieve rounded top corners
               },
          },
     };

     const [contributions, setContributions] = useState([]);

     useEffect(() => {
          const fetchData = async () => {
               try {
                    const response = await fetch('https://manamvanam.com/api/AdminDonations');
                    const data = await response.json();
                    setContributions(data.reverse()); // Reverse the order of donations
               } catch (error) {
                    console.error('Error fetching data:', error);
               }
          };

          fetchData();
     }, []);

     return (
          <>
               <AdminNavbar setToken={setToken} />
               <div className="events">
                    <div className="res-container sm-pt-10">
                         <div className="row admin-chart">
                              <div className="col-8">
                                   <div className="card p-0 mb-20">
                                        <div className="row">
                                             <div className="col-md-12 p-0">
                                                  <div className="card common-card">
                                                       <div className="card-header d-flex jc-sb pb-0">
                                                            <h3 className="head m-0">Day/Week/Month wise Donation Graph</h3>
                                                       </div>
                                                       <div className="card-body row" style={{ paddingTop: '15px', paddingBottom: '0px' }}>
                                                            {data?.datasets && (
                                                                 <Bar data={data} options={options} />
                                                            )}
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div className="col-4">
                                   <div className="card p-0 mb-20">
                                        <div className="row">
                                             <div className="col-md-12 p-0">
                                                  <div className="card common-card">
                                                       <div className="card-header d-flex jc-sb pb-0">
                                                            <h3 className="head m-0">Overrall Donation Graph</h3>
                                                       </div>
                                                       <div className="card-body row" style={{ paddingTop: '15px', paddingBottom: '0px' }}>
                                                            {data?.datasets && (
                                                                 <Doughnut data={data} options={options} />
                                                            )}
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>

                         </div>
                         <div className="row">
                              <div className="col-6">
                                   <div className="card p-0 mb-20">
                                        <div className="row">
                                             <div className="col-md-12 p-0">
                                                  <div className="card common-card">
                                                       <div className="card-header d-flex jc-sb pb-0">
                                                            <h3 className="head m-0">Last Five Donations</h3>
                                                       </div>
                                                       <div className="card-body row" style={{ paddingTop: '15px', paddingBottom: '0px' }}>
                                                            <div className='overflow-scroll'>
                                                                 <table className="table">
                                                                      <thead>
                                                                           <tr>
                                                                                {/* <th>Order ID</th> */}
                                                                                <th>Contributor Name</th>
                                                                                <th>Contribution Date</th>
                                                                                <th>Contribution Type</th>
                                                                                {/* <th>Payment Method</th> */}
                                                                                {/* <th>Transaction Number</th> */}
                                                                                <th>Contribution Amount</th>
                                                                                <th>Payment Status</th>
                                                                           </tr>
                                                                      </thead>
                                                                      <tbody>
                                                                           {contributions.slice(0, 5).map((contribution) => (
                                                                                <tr key={contribution.DonationOrderId}>
                                                                                     {/* <td>{contribution.DonationOrderId}</td> */}
                                                                                     <td>{contribution.DonarName}</td>
                                                                                     <td>{contribution.DonationDate}</td>
                                                                                     <td>{contribution.DonationType}</td>
                                                                                     {/* <td>{contribution.PaymentMethod}</td> */}
                                                                                     {/* <td>{contribution.TransactionNumber}</td> */}
                                                                                     <td>{contribution.DonationAmount}</td>
                                                                                     <td>{contribution.PaymentStatus || 'Pending'}</td>
                                                                                </tr>
                                                                           ))}
                                                                      </tbody>
                                                                 </table>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div className="col-6">
                                   <div className="card p-0 mb-20">
                                        <div className="row">
                                             <div className="col-md-12 p-0">
                                                  <div className="card common-card">
                                                       <div className="card-header d-flex jc-sb pb-0">
                                                            <h3 className="head m-0">Last Five Expenses</h3>
                                                       </div>
                                                       <div className="card-body row" style={{ paddingTop: '15px', paddingBottom: '0px' }}>

                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </>
     );
}

