import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import EventCommonSingle from '../components/EventCommonSingle';


const EventSingle = () => {

     return (
          <>
               <Navbar />
               <div className="events">
                    <EventCommonSingle />
               </div>
               <Footer />
          </>
     );
};

export default EventSingle;