import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import logo from '../images/logo.png';
import facebook from '../images/facebook-colored.png';
import twitter from '../images/twitter-colored.png';
import google from '../images/google-colored.png';
import linkedin from '../images/linkedin-colored.png';
import about from '../images/about.png';
import mission from '../images/mission.png';
import vision from '../images/vision.png';
import objectives from '../images/objective.png';
import { NavLink } from 'react-router-dom';


export default function AboutUs() {
     return (
          <>
               <Navbar />
               <div className="about">
                    <div className="bg-top">
                         <div className="res-container" style={{ margin: '50px auto 0' }}>
                              <div className="row">
                                   <div className="col-8 about-content ">
                                        <div className="d-grid text-left">
                                             <div className="">
                                                  <span className="tagline">About Manam Vanam</span>
                                                  <h1 className="mb-20">Help the Society, Donate to the Charity</h1>
                                                  <p>Welcome to "A Symphony of Hope," where the five elements of life guide our non-profit's comprehensive mission to safeguard and enhance the vitality of our planet and its inhabitants. Earth is at the heart of our commitment, where we address environmental challenges through sustainable practices and community education. As we nurture the wellsprings of life, our dedicated focus extends to water, symbolizing interconnectedness, and advocating for responsible usage. We breathe life into communities by working towards reducing air pollution and promoting clean air initiatives. Moreover, our mission encompasses social welfare, village welfare, tribal welfare, farmer welfare, and environmental welfare. Fueled by the spark of inspiration and transformation, we ignite passion for positive change through educational programs and collaborative projects that benefit diverse communities. The intangible force of Spirit unites us, fostering unity and compassion among individuals across social, village, tribal, and farming contexts. </p>

                                                  <p>Join us in this symphony of life, where our organization plays a harmonious melody aligned with Earth, Water, Air, Fire, and Spirit, creating a lasting impact on social, village, tribal, and farmer welfare while preserving the beauty and vitality of our planet for generations to come. Together, we weave a tapestry of hope and resilience, transcending the boundaries of time and space.</p>

                                             </div>
                                             <div className="sm-right">
                                                  <div className="d-grid ">
                                                       <NavLink to="/contactus" target="_blank">
                                                            <img className="social-media mr-10" src={facebook} alt="facebook" />
                                                       </NavLink>
                                                       <NavLink to="/contactus" target="_blank">
                                                            <img className="social-media mr-10" src={twitter} alt="twitter" />
                                                       </NavLink>
                                                       <NavLink to="/contactus" target="_blank">
                                                            <img className="social-media mr-10" src={google} alt="google" />
                                                       </NavLink>
                                                       <NavLink to="/contactus" target="_blank">
                                                            <img className="social-media mr-10" src={linkedin} alt="linkedin" />
                                                       </NavLink>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                                   <div className="col-4 about-container">
                                        <img className="about-img" src={about} alt="about" />
                                   </div>
                              </div>
                         </div>
                    </div>
                    <div className="bg-bottom">
                         <div className="res-container ">
                              <div className="row jc-sb">
                                   <div className="col-4 about-mission ">
                                        <div className="d-grid text-left">
                                             <div className="mb-30">
                                                  <img className="mission-img mb-10" src={vision} alt="vision" />
                                                  <h4 className="head mb-10">Vision</h4>
                                                  <p>Craft a campaign aimed to preserve the origins of nature and restore the ecological balance</p>
                                             </div>
                                        </div>
                                   </div>
                                   <div className="col-4 about-mission">
                                        <div className="d-grid text-left">
                                             <div className="mb-30">
                                                  <img className="mission-img mb-10" src={mission} alt="mission" />
                                                  <h4 className="head mb-10">Mission</h4>
                                                  <p>Hone Skillset to train and instill confidence to earn independent means of livelihood</p>
                                             </div>
                                        </div>
                                   </div>
                                   <div className="col-4 about-mission ">
                                        <div className="d-grid text-left">
                                             <div className="mb-30">
                                                  <img className="mission-img mb-10" src={objectives} alt="Objectices" />
                                                  <h4 className="head mb-10">Objectives</h4>
                                                  <p>Build a strong foundation to provide sustenance and generate employment</p>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
               <Footer />
          </>
     )
}

