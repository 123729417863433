import React, { useState, useEffect } from 'react';
import AdminNavbar from '../components/adminNavbar';
import { Link } from 'react-router-dom';
//import mediaData from '../../components/media.json'; // Import your media.json file
import deleteicon from '../../images/delete.png';
import axios from 'axios';

const API_URL = 'https://manamvanam.com/api';
export default function AdminMedia() {
    const token = localStorage.getItem('token');
    const [media, setMedia] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        },
    };
    const getEventMediaData = () => {
        // Perform AJAX call using Axios here
        axios.get(`${API_URL}/EventMedia`,config)
            .then(response => {
                console.log(response);
                const fetchedData = response.data;
                setMedia(fetchedData); // Store the fetched data in state
            })
            .catch(error => {
                // Handle errors
            });
    }

    useEffect(() => {
        // Set the media data from the imported JSON file
        getEventMediaData();
    }, []);

    const totalRows = Math.ceil(media.length / itemsPerPage);
    const paginatedMedia = media.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <>
            <AdminNavbar />
            <div className="events">
                <div className="res-container sm-pt-10">
                    <div className="row">
                        <div className="col">
                            <div className="card p-0 mb-20" style={{ height: '80vh' }}>
                                <div className="row">
                                    <div className="col-md-12 p-0">
                                        <div className="card common-card">
                                            <div className="card-header d-flex jc-sb">
                                                <h3 className="head m-0">Media Content</h3>
                                                <Link to="/admin/addmedia" className="btn btn-primary">
                                                    Add Media
                                                </Link>
                                            </div>
                                            <div className="card-body admin-service">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>ID</th>
                                                            <th>Image</th>
                                                            <th>Image Title</th>
                                                            <th>Category</th>
                                                            <th>Event Name</th>
                                                            <th className="text-center">Delete Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {paginatedMedia.map((item) => (
                                                            <tr key={item.id}>
                                                                <td>{item.id}</td>
                                                                <td>
                                                                    <div
                                                                        style={{
                                                                            width: '7vh',
                                                                            height: '7vh',
                                                                            borderRadius: '50%',
                                                                            backgroundImage: `url(${item.imagePath})`,
                                                                            backgroundSize: 'cover',
                                                                            backgroundPosition: 'center',
                                                                        }}
                                                                    ></div>
                                                                </td>
                                                                <td>{item.imageTitle}</td>
                                                                <td>{item.category}</td>
                                                                <td>{item.eventName}</td>
                                                                <td className="text-center"><button style={{ border: '0', background: 'none' }}><img className="action-img del" src={deleteicon} alt="delete" /></button></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <nav>
                                                    <ul className="pagination">
                                                        {Array.from({ length: totalRows }, (_, i) => (
                                                            <li
                                                                key={i}
                                                                className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}
                                                            >
                                                                <button
                                                                    className="page-link"
                                                                    onClick={() => handlePageChange(i + 1)}
                                                                >
                                                                    {i + 1}
                                                                </button>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}