import React, { useState, useEffect } from 'react'; // Import useState and useEffect
import Navbar from '../components/Navbar';
import Donation from '../components/Donation';
import view from '../images/view.png';
import attachment from '../images/attachment.png';

//import serviceData from '../components/services_data.json';
//import eventsData from '../components/events.json';
import { Link } from 'react-router-dom'; // Import Link from React Router
import ImageModal from '../components/ImageModal'; // Import the ImageModal component
import VideoModal from '../components/VideoModal'; // Import the VideoModal component
import Labelicon from '../images/label.png';
import play from '../images/play.png';


import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';

const EVENT_API_URL = 'https://manamvanam.com/api/events';
const SERVICE_API_URL = 'https://manamvanam.com/api/ServiceData';
export default function ManamVanamServiceComponent(props) {
    const serviceCategory = props.serviceName;//"RYTHU BHANDHAM";
    const masterServiceCategory = props.serviceName;//"RYTHU BHANDHAM";
    const [eventsData, setData] = useState([]);
    const [serviceData, setServiceData] = useState([]);
    //const [solution2Events, setSolutionData] = useState([]);
    const itemsPerPage = 6;
    const [currentPage, setCurrentPage] = useState(1);
    const [showImageModal, setShowImageModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const videosPerPage = 2; // Number of videos to display per page
    const [showVideoModal, setShowVideoModal] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null);
    // Inside your component function
    //const [sortedEvents, setSortedEvents] = useState([]); // State to store sorted events
    //const [imagesToDisplay, setImagesToDisplay] = useState([]);
    //const [videosToDisplay, setVideosToDisplay] = useState([]);
    //const [filteredSolution2Events, setFilteredSolution2Events] = useState([]);
    const [eventChunks, seteventChunks] = useState([]);
    const [serviceEvents, setServiceEvents] = useState([]);
    const [rythuBandhamServices, setRythuBandhamServices] = useState([]);

    const [graphData, setGraphData] = useState([]);
    const [graphOption, setGraphOption] = useState([]);
    const [graphLegendItems, setGraphLegendItems] = useState([]);
    var totalPages = 0;
    var numberOfPages = 0;
    const chunkSize = 2;


    var sortedEvents = [], imagesToDisplay = [], videosToDisplay = [], filteredSolution2Events = [], solution2Events = [];
    //var eventChunks = [];
    var data = [], options = [], legendItems = [];


    // Filter the services with category 'Rythubandham'


    // Inside your component function


    const openVideoModal = (video) => {
        setSelectedVideo(video);
        setShowVideoModal(true);
    };

    const closeVideoModal = () => {
        setShowVideoModal(false);
        setSelectedVideo(null);
    };

    const compareEventDates = (event1, event2) => {
        const date1 = new Date(event1.EventStartDate);
        const date2 = new Date(event2.EventStartDate);
        return date1 - date2;
    };

    // Sort the events based on event dates
    useEffect(() => {

        axios.get(`${SERVICE_API_URL}`)
            .then(response => {
                const fetchedServiceData = response.data;
                setServiceData(fetchedServiceData); // Store the fetched data in state


            })
            .catch(error => {
                // Handle errors
            });
        axios.get(`${EVENT_API_URL}`)
            .then(response => {
                const fetchedData = response.data;

                setData(fetchedData); // Store the fetched data in state


            })
            .catch(error => {
                // Handle errors
            });

    }, [props.serviceName]);

    // Split sorted events into chunks of 2 for the carousel




    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const monthNames = [
            'Jan', 'Feb', 'March', 'April', 'May', 'June', 'July',
            'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
        ];
        const month = monthNames[date.getMonth()];
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        return { month, day, year };
    };


    const openImageModal = (image) => {
        const parentEvent = solution2Events.find((event) =>
            event.EventGalleryImages.some((img) => img.Id === image.Id)
        );

        setSelectedImage({
            path: image.Path,
            headline: image.HeadLine,
            eventCategory: parentEvent.EventCategory,
            eventName: parentEvent.EventName,
            id: parentEvent.Id,
        });
        setShowImageModal(true);
    };

    const closeImageModal = () => {
        setSelectedImage(null);
        setShowImageModal(false);
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const [iframeVisible, setIframeVisible] = useState(false);

    const handleClickFrame = () => {
        setIframeVisible(true);
    };
    const handleCloseFrame = () => {
        setIframeVisible(false);
    };

    useEffect(() => {
        var local_solution2Events = eventsData.filter((event) => {
            return event.EventMasterServiceId.toString() === serviceCategory;
        });
        console.log(local_solution2Events);

        //setSolutionData(local_solution2Events);
        solution2Events = local_solution2Events;

        console.log(solution2Events);
        var local_sortedEvents = [...solution2Events].sort(compareEventDates);
        console.log(local_sortedEvents);
        //setSortedEvents(local_sortedEvents);
        sortedEvents = local_sortedEvents;

        console.log(sortedEvents);
        var local_eventChunks = [];
        for (let i = 0; i < sortedEvents.length; i += chunkSize) {
            local_eventChunks.push(sortedEvents.slice(i, i + chunkSize));
        }
        // eventChunks = local_eventChunks;
        console.log(local_eventChunks);

        seteventChunks(local_eventChunks);
        //console.log(eventChunks);

        // Filter events with category "solution2"
        // Filter events with category "solution2"
        // Filter events with category "solution2"
        var local_filteredSolution2Events = eventsData.filter(
            (event) => event.EventMasterServiceId.toString() === serviceCategory
        );

        //setFilteredSolution2Events(local_filteredSolution2Events);
        filteredSolution2Events = local_filteredSolution2Events;
        setServiceEvents(filteredSolution2Events);

        // Flatten the array of video objects from filteredSolution2Events
        const solution2Videos = filteredSolution2Events.flatMap((event) => event.EventGalleryVideos);

        // Calculate the start and end positions for the current page
        const startPosition = (currentPage - 1) * videosPerPage;
        const endPosition = startPosition + videosPerPage;

        // Create an array of videos to display for the current page
        var local_videosToDisplay = solution2Videos.slice(startPosition, endPosition);
        //setVideosToDisplay(local_videosToDisplay);
        videosToDisplay = local_videosToDisplay;

        // Calculate the total number of pages
        const numberOfPages = Math.ceil(solution2Videos.length / videosPerPage);

        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;

        var local_imagesToDisplay = solution2Events
            .flatMap((event) => event.EventGalleryImages)
            .slice(startIndex, endIndex);
        // setImagesToDisplay(local_imagesToDisplay);
        imagesToDisplay = local_imagesToDisplay;

        totalPages = Math.ceil(
            solution2Events.flatMap((event) => event.EventGalleryImages).length / itemsPerPage
        );
    }, [eventsData])


    useEffect(() => {
        // Filter the services with category 'Rythubandham' //.services
        console.log(serviceData);
        var local_rythuBandhamServices = serviceData.filter(
            (service) => service.master_service_id.toString() === masterServiceCategory
        );

        setRythuBandhamServices(local_rythuBandhamServices);
        console.log(local_rythuBandhamServices);


        if (local_rythuBandhamServices && local_rythuBandhamServices.length > 0) {
            //serviceCategory = local_rythuBandhamServices[0].category;
            // Combine the "Spent" and "Raised" data from all services
            //const spentData = local_rythuBandhamServices.reduce((total, service) => {
            //    return total.map((val, index) => val + service.spend_data?.datasets[0].data[index]);
            //}, local_rythuBandhamServices[0].spend_data?.datasets[0].data.map((val) => 0));
            const initialData = local_rythuBandhamServices[0]?.spend_data?.datasets[0]?.data || [];

            const spentData = local_rythuBandhamServices.reduce((total, service) => {
                const serviceData = service.spend_data?.datasets[0]?.data || [];

                if (serviceData.length !== initialData.length) {
                    console.error("Service data has a different length than expected.");
                    return total;
                }

                return total.map((val, index) => val + (serviceData[index] || 0));
            }, initialData);

            //const raisedData = local_rythuBandhamServices.reduce((total, service) => {
            //    return total.map((val, index) => val + service.raised_data.datasets[0].data[index]);
            //}, local_rythuBandhamServices[0].raised_data?.datasets[0].data.map((val) => 0));
            const initialRasisedData = local_rythuBandhamServices[0]?.raised_data?.datasets[0]?.data || [];
            const raisedData = initialRasisedData;
            //const raisedData = local_rythuBandhamServices.reduce((total, service) => {
            //    const serviceData = service.raised_data?.datasets[0]?.data || [];

            //    // Check if the data lengths are consistent among all services
            //    if (serviceData.length !== initialRasisedData.length) {
            //        console.error("Service data has a different length than expected.");
            //        return total;
            //    }

            //    return total.map((val, index) => val + (serviceData[index] || 0));
            //}, initialRasisedData);

            data = {
                labels: local_rythuBandhamServices[0].spend_data?.labels,
                datasets: [{
                    label: 'Spent',
                    data: spentData,
                    backgroundColor: '#7fb432',
                    hoverBackgroundColor: '#7fb432',
                    barThickness: 10,
                },
                {
                    label: 'Raised',
                    data: raisedData,
                    backgroundColor: '#f7b70d',
                    hoverBackgroundColor: '#f7b70d',
                    barThickness: 10,
                }],
            };
            console.log("-------------------------graph----------------------");
            console.log(data);
            console.log("-------------------------graph----------------------");
            setGraphData(data);
            options = {
                plugins: {
                    datalabels: {
                        display: true,
                        color: 'white',
                        font: {
                            weight: 'bold',
                        },
                        formatter: (value) => (value >= 1000 ? `${value / 1000}K` : value),
                    },
                    legend: {
                        display: false,
                    },
                },
                elements: {
                    bar: {
                        borderRadius: {
                            topLeft: 25,
                            topRight: 25,
                        },
                        borderSkipped: 'bottom', // Skip the bottom border to achieve rounded top corners

                    },
                },
            };
            setGraphOption(options);
            legendItems = data?.datasets?.map((dataset, index) => (
                <li key={index}>
                    <span
                        style={{
                            display: 'inline-block',
                            width: '12px',
                            height: '12px',
                            backgroundColor: dataset.backgroundColor,
                            marginRight: '5px',
                        }}
                    ></span>
                    {dataset.label}
                </li>
            ));
            setGraphLegendItems(legendItems);
        }

    }, [serviceData])



    return (
        <>
            {iframeVisible && (
                <div className="framestyle">
                    <button onClick={handleCloseFrame} className="closeBttn btn btn-primary">Close</button>
                    <iframe src="https://www.cse.msu.edu/~cse870/Lectures/2013/09a-System-Design-notes.pdf" className=""></iframe>
                </div>
            )}
            <Navbar />
            <div className="solution-page events d-grid">
                <div className="text-left">
                    <div className="res-container">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="row">
                                    <h1 className="head m-0">
                                        {rythuBandhamServices.map((service, index) => (
                                            <div key={index}>
                                                {service.service_title}
                                            </div>
                                        ))}</h1>
                                    {rythuBandhamServices.map((service, index) => (
                                        <div key={index}>
                                            <p>{service.service_tagline}</p>
                                        </div>
                                    ))}
                                </div>
                                <div className="row form-group">
                                    {rythuBandhamServices.map((service, index) => (
                                        <div className="text-center service-bg" key={index}>{/* <div className="common-card text-center service-bg" key={index}>*/}
                                            <img src={service.service_banner_image} alt={service.service_headline} className="service-banner" />
                                        </div>
                                    ))}
                                </div>
                                <div className="row">
                                    <div className='card common-card'>
                                        <div className="card-header">
                                            <h4 className="head m-0">Spend & Raise Graph</h4>
                                        </div>
                                        <div className="card-body">
                                            <div id="spent" className="chart-container">
                                                {graphData && graphData?.datasets?.length > 0 ? <Bar data={graphData} options={graphOption} /> : ""}

                                                <ul style={{ listStyle: 'none', padding: '0', marginBottom: '0' }}>{graphLegendItems}</ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="">{/* <div className="card-mid"> */}
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        {/* <li className="nav-item" role="presentation">
                                            <a className="nav-link" id="events-tab" data-bs-toggle="tab" href="#events" role="tab" aria-controls="events" aria-selected="true"><h4 className="head">Events</h4></a>
                                        </li> */}
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link active" id="description-tab" data-bs-toggle="tab" href="#description" role="tab" aria-controls="description" aria-selected="false"><h4 className="head">Description</h4></a>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link" id="image-tab" data-bs-toggle="tab" href="#image" role="tab" aria-controls="image" aria-selected="false"><h4 className="head">Images</h4></a>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link" id="video-tab" data-bs-toggle="tab" href="#video" role="tab" aria-controls="video" aria-selected="false"><h4 className="head">Video</h4></a>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="myTabContent">

                                        <div className="tab-pane fade show active pt-20" id="description" role="tabpanel" aria-labelledby="description-tab">
                                            {rythuBandhamServices.map((service, index) => (
                                                <div className="" key={index}>
                                                    <p style={{ textAlign: 'justify' }}>{service.service_description}</p>
                                                </div>
                                            ))}

                                            <div className="attachments">
                                                <div className="card mb-15" style={{ borderRadius: '4.3vh', padding: '1.5vh' }}>
                                                    <div className='d-flex jc-sb'>
                                                        <div>
                                                            <img src={attachment} className="filter-green" /> <i>File Name Goes here</i>
                                                        </div>
                                                        <Link className='green' style={{ textDecoration: 'none' }} onClick={handleClickFrame}>
                                                            Open
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade pt-20" id="image" role="tabpanel" aria-labelledby="image-tab">
                                            <div className="gallery-grid">
                                                <div className="row" style={{ width: '100%' }}>
                                                    {imagesToDisplay?.map((image) => (
                                                        <div className="col-6 gallery-outer4" key={image?.Id}>
                                                            <div
                                                                className="gallery-image4"
                                                                style={{ backgroundImage: `url(${image?.Path})` }}
                                                                onClick={() => openImageModal(image)}
                                                            ></div>
                                                        </div>
                                                    ))}
                                                </div>

                                            </div>
                                            <div className="pagination">
                                                {Array.from({ length: totalPages }, (_, index) => (
                                                    <button
                                                        key={index}
                                                        className={`page-button ${index + 1 === currentPage ? 'active' : ''}`}
                                                        onClick={() => handlePageChange(index + 1)}
                                                    >
                                                        {index + 1}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                        <ImageModal
                                            showModal={showImageModal}
                                            selectedImage={selectedImage}
                                            closeModal={closeImageModal}
                                        />




                                        <div className="tab-pane fade pt-20" id="video" role="tabpanel" aria-labelledby="video-tab">
                                            {videosToDisplay?.length === 0 ? (
                                                <p>No videos to display.</p>
                                            ) : (
                                                videosToDisplay.map((video, index) => {
                                                    const parentEvent = filteredSolution2Events.find(
                                                        (event) => event.EventGalleryVideos.includes(video)
                                                    );

                                                    return (
                                                        <div className="col-12" key={index}>
                                                            <div className="card mb-20 o-hidden">
                                                                <div className="video-bg" style={{ backgroundImage: `url(${parentEvent.EventBanner})` }}>
                                                                    <button className="btn-transparent" onClick={() => openVideoModal(video)}>
                                                                        <img src={play} />
                                                                    </button>
                                                                </div>
                                                                <div className="gallery-text">
                                                                    <p className="green mb-0">
                                                                        <img src={Labelicon} className="label-icon mr-5" />
                                                                        Category: {video.EventCategory}
                                                                    </p>
                                                                    <h4 className="head mb-5">{video.HeadLine}</h4>
                                                                    <p>Event Name: {parentEvent.EventName}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            )}
                                            <div className="pagination">
                                                {Array.from({ length: numberOfPages }, (_, index) => (
                                                    <button
                                                        key={index}
                                                        className={`page-button ${index + 1 === currentPage ? 'active' : ''}`}
                                                        onClick={() => handlePageChange(index + 1)}
                                                    >
                                                        {index + 1}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>

                                        <VideoModal showModal={showVideoModal} selectedVideo={selectedVideo} closeModal={closeVideoModal} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                {/* <Donation showPersonalDetails={true} showServiceSelection={false} showServiceWiseEvents={true} showLogin={true} displayEvents={eventsData} selectedServiceId={masterServiceCategory} />  */}
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" id="events-tab" data-bs-toggle="tab" href="#events" role="tab" aria-controls="events" aria-selected="true"><h4 className="head">Events</h4></a>
                                    </li>
                                </ul>
                                <div className="pt-20" id="events" role="tabpanel" aria-labelledby="events-tab">
                                    {eventChunks.length === 1 ? (
                                        // If there are only 1 or 2 events, display them normally
                                        eventChunks[0].map((event) => (
                                            <>
                                                <div className="card mb-20 o-hidden">
                                                    <div className="card-header o-hidden event-img2" style={{ backgroundImage: `url(${event.EventImage})` }}>
                                                        {/* <div className="overlay-video">
                                                                                     <img src={video} alt="Video" />
                                                                                </div> */}
                                                        <div className="overlay">
                                                            <div className="row">
                                                                {/* <div className="col text-center">
                                                                                               <p className="cust-time">
                                                                                                    <img src={view} alt="View" className="mr-5" />
                                                                                                    {event.eventStartTime}-{event.eventEndTime}
                                                                                               </p>
                                                                                          </div> */}
                                                                <div className="col  text-center col-middle">
                                                                    <p className="card-text date-p">
                                                                        <span className="head">{formatDate(event.EventStartDate).month}</span>
                                                                        <h1 className="head">{formatDate(event.EventStartDate).day}</h1>
                                                                        <span className="head">{formatDate(event.EventStartDate).year}</span>
                                                                    </p>
                                                                </div>
                                                                {/* <div className="col text-center">
                                                                                               <p className="cust-location">
                                                                                                    <img src={location} alt="View" className="mr-5" />
                                                                                                    {event.eventCity}
                                                                                               </p>
                                                                                          </div> */}
                                                            </div>
                                                        </div>
                                                        {/* <img src={event.eventImage} className="card-img-top" alt={event.eventName} /> */}
                                                        <div className="overlay2"></div>
                                                    </div>

                                                    <div className="card-body">
                                                        <h4 className="card-title">{event.EventName}</h4>
                                                        <p>{event.EventMotto}</p>
                                                        <Link to={`/events/${event.Id}`} className="btn btn-tertiary">
                                                            <img src={view} alt="View" />
                                                        </Link>
                                                    </div>
                                                </div>
                                                {/* <div className="text-center">
                                                                           <NavLink className="btn btn-primary btn-sm" to="/events">View All</NavLink>
                                                                      </div> */}
                                            </>
                                        ))
                                    ) : (
                                        // If there are more than 2 events, display them in a carousel
                                        <div id="eventCarousel" className="carousel slide" data-bs-ride="carousel">
                                            <div className="carousel-inner">
                                                {eventChunks.map((events, index) => (
                                                    <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}>
                                                        {events.map((event) => (
                                                            <div className="card mb-20 o-hidden">
                                                                <div className="card-header o-hidden event-img2" style={{ backgroundImage: `url(${event.EventBanner})` }}>
                                                                    {/* <div className="overlay-video" onClick={() => openVideoModal(event.eventVideos[0])}>
                                                                                                    <img src={video} alt="Video" />
                                                                                               </div> */}
                                                                    <div className="overlay">
                                                                        <div className="row">
                                                                            {/* <div className="col text-center">
                                                                                                              <p className="cust-time">
                                                                                                                   <img src={view} alt="View" className="mr-5" />
                                                                                                                   {event.eventStartTime}
                                                                                                              </p>
                                                                                                         </div> */}
                                                                            <div className="col  text-center col-middle">
                                                                                <p className="card-text date-p">
                                                                                    <span className="head">{formatDate(event.EventStartDate).month}</span>
                                                                                    <h1 className="head">{formatDate(event.EventStartDate).day}</h1>
                                                                                    <span className="head">{formatDate(event.EventStartDate).year}</span>
                                                                                </p>
                                                                            </div>
                                                                            {/* <div className="col text-center">
                                                                                                              <p className="cust-location">
                                                                                                                   <img src={location} alt="View" className="mr-5" />
                                                                                                                   {event.eventCity}
                                                                                                              </p>
                                                                                                         </div> */}
                                                                        </div>
                                                                    </div>
                                                                    {/* <img src={event.eventBanner} className="card-img-top" alt={event.eventName} /> */}
                                                                    <div className="overlay2"></div>
                                                                </div>

                                                                <div className="card-body">
                                                                    <h4 className="card-title">{event.EventName}</h4>
                                                                    <p>{event.EventMotto}</p>
                                                                    <Link to={`/events/${event.Id}`} className="btn btn-tertiary">
                                                                        <img src={view} alt="View" />
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="d-flex form-group m-0 solution-controls ">
                                                <div className="carousel-outer d-flex jc-c">
                                                    <button className="carousel-control-prev" type="button" data-bs-target="#eventCarousel" data-bs-slide="prev">
                                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                        <span className="visually-hidden">Previous</span>
                                                    </button>
                                                    {/* <div className="carousel-indicators solution-indicators">
                                                                           {eventChunks.map((events, index) => (
                                                                                <button
                                                                                     key={index}
                                                                                     type="button"
                                                                                     data-bs-target="#eventCarousel"
                                                                                     data-bs-slide-to={index}
                                                                                     className={index === 0 ? "active" : ""}
                                                                                     aria-current={index === 0 ? "true" : "false"}
                                                                                     aria-label={`Slide ${index + 1}`}
                                                                                ></button>
                                                                           ))}
                                                                      </div> */}
                                                    {/* <NavLink className="btn btn-primary btn-sm" to="/events">View All</NavLink> */}
                                                    <button className="carousel-control-next" type="button" data-bs-target="#eventCarousel" data-bs-slide="next">
                                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                        <span className="visually-hidden">Next</span>
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}