import React from 'react';
import CustomerNavbar from '../components/CustomerNavbar';
import CustomerEventTabs from '../pages/CustomerEventTabs';


export default function CustomerEvents({ setToken }) {
     return (
          <>
             <CustomerNavbar setToken={setToken} />
               <div className="events sm-pt-10">
                    <div className="res-container">
                         <div className="row">
                         <div className="col-12">
                         <div className="card p-0">
                              <div className="row">
                                   <div className="col-md-12 p-0">
                                        <div className="card common-card">
                                             <div className="card-body">
                                                  <CustomerEventTabs />
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              </div>
                              </div>
                         </div>
                    </div>
               </div>
          </>
     );
}
