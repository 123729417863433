import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import labelicon from '../images/label.png';
//import jsonData from '../components/events.json';
import ImageModal from '../components/ImageModal'; // Import the ImageModal component
import VideoModal from '../components/VideoModal'; // Import the VideoModal component
import Labelicon from '../images/label.png';
import play from '../images/play.png';
import axios from 'axios';


const API_URL = 'https://manamvanam.com/api/events';

export default function Gallery() {
     const [jsonData, setData] = useState([]);
     const [currentPage, setCurrentPage] = useState(1);
     const [showImageModal, setShowImageModal] = useState(false);
     const [showVideoModal, setShowVideoModal] = useState(false);
     const [selectedImage, setSelectedImage] = useState(null);
     const [selectedVideo, setSelectedVideo] = useState(null);

     const itemsPerPage = 12;

    useEffect(() => {
        // Perform AJAX call using Axios here
        axios.get(`${API_URL}`)
            .then(response => {
                console.log(response);
                const fetchedData = response.data;
                setData(fetchedData); // Store the fetched data in state
                // Process the data
            })
            .catch(error => {
                // Handle errors
            });
    }, []); // The empty array means this effect will run only once (on mount)


    const openImageModal = (image) => {
        console.log(image);
          const parentEvent = jsonData.find((event) =>
               event.EventGalleryImages.some((img) => img.Id === image.Id)
         );
         if (image!=null)
          setSelectedImage({
               Path: image.Path,
              HeadLine: image.HeadLine,
               EventName: parentEvent.EventName,
               Id: parentEvent.Id,
               EventCategory: parentEvent.EventCategory,
          });
          setShowImageModal(true);
     };

     const openVideoModal = (video) => {
          setSelectedVideo(video);
          setShowVideoModal(true);
     };

     const closeImageModal = () => {
          setShowImageModal(false);
     };

     const closeVideoModal = () => {
          setShowVideoModal(false);
     };

     const startIndex = (currentPage - 1) * itemsPerPage;
     const endIndex = startIndex + itemsPerPage;

     const allImages = jsonData?.map((eventdetail) =>
         eventdetail.EventGalleryImages?.map((image) => ({
                    ...image,
             EventName: eventdetail.EventName,
               }))
          )
          .flat();

     const imagesToShow = allImages.slice(startIndex, endIndex);
    console.log(imagesToShow);
     const handlePageChange = (newPage) => {
          setCurrentPage(newPage);
     };

    const allVideos = jsonData
        ?.map((eventdetail) =>
            eventdetail.eventGalleryVideos?.length > 0
                ? eventdetail.eventGalleryVideos.map((video) => ({
                    ...video,
                    EventName: eventdetail.EventName,
                    EventCategory: eventdetail.EventCategory,
                }))
                : null
        )
        .filter((videos) => videos !== null)
        .flat();

     const videosPerPage = 8;
     const startIndexVideos = (currentPage - 1) * videosPerPage;
     const endIndexVideos = startIndexVideos + videosPerPage;

     const videosToShow = allVideos.slice(startIndexVideos, endIndexVideos);
     
     return (
          <>
               <Navbar />
               <div className="events">
                    <div className="res-container">
                         <div className="row no-padding event-head">
                              <div className="col d-flex jc-sb plr-20">
                                   <h2 className="head">Gallery</h2>
                                   <ul className="nav nav-tabs">
                                        <li className="nav-item" role="presentation">
                                             <a
                                                  className="nav-link active"
                                                  id="image-tab"
                                                  data-bs-toggle="tab"
                                                  href="#image"
                                                  role="tab"
                                                  aria-controls="image"
                                                  aria-selected="true"
                                             >
                                                  Images
                                             </a>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                             <a
                                                  className="nav-link"
                                                  id="video-tab"
                                                  data-bs-toggle="tab"
                                                  href="#video"
                                                  role="tab"
                                                  aria-controls="video"
                                                  aria-selected="false"
                                             >
                                                  Videos
                                             </a>
                                        </li>
                                   </ul>
                                   <h2 className="head2">&nbsp;</h2>
                              </div>
                         </div>
                         <div className="row mt-3">
                              <div className="tab-content p-0" id="myTabContent">
                                   <div
                                        className="tab-pane fade show active"
                                        id="image"
                                        role="tabpanel"
                                        aria-labelledby="image-tab"
                                   >
                                        <div className="row">
                                             {imagesToShow.map((image, index) => (
                                                  <div className="col-3 gallery-outer2" key={index}>
                                                       <div
                                                            className="gallery-image2"
                                                            style={{ backgroundImage: `url(${image?.Path})` }}
                                                            onClick={() => openImageModal(image)} // Open modal when clicking on image
                                                       >
                                                            <div className="image-headline">
                                                                 <p className="white mb-10">
                                                                      <img src={labelicon} className="label-icon mr-5" />
                                                                 {image?.HeadLine}
                                                                 </p>
                                                            </div>
                                                       </div>
                                                  </div>
                                             ))}
                                        </div>
                                        {allImages.length > itemsPerPage && (
                                             <div className="pagination">
                                                  {Array.from(
                                                       { length: Math.ceil(allImages.length / itemsPerPage) },
                                                       (_, index) => (
                                                            <button
                                                                 key={index}
                                                                 className={`page-button ${index + 1 === currentPage ? 'active' : ''
                                                                      }`}
                                                                 onClick={() => handlePageChange(index + 1)}
                                                            >
                                                                 {index + 1}
                                                            </button>
                                                       )
                                                  )}
                                             </div>
                                        )}
                                   </div>
                                   <div
                                        className="tab-pane fade"
                                        id="video"
                                        role="tabpanel"
                                        aria-labelledby="video-tab"
                                   >
                                        <div className="row">
                                             {videosToShow.map((video, index) => {
                                                  const parentEvent = jsonData.find((event) =>
                                                       event.EventGalleryVideos?.some((v) => v.Path === video.Path)
                                                  );

                                                  return (
                                                       <div className="col-3" key={index}>
                                                            <div className="card mb-20 o-hidden">
                                                              <div className="video-bg" style={{ backgroundImage: `url(${parentEvent?.EventBanner})` }}>
                                                                      <button className="btn-transparent" onClick={() => openVideoModal(video)}><img src={play} /></button>
                                                                 </div>
                                                                 <div
                                                                      className="gallery-text"
                                                                 >
                                                                      {/* Use the parent event's eventBanner */}

                                                                      <p className="green mb-0">
                                                                           <img src={Labelicon} className="label-icon mr-5" />
                                                                           Category: {video?.EventCategory}
                                                                      </p>
                                                                  <h4 className="head mb-5">{video?.HeadLine}</h4>
                                                                      <p>Event Name: {video?.EventName}</p>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  );
                                             })}
                                        </div>

                                        {allVideos.length > videosPerPage && (
                                             <div className="pagination">
                                                  {Array.from(
                                                       { length: Math.ceil(allVideos.length / videosPerPage) },
                                                       (_, index) => (
                                                            <button
                                                                 key={index}
                                                                 className={`page-button ${index + 1 === currentPage ? 'active' : ''
                                                                      }`}
                                                                 onClick={() => handlePageChange(index + 1)}
                                                            >
                                                                 {index + 1}
                                                            </button>
                                                       )
                                                  )}
                                             </div>
                                        )}
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>

               {/* Use the ImageModal component */}
               <ImageModal
                    showModal={showImageModal}
                    selectedImage={selectedImage}
                    closeModal={closeImageModal}
               />

               {/* Use the VideoModal component */}
               <VideoModal
                    showModal={showVideoModal}
                    selectedVideo={selectedVideo}
                    closeModal={closeVideoModal}
               />
          </>
     );
}
