import React from 'react';
import { NavLink } from 'react-router-dom';


export default function Footer() {
     return (
          <section className="footer">
             <div className="col-lg-12 p-0 text-center"><p className="m-auto">© { (new Date().getFullYear()) } <NavLink className="nav-link" to="/" >Manam Vanam</NavLink> -  All Rights Reserved</p></div>
          </section>
     )
}

