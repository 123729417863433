import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import boy from '../images/boy.png';
import location from '../images/location-colored.png';
import phone from '../images/phone-colored.png';
import mail from '../images/mail-colored.png';
import facebook from '../images/facebook-colored.png';
import twitter from '../images/twitter-colored.png';
import google from '../images/google-colored.png';
import linkedin from '../images/linkedin-colored.png';
import { NavLink } from 'react-router-dom';

import EnrollForm from '../components/enrollForm.js'


export default function ContactUs() {
     return (
          <>
               <Navbar />
               <div className="contact row no-gutters w-100">
                    <div className="bg-left col-lg-5 sm-o-2">
                         <div className="background-image bg-left2" >
                              <div className="">
                                   <div className="contact-form d-grid text-left">
                                        <div className="mb-20">
                                             <h1 className="mb-20">Our Contact Information</h1>
                                             <p><img className="contact-image" src={location} alt="location" /><span className="ml-5">Lawsonsbay Colony,
                                                  Visakhapatnam-530017,
                                                  Andhra Pradesh, India</span></p>
                                     <p><img className="contact-image" src={phone} alt="phone" /><span className="ml-5">+91 99660 77000</span></p>
                                             <p><img className="contact-image" src={mail} alt="mail" /><span className="ml-5">info@manamvanam.com</span></p>
                                        </div>
                                        <div className="d-flex">
                                             <NavLink to="/contactus" target="_blank">
                                                  <img className="social-media mr-10" src={facebook} alt="facebook" />
                                             </NavLink>
                                             <NavLink to="/contactus" target="_blank">
                                                  <img className="social-media mr-10" src={twitter} alt="twitter" />
                                             </NavLink>
                                             <NavLink to="/contactus" target="_blank">
                                                  <img className="social-media mr-10" src={google} alt="google" />
                                             </NavLink>
                                             <NavLink to="/contactus" target="_blank">
                                                  <img className="social-media mr-10" src={linkedin} alt="linkedin" />
                                             </NavLink>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
                    {/* <img className="middle-image" src={boy} alt="Kids" /> */}
                    <div className="bg-right col-lg-7 sm-o-1">
                         <div className="contact-form d-grid text-center">
                              <span className="tagline">Contact us</span>
                              <h1 className="mb-20">Have a question ? Get in touch</h1>
                              <div className="p-20"><EnrollForm className="col-6" showVolunteer={false} showMessage={true} showBloodGroup={false} showSubject={true} /></div>


                         </div>
                    </div>
               </div >
               <Footer />
          </>
     )
}

