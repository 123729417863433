import React, { useState, useEffect } from 'react';
import AdminNavbar from '../components/adminNavbar';
import { Link } from 'react-router-dom';
//import customerData from '../../components/customer.json';
import axios from 'axios';
import Moment from 'react-moment';
import 'moment-timezone';

const API_URL = 'https://manamvanam.com/api/AdminDonations';

export default function AdminDonation() {
     const [openCustomerId, setOpenCustomerId] = useState(null);
     const [currentPage, setCurrentPage] = useState(1);
     const [searchQuery, setSearchQuery] = useState('');
     const [selectedDonationType, setSelectedDonationType] = useState('');
     const entriesPerPage = 10;
     const [customerData, setData] = useState([]);

     useEffect(() => {
          // Perform AJAX call using Axios here
          axios.get(`${API_URL}`)
               .then(response => {
                    console.log(response);
                    const fetchedData = response.data;

                    setData(fetchedData); // Store the fetched data in state
                    // Process the data
                    console.log(customerData);

               })
               .catch(error => {
                    // Handle errors
               });
     }, []); // The empty array means this effect will run only once (on mount)


     const toggleDetails = (customerId) => {
          if (customerId === openCustomerId) {
               setOpenCustomerId(null);
          } else {
               setOpenCustomerId(customerId);
          }
     };

     const indexOfLastEntry = currentPage * entriesPerPage;
     const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;

     let filteredCustomerData = customerData
          .filter((customer) =>
               customer['DonarName'].toLowerCase().includes(searchQuery.toLowerCase())
          )
          .filter((customer) =>
               selectedDonationType === ''
                    ? true
                    : customer['DonationType'].toLowerCase() === selectedDonationType
          );

     // Sort the data by donation date in descending order
     filteredCustomerData.sort((a, b) => new Moment(b['DonationDate'], "DD-MM-YYYY") - new Moment(a['DonationDate'], "DD-MM-YYYY"));

     const currentEntries = filteredCustomerData.slice(indexOfFirstEntry, indexOfLastEntry);

     const paginate = (pageNumber) => {
          setCurrentPage(pageNumber);
          setOpenCustomerId(null);
     };

     const handleSearchChange = (event) => {
          setSearchQuery(event.target.value);
          setCurrentPage(1);
          setOpenCustomerId(null);
     };

     const handleDonationTypeChange = (event) => {
          const selectedType = event.target.value.toLowerCase();
          setSelectedDonationType(selectedType);
          setCurrentPage(1);
          setOpenCustomerId(null);
     };

     const clearSearch = () => {
          setSearchQuery('');
          setSelectedDonationType('');
     };

     const formatDate = (dateString) => {
          const date = new Date(dateString);
          const day = date.getDate().toString().padStart(2, '0');
          const month = (date.getMonth() + 1).toString().padStart(2, '0');
          const year = date.getFullYear();
          return `${day}-${month}-${year}`;
     };

     return (
          <>
               <AdminNavbar />
               <div className="events">
                    <div className="res-container donation2 sm-pt-10">
                         <div className="row">
                              <div className="col-12">
                                   <div className="card p-0">
                                        <div className="row">
                                             <div className="col-md-12 p-0">
                                                  <div className="card common-card">
                                                       <div className="card-header d-flex jc-sb">
                                                            <div className="row d-flex w-100">
                                                                 <div className="col-4">
                                                                      <h3 className="head m-0">Donation</h3>
                                                                 </div>
                                                                 <div className="col-8 d-flex jc-e">
                                                                      <div className="col-3 mr-20">
                                                                           <input
                                                                                type="text"
                                                                                placeholder="Search by Customer Name"
                                                                                value={searchQuery}
                                                                                onChange={handleSearchChange}
                                                                                className="form-control"
                                                                           />
                                                                      </div>
                                                                      <div className="col-3 mr-20">
                                                                           <select
                                                                                className="form-control"
                                                                                value={selectedDonationType}
                                                                                onChange={handleDonationTypeChange}
                                                                           >
                                                                                <option value="">Select Donation Type</option>
                                                                                <option value="onetime">One-Time</option>
                                                                                <option value="recurring">Recurring</option>
                                                                           </select>
                                                                      </div>
                                                                      {/* <div className="mr-20">
                                                                 <Link to="" className="btn btn-outline" onClick={clearSearch}>
                                                                      Clear
                                                                 </Link>
                                                            </div> */}
                                                                      <div>
                                                                           <Link to="/admin/offlinedonation" className="btn btn-primary">
                                                                                Add Offline Donation
                                                                           </Link>
                                                         </div>
                                                         <div>
                                                             <Link to="/admin/offlinecompanydonation" className="btn btn-primary">
                                                                 Add Offline Company Donation
                                                             </Link>
                                                         </div>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                       <div className="card-body">
                                                            <div className="overflow-scroll">
                                                                 <table className="table">
                                                                      <thead>
                                                                           <tr>
                                                                                <th>Sl No.</th>
                                                                                <th>Donar Name</th>
                                                                                <th>Donation Date</th>
                                                                                <th>Donation Type</th>
                                                                                <th>Payment Status</th>
                                                                                <th>Payment Method</th>
                                                                                <th>Transaction Number</th>
                                                                                <th>Donation Amount</th>
                                                                                <th>Action</th>
                                                                           </tr>
                                                                      </thead>
                                                                      <tbody>
                                                                           {currentEntries.map((customer, index) => (
                                                                                <React.Fragment key={customer.DonationOrderId}>
                                                                                     <tr>
                                                                                          <td>{index + 1 + indexOfFirstEntry}</td>
                                                                                          <td>{customer['DonarName']}</td>
                                                                                          <td> <Moment fromNow ago parse="DD-MM-YYYY" date={customer['DonationDate']} format="DD-MM-YYYY" /></td>
                                                                                          <td>{customer['DonationType']}</td>
                                                                                          <td>{customer['PaymentStatus']}</td>
                                                                                          <td>{customer['PaymentMethod']}</td>
                                                                                          <td>{customer['TransactionNumber']}</td>
                                                                                          <td>
                                                                                               Rs.
                                                                                               {customer['DonationAmount']}
                                                                                          </td>
                                                                                          <td>
                                                                                               <button
                                                                                                    onClick={() => toggleDetails(customer.DonationOrderId)}
                                                                                                    className="btn btn-primary btn-action"
                                                                                               >
                                                                                                    {openCustomerId === customer.DonationOrderId ? '-' : '+'}
                                                                                               </button>
                                                                                          </td>
                                                                                     </tr>
                                                                                     {openCustomerId === customer.DonationOrderId && (
                                                                                          <tr>
                                                                                               <td colSpan="8" className="text-center">
                                                                                                    {/*{Object.keys(customer['DonationServices']).map(*/}
                                                                                                    {/*          (solution) => (*/}
                                                                                                    {/*               <React.Fragment key={solution}>*/}
                                                                                                    {/*                    <button className="btn btn-silver btn-sm mr-20">*/}
                                                                                                    {/*                {solution}: Rs.{customer['DonationServices'][solution].toFixed(*/}
                                                                                                    {/*                              2*/}
                                                                                                    {/*                         )}*/}
                                                                                                    {/*                    </button>*/}
                                                                                                    {/*               </React.Fragment>*/}
                                                                                                    {/*          )*/}
                                                                                                    {/*     )}*/}
                                                                                                    {customer?.DonationServices.map((donationService, index) => (
                                                                                                         <React.Fragment >
                                                                                                              <button className="btn btn-silver btn-sm mr-20">
                                                                                                                   {donationService.ServiceName}: Rs.{donationService.ServiceDonationAmount.toFixed(
                                                                                                                        2
                                                                                                                   )}
                                                                                                              </button>
                                                                                                         </React.Fragment>
                                                                                                    ))}
                                                                                               </td>
                                                                                          </tr>
                                                                                     )}
                                                                                </React.Fragment>
                                                                           ))}
                                                                      </tbody>
                                                                 </table>
                                                            </div>
                                                            <ul className="pagination">
                                                                 {Array.from({ length: Math.ceil(filteredCustomerData.length / entriesPerPage) }).map(
                                                                      (item, index) => (
                                                                           <li
                                                                                key={index}
                                                                                className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                                                                           >
                                                                                <button
                                                                                     className="page-link"
                                                                                     onClick={() => paginate(index + 1)}
                                                                                >
                                                                                     {index + 1}
                                                                                </button>
                                                                           </li>
                                                                      )
                                                                 )}
                                                            </ul>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </>
     );
}
