import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import logo from '../images/logo.png';
import paymentsuccess from '../images/payment-successfull.png';

export default function PaymentSuccessful() {
    //const [jsonData, setData] = useState(null);
    const location = useLocation();
    const jsonData = location.state;
     return (
          <>
               <section className="header">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                         <div className="container-fluid">
                              <NavLink className="navbar-brand logo" to="/">
                                   <img src={logo} alt="Logo" />
                              </NavLink>
                         </div>
                    </nav>
               </section>
               <div className="d-flex jc-sb" style={{ alignContent: 'center', flexWrap: 'wrap', height: '100vh' }}>
                    <div className="res-container" style={{ maxWidth: '70%' }}>
                         <div className="row">
                              <div className="col-6 d-flex jc-sb" style={{ alignContent: 'center', flexWrap: 'wrap' }}>
                                   <div classNAme="d-grid">
                                        <h1 className="head mb-20">Payment successfull</h1>
                                 <p className="mb-10">Thank you for donating to manamvanam. Your payment invoice email is sent to your registered email.</p>

                                 {jsonData?.PaymentRefNumber && <p className="mb-40">Transaction number : {jsonData?.PaymentRefNumber}</p>}
                                 {jsonData?.AuthId && <p className="mb-40">Transaction number : {jsonData?.AuthId}</p>}
                                 <p className="mb-40">Payment Status : {jsonData?.PaymentStatus}</p>
                                 <p className="mb-40">Payment Message : {jsonData?.PaymentMessage}</p>
                                 {jsonData?.ReceiptUrl !==""? <NavLink className="btn btn-primary mr-20" to={jsonData?.ReceiptUrl}>Download Invoice</NavLink>:""}
                                 
                                        <NavLink className="btn btn-outline" to="/">Back to home</NavLink>
                                   </div>
                              </div>
                              <div className="col-6">
                                   <img src={paymentsuccess} alt="payment" style={{ width: '100vh', padding: '0 30% 0 0' }} />
                              </div>
                         </div>
                    </div>
               </div>
          </>
     )
}

