import React, { useState, useEffect } from 'react';
import view from '../images/view.png';
import clock from '../images/clock.png';
import location from '../images/location.png';
import { Link } from 'react-router-dom'; // Import Link from React Router
import axios from 'axios';
//import jsonData from './events.json';

const API_URL = 'https://manamvanam.com/api/events';
const SERVICE_API_URL = 'https://manamvanam.com/api/ServiceData';

const EventTabs = () => {
     const [activeTab, setActiveTab] = useState('All');
     const [jsonData, setData] = useState([]);
    const [serviceData, setServiceData] = useState([]);

     useEffect(() => {
         // Perform AJAX call using Axios here
         axios.get(`${SERVICE_API_URL}`)
             .then(response => {
                 const fetchedServiceData = response.data;
                 setServiceData(fetchedServiceData); // Store the fetched data in state


             })
             .catch(error => {
                 // Handle errors
             });

          axios.get(`${API_URL}`)
               .then(response => {
                    console.log(response);
                    const fetchedData = response.data;
                    setData(fetchedData); // Store the fetched data in state
                    // Process the data
               })
               .catch(error => {
                    // Handle errors
               });
     }, []); // The empty array means this effect will run only once (on mount)

     const handleTabChange = (category) => {
          setActiveTab(category);
     };

     const getEventsByCategory = (category) => {
          if (category === 'All') {
               return jsonData;
          }
          return jsonData.filter((event) => event.EventMasterServiceId === category);
     };

     const formatDate = (dateString) => {
          const date = new Date(dateString);
          const monthNames = [
               'Jan', 'Feb', 'March', 'April', 'May', 'June', 'July',
               'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
          ];
          const month = monthNames[date.getMonth()];
          const day = String(date.getDate()).padStart(2, '0');
          const year = date.getFullYear();
          return { month, day, year };
     };

     const chunkEvents = (events, chunkSize) => {
          const result = [];
          for (let i = 0; i < events.length; i += chunkSize) {
               result.push(events.slice(i, i + chunkSize));
          }
          return result;
     };

     return (
          <div className="res-container">
               <div className="row no-padding event-head">
                    <div className="col d-flex jc-sb plr-20">
                         <h2 className="head">Events</h2>
                         <ul className="nav nav-tabs">
                              <li className="nav-item">
                                   <button
                                        className={`nav-link ${activeTab === 'All' ? 'active' : ''}`}
                                        onClick={() => handleTabChange('All')}
                                   >
                                        All Events
                                   </button>
                              </li>
                         {/*     <li className="nav-item">*/}
                         {/*          <button*/}
                         {/*               className={`nav-link ${activeTab === '1' ? 'active' : ''}`}*/}
                         {/*               onClick={() => handleTabChange('1')}*/}
                         {/*          > Rythu bandham*/}

                         {/*          </button>*/}
                         {/*     </li>*/}
                         {/*     <li className="nav-item">*/}
                         {/*          <button*/}
                         {/*               className={`nav-link ${activeTab === '2' ? 'active' : ''}`}*/}
                         {/*               onClick={() => handleTabChange('2')}*/}
                         {/*          >*/}
                         {/*               Girijana bandham*/}
                         {/*          </button>*/}
                         {/*     </li>*/}
                         {/*     <li className="nav-item">*/}
                         {/*          <button*/}
                         {/*               className={`nav-link ${activeTab === '3' ? 'active' : ''}`}*/}
                         {/*               onClick={() => handleTabChange('3')}*/}
                         {/*          >*/}
                         {/*               Grama bandham*/}
                         {/*          </button>*/}
                         {/*     </li>*/}
                         {/*     <li className="nav-item">*/}
                         {/*          <button*/}
                         {/*               className={`nav-link ${activeTab === '4' ? 'active' : ''}`}*/}
                         {/*               onClick={() => handleTabChange('4')}*/}
                         {/*          >*/}
                         {/*               Janahita bandham*/}
                         {/*          </button>*/}
                         {/*     </li>*/}
                         {/*     <li className="nav-item">*/}
                         {/*          <button*/}
                         {/*               className={`nav-link ${activeTab === '5' ? 'active' : ''}`}*/}
                         {/*               onClick={() => handleTabChange('5')}*/}
                         {/*          >*/}
                         {/*               Paryavaranam bandham*/}
                         {/*          </button>*/}
                         {/*</li>*/}
                         {
                             serviceData.map((service) => (
                                 <li className="nav-item">
                                     <button
                                         className={`nav-link ${activeTab === service.master_service_id.toString()  ? 'active' : ''}`}
                                         onClick={() => handleTabChange(service.master_service_id.toString())}
                                     >
                                         {service.service_title}
                                     </button>
                                 </li>
                             ))
                         }
                         </ul>
                         <h2 className="head2">&nbsp;</h2>
                    </div>
               </div>

               <div className="tab-content mt-3">
                    {['All', '1', '2', '3', '4', '5'].map((category) => (
                         <div key={category} className={`tab-pane ${activeTab === category ? 'active' : ''}`}>
                              {getEventsByCategory(category)?.length > 6 ? (
                                   <div id={`carousel-${category}`} className="carousel slide" data-bs-ride="carousel" data-bs-interval="25000">
                                        <div className="carousel-inner">
                                             {chunkEvents(getEventsByCategory(category), 6).map((eventChunk, index) => (
                                                  <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                                       <div className="row">
                                                            {eventChunk.map((event) => (
                                                                 <div key={event.Id} className="col-md-4">
                                                                      <div className="card mb-20 o-hidden">
                                                                           <Link to={`/events/${event.Id}`}>
                                                                                <div className="card-header o-hidden event-img2" style={{ backgroundImage: `url(${event.EventBanner})` }}>
                                                                                     <div className="overlay">
                                                                                          <div className="row">
                                                                                               {/* <div className="col text-center">
                                                                                                    <p className="cust-time">
                                                                                                         <img src={view} alt="View" className="mr-5" />
                                                                                                         {event.EventStartTime}
                                                                                                    </p>
                                                                                               </div> */}
                                                                                               <div className="col  text-center col-middle">
                                                                                                    <p className="card-text date-p">
                                                                                                         <span className="head">{formatDate(event.EventStartDate).month}</span>
                                                                                                         <h1 className="head">{formatDate(event.EventStartDate).day}</h1>
                                                                                                         <span className="head">{formatDate(event.EventStartDate).year}</span>
                                                                                                    </p>
                                                                                               </div>
                                                                                               {/* <div className="col text-center">
                                                                                                    <p className="cust-location">
                                                                                                         <img src={location} alt="View" className="mr-5" />
                                                                                                         {event.EventCity}
                                                                                                    </p>
                                                                                               </div> */}
                                                                                          </div>
                                                                                     </div>
                                                                                     {/* <img src={event.EventBanner} className="card-img-top" alt={event.eventName} /> */}
                                                                                     <div className="overlay2"></div>
                                                                                </div>
                                                                           </Link>
                                                                           <div className="card-body">
                                                                                {/* <p className="mb-5">Category : <span className="green">{event.eventCategory}</span></p> */}
                                                                                <h4 className="card-title">{event.EventName}</h4>
                                                                                <p>{event.EventMotto}</p>
                                                                                <Link to={`/events/${event.Id}`} className="btn btn-tertiary">
                                                                                     <img src={view} alt="View" />
                                                                                </Link>
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            ))}
                                                       </div>
                                                  </div>
                                             ))}
                                        </div>
                                        <button
                                             className="carousel-control-prev"
                                             type="button"
                                             data-bs-target={`#carousel-${category}`}
                                             data-bs-slide="prev"
                                        >
                                             <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                             <span className="visually-hidden">Previous</span>
                                        </button>
                                        <button
                                             className="carousel-control-next"
                                             type="button"
                                             data-bs-target={`#carousel-${category}`}
                                             data-bs-slide="next"
                                        >
                                             <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                             <span className="visually-hidden">Next</span>
                                        </button>
                                        <ol className="carousel-indicators custom-indicators">
                                             {chunkEvents(getEventsByCategory(category), 6).map((_, index) => (
                                                  <li
                                                       key={index}
                                                       data-bs-target={`#carousel-${category}`}
                                                       data-bs-slide-to={index}
                                                       className={index === 0 ? 'active' : ''}
                                                  ></li>
                                             ))}
                                        </ol>
                                   </div>
                              ) : (
                                   <div className="row">
                                        {getEventsByCategory(category).map((event) => (
                                             <div key={event.Id} className="col-md-4">
                                                  <div className="card mb-20 o-hidden">
                                                       <Link to={`/events/${event.Id}`}>
                                                            <div className="card-header o-hidden event-img2" style={{ backgroundImage: `url(${event.EventImage})` }}>
                                                                 <div className="overlay">
                                                                      <div className="row">
                                                                           {/* <div className="col text-center">
                                                                                <p className="cust-time">
                                                                                     <img src={view} alt="View" className="mr-5" />
                                                                                     {event.EventStartTime}-{event.EventEndTime}
                                                                                </p>
                                                                           </div> */}
                                                                           <div className="col  text-center col-middle">
                                                                                <p className="card-text date-p">
                                                                                     <span className="head">{formatDate(event.EventStartDate).month}</span>
                                                                                     <h1 className="head">{formatDate(event.EventStartDate).day}</h1>
                                                                                     <span className="head">{formatDate(event.EventStartDate).year}</span>
                                                                                </p>
                                                                           </div>
                                                                           {/* <div className="col text-center">
                                                                                <p className="cust-location">
                                                                                     <img src={location} alt="View" className="mr-5" />
                                                                                     {event.EventCity}
                                                                                </p>
                                                                           </div> */}
                                                                      </div>
                                                                 </div>
                                                                 {/* <img src={event.EventBanner} className="card-img-top" alt={event.eventName} /> */}
                                                                 <div className="overlay2"></div>
                                                            </div>
                                                       </Link>
                                                       <div className="card-body">
                                                            <h4 className="card-title">{event.EventName}</h4>
                                                            <p>{event.EventMotto}</p>
                                                            <Link to={`/events/${event.Id}`} className="btn btn-tertiary">
                                                                 <img src={view} alt="View" />
                                                            </Link>
                                                       </div>
                                                  </div>
                                             </div>
                                        ))}
                                   </div>
                              )}
                         </div>
                    ))}
               </div>
          </div>
     );
};

export default EventTabs;
