import React, { useState, useEffect } from 'react';
import Axios from 'axios'; // Import Axios
import AdminNavbar from '../components/adminNavbar';
import { Link,  useNavigate } from 'react-router-dom';

export default function AdminAddMedia() {
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const [uploadCount, setUploadCount] = useState(0);
     const [service, setService] = useState('');
     const [eventId, setEvent] = useState('');
     const [mediaItems, setMediaItems] = useState([{ image: null, title: '' }]);
     const [categories, setCategories] = useState([]); // Store categories from the API
     const [allEvents, setAllEvents] = useState([]); // Store all events from the API
     const [filteredEvents, setFilteredEvents] = useState([]); // Filtered events based on service
     const [errors, setErrors] = useState({
          service: '',
          event: '',
          imageErrors: [],
          titleErrors: [],
     });
    

     useEffect(() => {
          // Fetch categories from the API when the component mounts
          Axios.get('https://manamvanam.com/api/ServiceData')
               .then((response) => {
                    setCategories(response.data);
               })
               .catch((error) => {
                    console.error('Error fetching categories: ', error);
               });

          // Fetch all events from the API when the component mounts
          Axios.get('https://manamvanam.com/api/events')
               .then((response) => {
                    setAllEvents(response.data);
                    setFilteredEvents([]); // Initialize filtered events as empty
               })
               .catch((error) => {
                    console.error('Error fetching events: ', error);
               });
     }, []);

     const handleServiceChange = (e) => {
          setService(e.target.value);
          setErrors({ ...errors, service: '' }); // Clear error on change
          // Filter events based on the selected service
          const selectedServiceEvents = allEvents.filter(
              (event) => event.EventCategoryId.toString() === e.target.value
          );
          setFilteredEvents(selectedServiceEvents);
          // Clear the selected event
          setEvent('');
     };

     const handleEventChange = (e) => {
          setEvent(e.target.value);
          setErrors({ ...errors, event: '' }); // Clear error on change
     }

     const handleImageChange = (index, e) => {
          const newMediaItems = [...mediaItems];
          const file = e.target.files[0];
          if (file) {
               if (file.size > 500000) {
                    // Max size is 500KB (500000 bytes)
                    setErrors((prevErrors) => ({
                         ...prevErrors,
                         imageErrors: [...prevErrors.imageErrors, 'Image size should be less than 500KB'],
                    }));
               } else if (!['image/jpeg', 'image/png'].includes(file.type)) {
                    // Check for image format (jpg and png)
                    setErrors((prevErrors) => ({
                         ...prevErrors,
                         imageErrors: [...prevErrors.imageErrors, 'Only JPG and PNG formats are allowed'],
                    }));
               } else {
                    newMediaItems[index].image = file;
                    setErrors({ ...errors, imageErrors: [] }); // Clear error on valid change
               }
          }
          setMediaItems(newMediaItems);
     };

     const handleTitleChange = (index, e) => {
          const newMediaItems = [...mediaItems];
          newMediaItems[index].title = e.target.value;
          setMediaItems(newMediaItems);
          setErrors({ ...errors, titleErrors: [] }); // Clear error on change
     };

     const addMediaItem = () => {
          setMediaItems([...mediaItems, { image: null, title: '' }]);
     };

     const removeMediaItem = (index) => {
          const newMediaItems = [...mediaItems];
          newMediaItems.splice(index, 1);
          setMediaItems(newMediaItems);
     };

     const validateForm = () => {
          const newErrors = { service: '', event: '', imageErrors: [], titleErrors: [] };
         var isValid = true;
          if (!service) {
              newErrors.service = 'Service is required';
              isValid = false;
          }

         if (!eventId) {
              newErrors.event = 'Event is required';
              isValid = false;
          }

          mediaItems.forEach((media, index) => {
               if (!media.image) {
                   newErrors.imageErrors[index] = 'Image is required';
                   isValid = false;
               } else if (media.image && media.image.size > 500000) {
                   newErrors.imageErrors[index] = 'Image size should be less than 500KB';
                   isValid = false;
               }

               if (!media.title) {
                   newErrors.titleErrors[index] = 'Title is required';
                   isValid = false;
               }
          });

          setErrors(newErrors);
         
          // Check if there are any errors
         return isValid;// Object.values(newErrors).every((error) => !error);
     };
  
    const handleSubmit = () => {
        const isValid = validateForm();
        if (isValid) {
            // Handle form submission
        
            
            mediaItems.forEach((media, index) => {
                const eventMediaData = new FormData();
                eventMediaData.append('fileId', index);
                eventMediaData.append('serviceId', service);
                eventMediaData.append('eventId', eventId);
                eventMediaData.append('title', media.title);
                eventMediaData.append('titleDescription', media.title);
                eventMediaData.append('mediaFileData', media.image);
                console.log(eventMediaData);
                Axios.post(`https://manamvanam.com/api/EventMediaUpload`, eventMediaData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`
                    }
                }).then(response => {
                    console.log(response);
                    if (response != null && response !== undefined && response.status === 200) {
                        //fileId
                        setUploadCount(prevCount => prevCount + 1);
                    } else {
                        setErrors(response.errors);
                    }
                })
                    .catch(error => {

                    });
            });
        }
    };
    // Use setInterval to check for completion every second
    const intervalId = setInterval(() => {
        if (uploadCount === mediaItems.length) {
            // All media items are uploaded, clear the interval and redirect
            clearInterval(intervalId);
            navigate('/admin/media');
        }
    }, 1000);
     return (
          <>
               <AdminNavbar />
               <div className="events">
                    <div className="res-container sm-pt-10">
                         <div className="row">
                              <div className="col">
                                   <div className="card p-0 mb-20" style={{ height: '80vh' }}>
                                        <div className="row">
                                             <div className="col-md-12 p-0">
                                                  <div className="card common-card">
                                                       <div className="card-header d-flex jc-sb">
                                                            <h3 className="head m-0">Media Content</h3>
                                                            <Link to="/admin/media" className="btn btn-outline">
                                                                 Back
                                                            </Link>
                                                       </div>
                                                       <div className="card-body admin-service" style={{ overflowY: 'scroll', height: '75vh' }}>
                                                            <div className="row">
                                                                 <div className="col-3 mb-15">
                                                                      <label>Select Service :</label>
                                                                      <select
                                                                           className="form-control mb-0"
                                                                           value={service}
                                                                           onChange={handleServiceChange}
                                                                      >
                                                                           <option value="">Select Service</option>
                                                                           {categories.map((category) => (
                                                                               <option key={category.categoryId} value={category.categoryId}>
                                                                                     {category.category}
                                                                                </option>
                                                                           ))}
                                                                      </select>
                                                                      {errors.service && (
                                                                           <div className="text-danger mb-0">{errors.service}</div>
                                                                      )}
                                                                 </div>
                                                                 <div className="col-3 mb-15">
                                                                      <label>Select Event :</label>
                                                                      <select
                                                                           className="form-control mb-0"
                                                             value={eventId}
                                                                           onChange={handleEventChange}
                                                                      >
                                                                           <option value="">Select Event</option>
                                                                           {filteredEvents.map((event) => (
                                                                               <option key={event.Id} value={event.Id}>
                                                                                     {event.EventName}
                                                                                </option>
                                                                           ))}
                                                                      </select>
                                                                      {errors.event && (
                                                                           <div className="text-danger mb-0">{errors.event}</div>
                                                                      )}
                                                                 </div>
                                                                 <div className="col-3"></div>
                                                                 {mediaItems.map((media, index) => (
                                                                      <div className="col-3 text-right">
                                                                           {index === 0 && (
                                                                                <button
                                                                                     className="btn btn-outline mt-20"
                                                                                     onClick={addMediaItem}
                                                                                >
                                                                                     + Add more
                                                                                </button>
                                                                           )}
                                                                      </div>
                                                                 ))}
                                                            </div>
                                                            {mediaItems.map((media, index) => (
                                                                 <div key={index}>
                                                                      <div className="card pt-20 mb-20">
                                                                           <div className="row">
                                                                                <div className="col-3 mb-15">
                                                                                     <label>Upload Image :</label>
                                                                                     <input
                                                                                          className="form-control mb-0"
                                                                                          type="file"
                                                                                          accept="image/jpeg, image/png"
                                                                                          onChange={(e) => handleImageChange(index, e)}
                                                                                     />
                                                                                     {errors.imageErrors[index] && (
                                                                                          <div className="text-danger mb-0">{errors.imageErrors[index]}</div>
                                                                                     )}
                                                                                </div>
                                                                                <div className="col-6 mb-15">
                                                                                     <label>Image Title :</label>
                                                                                     <input
                                                                                          className="form-control mb-0"
                                                                                          type="text"
                                                                                          placeholder="Image Title"
                                                                                          value={media.title}
                                                                                          onChange={(e) => handleTitleChange(index, e)}
                                                                                     />
                                                                                     {errors.titleErrors[index] && (
                                                                                          <div className="text-danger mb-0">{errors.titleErrors[index]}</div>
                                                                                     )}
                                                                                </div>
                                                                                {index > 0 && (
                                                                                     <div className="col-3  text-right">
                                                                                          <button
                                                                                               className="btn btn-danger mt-20"
                                                                                               onClick={() => removeMediaItem(index)}
                                                                                          >
                                                                                               Remove
                                                                                          </button>
                                                                                     </div>
                                                                                )}
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            ))}
                                                            <div className="row">
                                                                 <div className="col-12 text-right">
                                                                      <button className="btn btn-primary" onClick={handleSubmit}>
                                                                           Submit
                                                                      </button>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </>
     );
}
