import React, { useState, useRef, useEffect } from "react";
import logo from '../../images/logo.png';
import user from '../../images/user.jpg';
import editprofile from '../../images/edit-profile.png';
import { NavLink } from 'react-router-dom';
import Protected from '../../components/Protected';
import axios from 'axios';
const API_URL = 'https://manamvanam.com/api/DonorProfile';

const CustomerNavbar = ({ setToken }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [profileData, setProfileData] = useState({
        FirstName: "",
        LastName: "",
        PhoneNumber: "",
        EmailAddress: "",
        HomeAddress: "",
        City: "",
        State: "",
        Country: "",
        PostalCode: "",
        ProfilePictureUrl: ""
        //  DOB: "",
        //  UserId: 0,
        // Gender:""
    });
    const profileImageRef = useRef(null);
    const userDetails = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    const token = localStorage.getItem('token');

    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const [showEditModal, setShowEditModal] = useState(false);

    const handleEditProfileClick = () => {
        setShowEditModal(true); // Open the edit modal
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            // Validate file type and size
            const validTypes = ["image/jpeg", "image/png"];
            if (validTypes.includes(file.type) && file.size <= 500 * 1024) {
                setSelectedFile(file);
                setErrorMessage("");
            } else {
                setSelectedFile(null);
                setErrorMessage("Please select a valid image (JPEG/PNG) file up to 500KB.");
            }
        }
    };

    const handleUpdateTwo = () => {
        if (!selectedFile) {
            setErrorMessage("Please select an image before updating.");
            return;
        }

        // Logic to update profile with the selected file
        console.log("File to upload:", selectedFile);
        // Close the edit modal after update
        const profileMediaData = new FormData();
        profileMediaData.append('userId', userDetails.UserId);
        profileMediaData.append('mediaFileData', selectedFile);
        console.log(profileMediaData);
        axios.post(`https://manamvanam.com/api/ProfilePicUpload`, profileMediaData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            console.log(response);
            if (response != null && response !== undefined && response.status === 200) {
                //fileId

            } else {

            }
        })
            .catch(error => {

            });
        setShowEditModal(false);
    };

    const toggleDropdown = () => {
        setShowDropdown(prevState => !prevState);
    };

    const toggleModal = () => {
        setShowModal(!showModal);
        setShowDropdown(false); // Close the dropdown when opening the modal
    };

    const handleUpdate = () => {
        profileData.UserId = userDetails.UserId;
        profileData.DOB = "1900-01-01";

        //setProfileData(profileData)
        axios.put(`${API_URL}/${userDetails.UserId}`, profileData, config)
            .then(response => {
                console.log(response);
                setShowModal(!showModal);
                setShowDropdown(false); // Close the dropdown when opening the modal
                // Process the data
            })
            .catch(error => {
                // Handle errors
            });
    };

    const handleLogout = () => {
        // Implement logout logic here
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        //setToken(null);
    };

    useEffect(() => {
        if (profileData.length === 0 || profileData.FirstName === "") {
            // Perform AJAX call using Axios here
            axios.get(`${API_URL}/${userDetails.UserId}`, config)
                .then(response => {
                    console.log(response);
                    const fetchedData = response.data;
                    setProfileData(fetchedData); // Store the fetched data in state
                    // Process the data
                })
                .catch(error => {
                    // Handle errors
                });
        }
        const handleOutsideClick = (event) => {
            if (profileImageRef.current && !profileImageRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);
        return () => document.removeEventListener("click", handleOutsideClick);
    }, [setProfileData]);

    return (
        <>
            <nav className="customer-navbar navbar navbar-expand-lg navbar-light bg-light mob-nav ">
                <div className="container-fluid d-flex justify-content-between align-items-center">
                <Protected setToken={setToken} />

                <NavLink className="navbar-brand logo" to="/customer/dashboard"><img src={logo} alt="Logo" /></NavLink>  
                    <div className="d-flex">                 
                    <div className="d-s">
                        <img
                            src={profileData?.ProfilePictureUrl == null ? user : profileData.ProfilePictureUrl}
                            alt="user"
                            className="profile-image mr-5"
                        />
                    </div>
                    <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        ><span className="navbar-toggler-icon"></span>
                    </button>                  
</div>
                    <div className="collapse navbar-collapse mob-nav" id="navbarSupportedContent">
                        <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/customer/dashboard" activeClassName="active">
                                    Dashboard
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/customer/events" activeClassName="active">
                                    Events
                                </NavLink>
                            </li>
                            <li className="nav-item d-s">
                                <NavLink className="nav-link" onClick={toggleModal}>
                                    My Profile
                                </NavLink>
                            </li>
                            <li className="nav-item d-s">
                                <NavLink className="nav-link" onClick={handleLogout}>
                                    Logout
                                </NavLink>
                            </li>
                        </ul>
                        <h4 className="head mr-20"><span className="white">Welcome Donor, <span className="green">{userDetails?.FirstName}</span></span></h4>
                        <div className="dropdown profile-div d-h">
                            <img
                                src={profileData?.ProfilePictureUrl == null ? user : profileData.ProfilePictureUrl}
                                alt="user"
                                id="dropdownMenuButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                className={`dropdown-toggle profile-image ${showDropdown ? 'active' : ''}`}
                                onClick={toggleDropdown}
                                ref={profileImageRef}
                            />
                            <ul aria-labelledby="dropdownMenuButton" className={`dropdown-menu dropdown-menu-right ${showDropdown ? 'show' : ''}`}>
                                <button className="dropdown-item" onClick={toggleModal}>My Profile</button>
                                <NavLink className="white" to="/login" style={{ textDecoration: "none" }}><button className="dropdown-item" onClick={handleLogout}>
                                    Logout
                                </button></NavLink>
                            </ul>
                        </div>
                    </div>
                 
                </div>
            </nav>
            
            <div
                className={`pm modal fade ${showModal ? 'show d-block' : ''}`}
                tabIndex="-1"
                role="dialog"
                style={{ animation: showModal ? 'slideInRight 0.3s forwards' : '' }}
            >
                <div className="modal-dialog modal-dialog-slideout-right" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="text-right">
                                <button type="button" className="profile-close close" onClick={toggleModal}>
                                    <span>&times;</span>
                                </button>
                            </div>

                            <form className="mb-30">
                                <div className="profile-main mb-20">
                                    <div className="mr-10 p-relative">
                                        <img style={{ "width": "200px" }}
                                            src={profileData?.ProfilePictureUrl == null ? user : profileData.ProfilePictureUrl}
                                            alt="user"
                                            className="profile-update"
                                        />
                                        <div className="p-absolute edit-profile" onClick={handleEditProfileClick}>
                                            <img src={editprofile} alt="Edit Profile" />
                                        </div>
                                    </div>
                                    <div>
                                        <h4 className="head green mb-0">{profileData.FirstName} {profileData.LastName}</h4>
                                        <p className="mb-0">Donor</p>
                                    </div>
                                </div>
                                <div className="d-grid">
                                    <label>Phone Number</label>
                                    <input
                                        value={profileData.PhoneNumber}
                                        type="number"
                                        className="form-control"
                                        id="PhoneNumber"
                                        name="PhoneNumber"
                                        onChange={e => setProfileData(prevData => ({ ...prevData, PhoneNumber: e.target.value }))}
                                    />
                                </div>
                                <div className="d-grid">
                                    <label>Email</label>
                                    <input
                                        value={profileData.EmailAddress}
                                        type="email"
                                        className="form-control"
                                        id="emailAddress"
                                        name="emailAddress"
                                        onChange={e => setProfileData(prevData => ({ ...prevData, EmailAddress: e.target.value }))}
                                    />
                                </div>
                                <div className="d-grid">
                                    <label>Address</label>
                                    <input
                                        value={profileData.HomeAddress}
                                        type="text"
                                        className="form-control"
                                        id="homeAddress"
                                        name="homeAddress"
                                        onChange={e => setProfileData(prevData => ({ ...prevData, HomeAddress: e.target.value }))}
                                    />
                                </div>
                                <div className="d-grid">
                                    <label>City</label>
                                    <input
                                        value={profileData.City}
                                        type="text"
                                        className="form-control"
                                        id="city"
                                        name="city"
                                        onChange={e => setProfileData(prevData => ({ ...prevData, City: e.target.value }))}
                                    />
                                </div>
                                <div className="d-grid">
                                    <label>State</label>
                                    <input
                                        value={profileData.State}
                                        type="text"
                                        className="form-control"
                                        id="state"
                                        name="state"
                                        onChange={e => setProfileData(prevData => ({ ...prevData, State: e.target.value }))}
                                    />
                                </div>
                                <div className="d-grid">
                                    <label>Country</label>
                                    <input
                                        value={profileData.Country}
                                        type="text"
                                        className="form-control"
                                        id="country"
                                        name="country"
                                        onChange={e => setProfileData(prevData => ({ ...prevData, Country: e.target.value }))}
                                    />
                                </div>
                                <div className="d-grid">
                                    <label>Pincode</label>
                                    <input
                                        value={profileData.PostalCode}
                                        type="text"
                                        className="form-control"
                                        id="postalCode"
                                        name="postalCode"
                                        onChange={e => setProfileData(prevData => ({ ...prevData, PostalCode: e.target.value }))}
                                    />
                                </div>
                            </form>
                            <div className="text-right">
                                <button type="button" className="btn btn-primary mr-5" onClick={handleUpdate}>
                                    Update
                                </button>
                                <button type="button" className="btn btn-outline" style={{ borderTopLeftRadius: "25px" }} onClick={toggleModal}>
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade ${showEditModal ? 'show d-block' : ''}`} style={{ background: '#0000006e', backdropFilter: 'none' }}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 class="head">Update Profile Image</h4>
                        </div>
                        <div className="modal-body">
                            {/* File Upload */}
                            <div className="form-group">
                                <label>Upload Image:</label>
                                <input type="file" onChange={handleFileChange} accept=".jpg, .png" />
                                {errorMessage && <p className="text-danger">{errorMessage}</p>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={handleUpdateTwo}>
                                Update
                            </button>
                            <button type="button" className="btn btn-outline" onClick={() => setShowEditModal(false)}>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CustomerNavbar;