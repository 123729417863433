import React from 'react';
import Navbar from '../components/Navbar';
import volunteerbg from '../images/volunteer.png';
import EnrollForm from '../components/enrollForm.js'
import Footer from '../components/Footer';


export default function Volunteer() {
    return (
        <>
            <Navbar />
            <div className="events" >
                <div className="res-container">
                    <div className="row">

                        <div className="col-4 vol-3">
                            <div className="card-mid">
                                <div className="d-grid text-left" style={{ position: 'relative', height: '-webkit-fill-available' }}>
                                    <div className="mb-30">
                                        <h4 className="mb-20"><b>Join Us in Making a Difference:</b></h4>
                                        <p style={{ textAlign: 'justify' }}> Welcome to our NGO's vibrant community of change-makers! We believe in the transformative power of individuals to create positive impacts, and we invite you to join our mission by becoming a valued volunteer. As a volunteer you embark on a meaningful journey to contribute your few hours a week or a dedicated block of time, skills and passion across various sectors including environmental conservation, social welfare, village development, tribal empowerment, farmer support and more leaving an enduring mark on our society.</p>

                                        <h5><b>Why Volunteer With Us?</b></h5>
                                        <p style={{ textAlign: 'justify' }}>Our NGO registered volunteers will have the chance to participate in impactful projects enjoying the host of benefits
                                            Personal Growth: Develop new skills, gain hands-on experience, and broaden your perspective.
                                            Community Engagement: Connect with like-minded individuals who share your passion for positive change.
                                            Impact: Witness the direct impact of your efforts on communities, the environment, and various social causes.
                                            Recognition: Receive certificates and acknowledgments for your valuable contributions.</p>

                                        <h5 style={{ textAlign: 'justify' }}><b>Get Started Today: Register as a Volunteer for Positive Change. Together, let's make a lasting impact! </b></h5>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-4 text-center vol-2">
                            <div className="card-mid" style={{ background: 'transparent', display: 'flex', alignContent: 'center', flexWrap: 'wrap' }}>
                                <img className="voluntter-img" src={volunteerbg} alt="volunteerbg" />
                            </div>
                        </div>

                        <div className="col-4 card-mid vol-1" style={{ background: '#ffffff', display: 'flex', alignContent: 'center', flexWrap: 'wrap', height: 'auto' }}>
                            <div className="card-mid" style={{ display: 'flex', alignContent: 'center', flexWrap: 'wrap', height: 'auto' }}>
                                <div className="d-grid text-center">
                                    <span className="tagline">Volunteer</span>
                                    <h1 className="mb-20">Want to become a regular ?</h1>
                                    <EnrollForm className="col-6" showVolunteer={true} showMessage={false} showBloodGroup={true} showSubject={false} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
            <Footer />
        </>
    )
}