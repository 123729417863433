import React, { useState } from 'react';
import kids from '../images/kids.png';
import { NavLink } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import axios from 'axios';

const API_URL = 'https://manamvanam.com/api/ForgotPassword';

export default function ForgetPassword() {
     const [email, setEmail] = useState('');
     const [errorMessage, setErrorMessage] = useState('');
     const [showAfterFP, setShowAfterFP] = useState(false);

     const validateEmail = (input) => {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return emailRegex.test(input);
     };

     const handleInputChange = (e) => {
          const inputValue = e.target.value;
          setEmail(inputValue);

          if (validateEmail(inputValue)) {
              setErrorMessage(''); // Clear the error message if email is valid

          } else {
               setErrorMessage('Please enter a valid email address');
          }
     };

     const handleSubmit = () => {
          if (validateEmail(email)) {
               // Email is valid, proceed with further action
               //setShowAfterFP(true); // Show the after-fp div
              console.log('Email is valid:', email);
              axios.post(`${API_URL}`, {
                  "Username": email
              }).then(response => {
                  console.log(response);
                  if (response.data.Succeeded)
                      setShowAfterFP(true); // Show the after-fp div
                  else
                      setErrorMessage(response.data.Errors[0]);
              });
          } else {
               setErrorMessage('Please enter a valid email address');
          }
     };

     return (
          <>
               <Navbar />
               <div className="login">
                    <div className="row no-gutters w-100">
                         <div className="bg-left col-lg-6">
                              <div className="background-image" >
                                   <h3 className="centered-h2">"It's not how much you give, but how much love we put into giving"</h3>
                                   <p className="centered-p"><b>- Mother Teresa</b></p>
                                   <img className="bottom-image" src={kids} alt="Kids" />
                              </div>
                         </div>
                         <div className="bg-right col-lg-6">
                              <div className="login-form d-grid text-center">
                                   <span className="tagline">Forgot Password</span>
                                   <div className={!showAfterFP ? 'before-fp' : 'after-fp'}>
                                        {!showAfterFP ? (
                                             <>
                                                  <h1 className="mb-40">Enter your email address</h1>
                                                  <form className="mb-40">
                                                       <div className="form-group text-left">
                                                            <input
                                                                 value={email}
                                                                 type="email"
                                                                 className="form-control mb-0"
                                                                 placeholder="Email"
                                                                 id="email"
                                                                 name="email"
                                                                 autoComplete="off"
                                                                 onChange={handleInputChange}
                                                            />
                                                            {errorMessage && <p className="error-message red">{errorMessage}</p>}
                                                       </div>
                                                       <div className="d-flex jc-sb">
                                                            <NavLink
                                                                 to="/login"
                                                                 style={{ textDecoration: "underline", color: 'var(--green)', padding: '10px 0' }}
                                                            >
                                                                 Back
                                                            </NavLink>
                                                            <button className="btn btn-primary" type="button" onClick={handleSubmit}>
                                                                 Submit
                                                            </button>
                                                       </div>
                                                  </form>
                                             </>
                                        ) : (
                                             <>
                                                  <h1>A link has been sent to your email address</h1>
                                                  <p className="mb-40">Click on the link to reset your password</p>
                                                  <div className="d-flex jc-c">
                                                       <NavLink
                                                            to="/login"
                                                            style={{ textDecoration: "underline" }}
                                                       >
                                                            <button className="btn btn-primary" type="button">
                                                                 Return
                                                            </button>
                                                       </NavLink>
                                                  </div>
                                             </>
                                        )}
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
               <Footer />
          </>
     );
}