import React, { useEffect, useState } from 'react';
import view from '../../images/view.png';
import CustomerNavbar from '../components/CustomerNavbar';
import { Link } from 'react-router-dom';
import Donation from '../../components/Donation';
import eventData from '../../components/events.json';
import jsonData from '../../components/john.json';
import axios from 'axios';
import Moment from 'react-moment';
import 'moment-timezone';

import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';

const EVENT_API_URL = 'https://manamvanam.com/api/events';



const formatDate = (dateString) => {
    const date = new Date(dateString);
    const monthNames = [
        'Jan', 'Feb', 'March', 'April', 'May', 'June', 'July',
        'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
    ];
    const month = monthNames[date.getMonth()];
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return { month, day, year };
};

export default function CustomerDashboard({ setToken}) {

    const { name, donations } = jsonData;
    const [next4Events, setData] = useState([]);

    useEffect(() => {
        // Perform AJAX call using Axios here
        axios.get(`${EVENT_API_URL}`)
            .then(response => {
                console.log(response);
                const fetchedData = response.data;
                // // Store the fetched data in state
                const upcomingEvents = fetchedData.filter(event => new Date(event.EventStartDate) >= new Date());
                console.log(upcomingEvents);
                // Sort the upcoming events by eventDate in ascending order
                upcomingEvents.sort((a, b) => new Date(a.EventStartDate) - new Date(b.EventStartDate));

                // Take the first 4 upcoming events
                setData(upcomingEvents.slice(0, 4));
                // Process the data
            })
            .catch(error => {
                // Handle errors
            });
    }, []); // The empty array means this effect will run only once (on mount)


    const labels = donations.map(donation => donation.date);
    const datasets = [
        'RythuBandham', 'GirijanaBandham', 'GramaBandham', 'JanahitaBandham', 'ParyavaranamBandham'
    ];


    const data = {
        labels: labels,
        datasets: datasets.map((dataset, index) => ({
            label: dataset,
            data: donations.map(donation => donation[dataset]),
            backgroundColor: [
                '#544bfc',
                '#5793f3',
                '#de453f',
                '#f09430',
                '#b24bfc',
            ][index],
            hoverBackgroundColor: [
                '#544bfc',
                '#5793f3',
                '#de453f',
                '#f09430',
                '#b24bfc',
            ][index],
            barThickness: 10,
        })),
    };


    const options = {
        scales: {
            y: {
                beginAtZero: true,
                suggestedMin: 0,      // Set the minimum value of the y-axis
                suggestedMax: 150,    // Set the maximum value of the y-axis
                ticks: {
                    stepSize: 50,        // Set the step size to 50
                },
            },
        },
        plugins: {
            datalabels: {
                display: true,
                color: 'white',
                font: {
                    weight: 'bold',
                },
                formatter: (value) => (value >= 1000 ? `${value / 1000}K` : value),
            },
            legend: {
                display: true,
            },
        },
        elements: {
            bar: {
                borderRadius: {
                    topLeft: 25,
                    topRight: 25,
                },
                borderSkipped: 'bottom', // Skip the bottom border to achieve rounded top corners
            },
        },
    };


    return (
        <>
            <CustomerNavbar setToken={setToken} />
            <div className="events">
                <div className="res-container">
                    <div className="row sm-pt-10">
                        <div className="col-8">
                            <div className="card p-0 mb-20">
                                <div className="row">
                                    <div className="col-md-12 p-0">
                                        <div className="card common-card">
                                            <div className="card-header d-flex jc-sb pb-0">
                                                <h3 className="head m-0">Donation Graph</h3>
                                            </div>
                                            <div className="card-body row" style={{ paddingTop: '15px', paddingBottom: '0px' }}>
                                                {data?.datasets && (
                                                    <Bar data={data} options={options} height={125} />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-4">
                            <Donation showPersonalDetails={false} showServiceSelection={false} showLogin={false} showAllEvents={true} /> {/* Pass the prop to hide personal details and service selection */}
                        </div>
                        <div className="col-12">
                            <div className="card p-0 mb-20">
                                <div className="row">
                                    <div className="col-md-12 p-0">
                                        <div className="card common-card">
                                            <div className="card-header d-flex jc-sb">
                                                <h3 className="head m-0">Upcoming Events</h3>
                                                <Link to="/customer/events" className="btn btn-outline">
                                                    View Events
                                                </Link>
                                            </div>
                                            <div className="card-body row" style={{ paddingTop: '15px', paddingBottom: '0px' }}>
                                                {/* Display the next 4 upcoming events */}
                                                {next4Events.map(event => (
                                                    <div className="col-3" key={event.Id}>
                                                        <div className={`cust-e card mb-20 ${event.EventStatus === 'Completed' ? 'completed-event' : ''}`}>
                                                            {event.EventStatus === 'Completed' && (
                                                                <div className="cust-ribbon ribbon-top-left"><span>Completed</span></div>
                                                            )}
                                                            <div className="row">
                                                                <div className="small-blog-img col-md-3 p-0 p-relative">
                                                                    <div className="event-banner" style={{ backgroundImage: `url(${event.EventBanner})` }}>
                                                                        <span className="head">{formatDate(event.EventStartDate).month}</span>
                                                                        <h1 className="head">{formatDate(event.EventStartDate).day}</h1>
                                                                        <span className="head">{formatDate(event.EventStartDate).year}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-9 p-20">
                                                                    <Link to={`/customer/events/${event.Id}`} style={{ textDecoration: 'none', color: 'var(--black)' }}>
                                                                        <h4 className="card-title" >{event.EventName}<img src={view} alt="View" style={{ padding: '0 0 5px 5px' }} className="filter-green" />  </h4>

                                                                    </Link>
                                                                    <p className="mb-10">{event.EventMotto}</p>
                                                                    <div className="event-footer d-flex jc-sa">
                                                                        <Link to={`/customer/events/${event.Id}`}>
                                                                            <img src={view} alt="View" />
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

