import React, { useState } from 'react';
import kids from '../images/kids.png';
import logo from '../images/logo.png';
import { NavLink, useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import axios from 'axios';

const API_URL = 'https://manamvanam.com/api/ResetPassword';
export default function ResetPassword() {
    const { passwordToken } = useParams(); 
     const [password, setPassword] = useState('');
     const [confirmPassword, setConfirmPassword] = useState('');
     const [errors, setErrors] = useState({
          password: '',
          confirmPassword: '',
     });
     const [showAfterFP, setShowAfterFP] = useState(false);

     const validatePassword = () => {
          let valid = true;
          const newErrors = {
               password: '',
               confirmPassword: '',
          };

          if (!password) {
               newErrors.password = 'Password is required';
               valid = false;
          } else if (password.length < 6) {
               newErrors.password = 'Password should contain minimum 6 characters';
               valid = false;
          }

          if (!confirmPassword) {
               newErrors.confirmPassword = 'Confirm Password is required';
               valid = false;
          } else if (confirmPassword !== password) {
               newErrors.confirmPassword = 'Confirm Password does not match';
               valid = false;
          }

          setErrors({ ...newErrors });
          return valid;
     };

     const handleChange = (e) => {
          const { name, value } = e.target;
          if (name === 'password') {
               setPassword(value);
               setErrors((prevErrors) => ({
                    ...prevErrors,
                    password: '',
               }));
          } else if (name === 'confirmPassword') {
               setConfirmPassword(value);
               setErrors((prevErrors) => ({
                    ...prevErrors,
                    confirmPassword: '',
               }));
          }
     };

     const handleSubmit = () => {
          if (validatePassword()) {
              axios.post(`${API_URL}`, {
                  "PasswordToken": passwordToken,
                  "Password": password,
                  "ConfirmPassword": confirmPassword
              }).then(response => {
                  console.log(response);
                  if (response.data.Succeeded)
                      setShowAfterFP(true);
                  else {
                      const newErrors = {
                          password: '',
                          confirmPassword: '',
                      };

                      newErrors.password = response.data.Errors[0];
                      setErrors({ ...newErrors });
                  }
              });

          }
     };

     return (
          <>
               <section className="header">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                         <div className="container-fluid">
                              <NavLink className="navbar-brand logo" to="/">
                                   <img src={logo} alt="Logo" />
                              </NavLink>
                         </div>
                    </nav>
               </section>
               <div className="login">
                    <div className="row no-gutters w-100">
                         <div className="bg-left col-lg-6">
                              <div className="background-image" >
                                   <h3 className="centered-h2">"It's not how much you give, but how much love we put into giving"</h3>
                                   <p className="centered-p"><b>- Mother Teresa</b></p>
                                   <img className="bottom-image" src={kids} alt="Kids" />
                              </div>
                         </div>
                         <div className="bg-right col-lg-6">
                              <div className="login-form d-grid text-center">
                                   <span className="tagline">Reset Password</span>
                                   <div className={!showAfterFP ? 'before-fp' : 'after-fp'}>
                                        {!showAfterFP ? (
                                             <>
                                                  <h1 className="mb-40">Create new password</h1>
                                                  <form className="mb-40">
                                                       <div className="form-group text-left">
                                                            <input
                                                                 value={password}
                                                                 type="password"
                                                                 className="form-control mb-0"
                                                                 placeholder="New Password"
                                                                 id="password"
                                                                 name="password"
                                                                 autoComplete="off"
                                                                 onChange={handleChange}
                                                            />
                                                            {errors.password && <p className="error-message red">{errors.password}</p>}
                                                       </div>
                                                       <div className="form-group text-left">
                                                            <input
                                                                 value={confirmPassword}
                                                                 type="password"
                                                                 className="form-control mb-0"
                                                                 placeholder="Confirm Password"
                                                                 id="confirmPassword"
                                                                 name="confirmPassword"
                                                                 autoComplete="off"
                                                                 onChange={handleChange}
                                                            />
                                                            {errors.confirmPassword && <p className="error-message red">{errors.confirmPassword}</p>}
                                                       </div>
                                                       <div className="d-flex jc-c">
                                                            <button className="btn btn-primary" type="button" onClick={handleSubmit}>
                                                                 Reset
                                                            </button>
                                                       </div>
                                                  </form>
                                             </>
                                        ) : (
                                             <>
                                                  <h1>Your password has been reset successfully</h1>
                                                  <p className="mb-40">Please login to your account</p>
                                                  <div className="d-flex jc-c">
                                                       <NavLink to="/login" style={{ textDecoration: "underline" }}>
                                                            <button className="btn btn-primary" type="button">
                                                                 Login
                                                            </button>
                                                       </NavLink>
                                                  </div>
                                             </>
                                        )}
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
               <Footer />
          </>
     );
}