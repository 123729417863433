import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

const VideoModal = ({ showModal, selectedVideo, closeModal }) => {
     const videoRef = useRef(null);

     useEffect(() => {
          const videoElement = videoRef.current;

          if (videoElement) {
               // Remove the download attribute from the video element
               videoElement.removeAttribute('controlsList');
          }
     }, [selectedVideo]);

     useEffect(() => {
          const videoElement = videoRef.current;

          if (showModal && videoElement) {
               // Auto-play the video when the modal is opened
               videoElement.play();
          } else if (!showModal && videoElement) {
               // Pause the video when the modal is closed
               videoElement.pause();
          }
     }, [showModal]);

     return (
          <>
               {showModal && selectedVideo && (
                    <div className="modal image-modal video-modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                         <div className="modal-dialog" role="document">
                              <div className="modal-content">
                                   <div className="modal-body">
                                        <div className="row">
                                             <div className="col-12 p-0">
                                                  <video
                                                       ref={videoRef}
                                                       src={selectedVideo.path}
                                                       alt={selectedVideo.headline}
                                                       className="video-fluid"
                                                       controls
                                                       autoPlay
                                                  >
                                                       Your browser does not support the video tag.
                                                  </video>
                                             </div>
                                        </div>
                                        <div className="row">
                                             <div className="col-12 p-0">
                                                  <div style={{ zIndex: '1', position: 'absolute', top: '0', right: '0' }}>
                                                       <button type="button" className="close" onClick={closeModal}>
                                                            <span aria-hidden="true">×</span>
                                                       </button>
                                                  </div>
                                                  <div className="video-outer">
                                                       <video controls height="100vh">
                                                            <source src={selectedVideo.path} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                       </video>
                                                  </div>
                                             </div>
                                             {/* <div className="col-3 p-30">
                                                  
                                                  <p className="green">Category: {selectedVideo.eventCategory}</p>
                                                  <h3 className="head">{selectedVideo.headline}</h3>
                                                  <p>Event Name: {selectedVideo.eventName}</p>
                                                  <Link
                                                       to={`/events/${selectedVideo.id}`} // Update the URL with the correct event ID
                                                       className="btn btn-primary"
                                                  >
                                                       View Event
                                                  </Link>
                                             </div> */}
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               )}

               {showModal && <div className="modal-backdrop fade show"></div>}
          </>
     );
};

export default VideoModal;
