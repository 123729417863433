import React, { useState, useEffect } from 'react';
import logo from '../images/logo.png';
import search from '../images/search.png';
import { NavLink, useLocation } from 'react-router-dom'; // Update the import statement
import { useTabContext } from '../components/TabContext';
import axios from 'axios';

const SERVICE_API_URL = 'https://manamvanam.com/api/ServiceData';
export default function Navbar() {
     const { activeTab, handleTabSelect } = useTabContext();
     const location = useLocation(); // Use useLocation here
    const [servicesData, setServiceData] = useState([]);

    useEffect(() => {
        axios.get(`${SERVICE_API_URL}`)
            .then(response => {
                const fetchedServicesData = response.data;
                setServiceData(fetchedServicesData);
                sessionStorage.setItem("manamvanam_services", JSON.stringify(fetchedServicesData));
            })
            .catch(error => {
                // Handle errors
            });
    }, []);
     const handleHomeClick = () => {
          handleTabSelect('home');
     };

     return (
          <section className="header">
               <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <div className="container-fluid">
                         <NavLink className="navbar-brand logo" to="/" onClick={handleHomeClick}>
                              <img src={logo} alt="Logo" />
                         </NavLink>
                         <div className="d-flex jc-sb">
                              <NavLink to="/guest-login" style={{ textDecoration: "none" }} className="donate-btn mr-5">
                                   <button className="btn btn-primary">Donate</button>
                              </NavLink>
                              <button
                                   className="navbar-toggler"
                                   type="button"
                                   data-bs-toggle="collapse"
                                   data-bs-target="#navbarNav"
                                   aria-controls="navbarNav"
                                   aria-expanded="false"
                                   aria-label="Toggle navigation"
                              >
                                   <span className="navbar-toggler-icon"></span>
                              </button>
                         </div>
                         <div className="collapse navbar-collapse" id="navbarNav">
                              <ul className="navbar-nav mx-auto">
                                   <li className="nav-item">
                                        <NavLink className={`nav-link ${location.pathname === '/' ? 'active' : ''}`} to="/" onClick={handleHomeClick}>
                                             Home
                                        </NavLink>
                                   </li>
                                   <li className="nav-item">
                                        <NavLink className={`nav-link ${location.pathname === '/aboutus' ? 'active' : ''}`} to="/aboutus">
                                             About Us
                                        </NavLink>
                                   </li>
                                   <li className="nav-item dropdown">
                                        <a
                                             className={`nav-link dropdown-toggle ${location.pathname.startsWith('/rythubandham') ||
                                                  location.pathname.startsWith('/girijanabandham') ||
                                                  location.pathname.startsWith('/gramabandham') ||
                                                  location.pathname.startsWith('/janahitabandham') ||
                                                  location.pathname.startsWith('/paryavaranambandham')
                                                  ? 'active'
                                                  : ''
                                                  }`}
                                             href="#"
                                             id="navbarDropdown"
                                             role="button"
                                             data-bs-toggle="dropdown"
                                             aria-expanded="false"
                                        >
                                             Services
                                        </a>
                                        <ul className="dropdown-menu p-0" aria-labelledby="navbarDropdown">
                                            {/*<li>*/}
                                            {/*<NavLink className="dropdown-item" to="/rythubandham_component">*/}
                                            {/*Rythu Bandham*/}
                                            {/*</NavLink>*/}
                                            {/*</li>*/}
                                            {/*<li>*/}
                                            {/*<NavLink className="dropdown-item" to="/girijanabandham_component">*/}
                                            {/*Girijana Bandham*/}
                                            {/*</NavLink>*/}
                                            {/*</li>*/}
                                            {/*<li>*/}
                                            {/*<NavLink className="dropdown-item" to="/gramabandham_component">*/}
                                            {/*Grama Bandham*/}
                                            {/*</NavLink>*/}
                                            {/*</li>*/}
                                            {/*<li>*/}
                                            {/*<NavLink className="dropdown-item" to="/janahitabandham_component">*/}
                                            {/*Janahita Bandham*/}
                                            {/*</NavLink>*/}
                                            {/*</li>*/}
                                            {/*<li>*/}
                                            {/*<NavLink className="dropdown-item" to="/paryavaranambandham_component">*/}
                                            {/*Paryavaranam Bandham*/}
                                            {/*</NavLink>*/}
                                            {/*</li>*/}
                                     {servicesData.map((service, index) => (
                                         <li>
                                             <NavLink className="dropdown-item" to={"/" + service.category.toString().toLowerCase().replace(/ /g, '_') }>
                                                    {service.service_title}
                                                  </NavLink>
                                     </li>
                                     ))}
                                             {/* Add more dropdown options as needed */}
                                        </ul>
                                   </li>
                                   <li className="nav-item">
                                        <NavLink className={`nav-link ${location.pathname === '/events' ? 'active' : ''}`} to="/events">
                                             Events
                                        </NavLink>
                                   </li>
                                   <li className="nav-item">
                                        <NavLink className={`nav-link ${location.pathname === '/gallery' ? 'active' : ''}`} to="/gallery">
                                             Gallery
                                        </NavLink>
                                   </li>
                                   {/* <li className="nav-item">
                                        <NavLink className={`nav-link ${location.pathname === '/login' ? 'active' : ''}`} to="/login">
                                             Login
                                        </NavLink>
                                   </li> */}
                                   <li className="nav-item">
                                        <NavLink className={`nav-link ${location.pathname === '/contactus' ? 'active' : ''}`} to="/contactus">
                                             Contact Us
                                        </NavLink>
                                   </li>
                                   <li className="nav-item">
                                        <NavLink className={`nav-link ${location.pathname === '/volunteer' ? 'active' : ''}`} to="/volunteer">
                                             Volunteer
                                        </NavLink>
                                   </li>
                                   {/* <li className="nav-item">
                                        <NavLink className={`nav-link ${location.pathname === '/search' ? 'active' : ''}`} to="/search">
                                             <img src={search} alt="Search" />
                                        </NavLink>
                                   </li> */}
                                   {/* Add more navlinks here with appropriate paths */}
                              </ul>
                              <div className="d-flex justify-content-end">
                                   <NavLink to="/login" style={{ textDecoration: "none" }} className="mr-10">
                                        <button className="btn btn-outline">Login</button>
                                   </NavLink>
                                   <NavLink to="/guest-login" style={{ textDecoration: "none" }}>
                                        <button className="btn btn-primary">Donate</button>
                                   </NavLink>
                              </div>
                         </div>

                    </div>
               </nav>
          </section>
     );
}
