import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Labelicon from '../images/label.png';

const ImageModal = ({ showModal, selectedImage, closeModal }) => {
    const imgRef = useRef(null);

    useEffect(() => {
        const imgElement = imgRef.current;

        if (imgElement) {
            const width = imgElement.width;
            const height = imgElement.height;

            if (width > height) {
                imgElement.classList.add('img-width');
                imgElement.classList.remove('img-height');
            } else {
                imgElement.classList.add('img-height');
                imgElement.classList.remove('img-width');
            }
        }
    }, [selectedImage]);

    return (
        <>
            {showModal && selectedImage && (
                <div className="modal image-modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="row">
                                    <button type="button" className="close" onClick={closeModal}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <div className="left-con col-10 p-0">
                                        <img
                                            ref={imgRef}
                                            src={selectedImage.Path}
                                            alt={selectedImage.HeadLine}
                                            className="img-fluid check-img"
                                        />
                                    </div>
                                    <div className="right-con col-2 p-30">

                                        <p className="green"><img src={Labelicon} className="label-icon mr-5" />Category : {selectedImage.EventCategory}</p>
                                        <h3 className="head">{selectedImage.HeadLine}</h3>
                                        <p>Event Name : {selectedImage.EventName}</p>

                                        <Link
                                            to={`/events/${selectedImage.Id}`} // Update the URL with the correct event ID
                                            className="btn btn-primary"
                                        >
                                            View Event
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showModal && <div className="modal-backdrop fade show"></div>}
        </>
    );
};

export default ImageModal;