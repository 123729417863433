import React, { useState, useEffect } from 'react';
import AdminNavbar from '../components/adminNavbar';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Moment from 'react-moment';

import 'moment-timezone';

const API_URL = 'https://manamvanam.com/api/Events';
const SERVICES_API_URL = 'https://manamvanam.com/api/ServiceDetails';
export default function AdminAddEvents() {
    const token = localStorage.getItem('token');
    const [selectedFile, setSelectedFile] = useState(null);
    const [servicesData, setServicesData] = useState([]);
    const navigate = useNavigate();
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        },
    };
    const [eventData, setEventData] = useState({
        categoryId: '',
        eventTime: '',
        eventDate: '',
        phoneNumber: '',
        email: '',
        address: '',
        city: '',
        eventDescription: '',
        eventBanner: '', // You can handle file uploads separately
    });
    useEffect(() => {
        getServicesData();
    }, []); // The empty array means this effect will run only once (on mount)

    const getServicesData = () => {
        axios.get(`${SERVICES_API_URL}`, config)
            .then(response => {
                console.log(response);
                const fetchedData = response.data;
                setServicesData(fetchedData); // Store the fetched data in state
            })
            .catch(error => {
                // Handle errors
            });
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setEventData({ ...eventData, [name]: value });
        if (e.target.files !== undefined && e.target.files !== null
            && e.target.files.length > 0) {
            setSelectedFile(e.target.files[0]);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Handle form submission, e.g., send data to the server
        console.log(eventData);
        //const eventDate = <Moment parse="YYYY-MM-DD">{eventData.eventDate}</Moment>;
        //console.log(new Moment(eventData.eventDate, "YYYY-MM-DD"));
        //console.log(eventDate);
        const submitEventData = new FormData();
        //submitEventData.append('MasterServiceId', 1);
        submitEventData.append('CategoryId', eventData.categoryId);
        submitEventData.append('EventTitle', eventData.eventDisplayName);
        submitEventData.append('EventDescription', eventData.eventDescription);
        submitEventData.append('EventMotto', eventData.eventDisplayName);
        submitEventData.append('EventImageLink', "");
        submitEventData.append('EventBanner', eventData.eventBanner);
        submitEventData.append('EventStatus', "Upcoming");

        submitEventData.append('EventStartDate', eventData.eventDate);
        submitEventData.append('EventEndDate', eventData.eventDate);
        submitEventData.append('EventLocation', eventData.address);
        submitEventData.append('EventCity', eventData.city);
        submitEventData.append('EventFacebookLink', "");
        submitEventData.append('EventLinkedInLink', "");
        submitEventData.append('EventContactMail', eventData.email);
        submitEventData.append('EventContactPhone', eventData.phoneNumber);
        submitEventData.append('RevenueTarget', 1000);
        submitEventData.append('PublishDate', new Date());
        submitEventData.append('PublishedBy', "Admin");
        submitEventData.append('IsPublished', false);
        //if (selectedFile) {
        //    const reader = new FileReader();
        //    reader.onload = (e) => {
        //        const fileData = e.target.result; // This is an array buffer
        //        // Convert the array buffer to a Uint8Array if needed
        //        const uint8Array = new Uint8Array(fileData);
        //        submitEventData.append('EventMediaFileData', uint8Array);
        //        // Send the uint8Array or fileData to your API
        //        // Example: sendFileDataToApi(uint8Array);
        //    };
        //    reader.readAsArrayBuffer(selectedFile);
        //}
        submitEventData.append('eventMediaFileData', selectedFile);
        //EventLocations
        submitEventData.append('eventLocations', JSON.stringify( additionalLocations));
        console.log(additionalLocations);
        axios.post(`${API_URL}`, submitEventData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            console.log(response);
            if (response != null && response !== undefined && response.status === 200) {
                if (response.data > 0)
                    navigate('/admin/events');
            }
        })
            .catch(error => {

            });
    };


    const [additionalLocations, setAdditionalLocations] = useState([]);

    const handleAddLocation = () => {
        setAdditionalLocations([...additionalLocations, { city: '', address: '' }]);
    };

    const handleRemoveLocation = (index) => {
        const updatedLocations = [...additionalLocations];
        updatedLocations.splice(index, 1);
        setAdditionalLocations(updatedLocations);
    };

    const handleLocationChange = (index, field, value) => {
        const updatedLocations = [...additionalLocations];
        updatedLocations[index][field] = value;
        setAdditionalLocations(updatedLocations);
    };

    return (
        <>
            <AdminNavbar />
            <div className="events">
                <div className="res-container sm-pt-10">
                    <div className="card p-0">
                        <div className="row">
                            <div className="col-md-12 p-0">
                                <div className="card common-card">
                                    <div className="card-header d-flex jc-sb">
                                        <h3 className="head m-0">Add Events</h3>
                                        <Link to="/admin/events" className="btn btn-outline">
                                            Back
                                        </Link>
                                    </div>
                                    <div className="card-body" style={{ overflowY: 'scroll', height: '75vh' }}>
                                        <form onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Select Service<span className="red"><strong> *</strong></span></label>
                                                        <select
                                                            className="form-control"
                                                            name="categoryId"
                                                            value={eventData.categoryId}
                                                            onChange={handleChange}
                                                            required
                                                        >
                                                            <option value="" disabled>Select Service</option>
                                                            {servicesData.map((service) => (
                                                                <option key={service.ServiceDetailId} value={service.ServiceDetailId}>
                                                                    {service.ServiceTitle}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-9">
                                                    <div className="form-group">
                                                        <label>Event Name<span className="red"><strong> *</strong></span></label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="eventDisplayName" // Change the name to "eventDisplayName"
                                                            value={eventData.eventDisplayName}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                {/* <div className="col-md-3">
                                                                 <div className="form-group">
                                                                      <label>Event Time<span className="red"><strong> *</strong></span></label>
                                                                      <input
                                                                           type="text"
                                                                           className="form-control"
                                                                           name="eventTime"
                                                                           value={eventData.eventTime}
                                                                           onChange={handleChange}
                                                                           required
                                                                      />
                                                                 </div>
                                                            </div> */}
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Event Date<span className="red"><strong> *</strong></span></label>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            name="eventDate"
                                                            value={eventData.eventDate}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Phone Number<span className="red"><strong> *</strong></span></label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="phoneNumber"
                                                            value={eventData.phoneNumber}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Email<span className="red"><strong> *</strong></span></label>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            name="email"
                                                            value={eventData.email}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Event Banner (Upload)<span className="red"><strong> *</strong></span></label>
                                                        <input
                                                            type="file"
                                                            className="form-control-file"
                                                            name="eventBanner"
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>City<span className="red"><strong> *</strong></span></label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="city"
                                                            value={eventData.city}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Address<span className="red"><strong> *</strong></span></label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="address"
                                                            value={eventData.address}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                {/* Button to add more locations */}
                                                <div className="col-md-3 text-right">
                                                    <button class="btn btn-outline mt-20" type="button" onClick={handleAddLocation}>
                                                        Add More
                                                    </button>
                                                </div>

                                                {/* Additional locations */}
                                                {additionalLocations.map((location, index) => (
                                                    <div key={index} className="row">
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>City<span className="red"><strong> *</strong></span></label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name={`additionalCity[${index}]`}
                                                                    value={location.city}
                                                                    onChange={(e) => handleLocationChange(index, 'city', e.target.value)}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>Address<span className="red"><strong> *</strong></span></label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name={`additionalAddress[${index}]`}
                                                                    value={location.address}
                                                                    onChange={(e) => handleLocationChange(index, 'address', e.target.value)}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 text-right">
                                                            <button className="btn btn-danger mt-20" type="button" onClick={() => handleRemoveLocation(index)}>
                                                                Remove
                                                            </button>
                                                        </div>
                                                    </div>
                                                ))}



                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Event Description<span className="red"><strong> *</strong></span></label>
                                                        <textarea style={{ height: '20vh' }}
                                                            className="form-control"
                                                            name="eventDescription"
                                                            value={eventData.eventDescription}
                                                            onChange={handleChange}
                                                            required
                                                        ></textarea>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 text-right">
                                                    <button type="submit" className="btn btn-primary">
                                                        Save
                                                    </button>
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
}