import React, { useState, useEffect } from 'react';
import AdminNavbar from '../components/adminNavbar';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';

export default function AdminServices() {
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        },
    };
    const [formData, setFormData] = useState({
        service: '',
        title: '',
        titleDescription: '',
        tagline: '',
        imageUpload: null,
        description: '',
        title0: '',
        titleDescription0: '',
        imageUpload0: null,
    });
    const [activeTab, setActiveTab] = useState('landingpage');
    const [categories, setCategories] = useState([]);
    const [errors, setErrors] = useState({
        service: '',
        title: '',
        titleDescription: '',
        tagline: '',
        imageUpload: '',
        description: '',
        title0: '',
        titleDescription0: '',
        imageUpload0: '',
    });

    useEffect(() => {
        Axios.get('https://manamvanam.com/api/MasterServices')
            .then((response) => {
                setCategories(response.data);
            })
            .catch((error) => {
                console.error('Error fetching categories: ', error);
            });
    }, []);

    const handleInputChange = (e) => {
        const { name, type, files } = e.target;

        if (name === 'imageUpload' || name === 'imageUpload0') {
            const file = files[0];
            const allowedFormats = ['image/jpeg', 'image/png'];
            const maxSize = 3*1024 * 1024; // 500KB

            if (file) {
                if (file.size > maxSize) {
                    setErrors((prevErrors) => ({ ...prevErrors, [name]: 'Image size should be less than 3MB' }));
                } else if (!allowedFormats.includes(file.type)) {
                    setErrors((prevErrors) => ({ ...prevErrors, [name]: 'Only JPG and PNG formats are allowed' }));
                } else {
                    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
                }
            }
        }

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: type === 'file' ? files[0] : e.target.value,
        }));

        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    };

    const handleLandingPageUpdate = () => {
        const newErrors = {};
        if (!formData.title0) newErrors.title0 = 'Please enter a title';
        if (!formData.titleDescription0) newErrors.titleDescription0 = 'Please enter a title description';
        if (!formData.imageUpload0) newErrors.imageUpload0 = 'Please upload an image';
        if (formData.imageUpload0 && formData.imageUpload0.size > 3*1024 * 1024) {
            newErrors.imageUpload0 = 'Image size should be less than 3MB';
        }

        setErrors(newErrors);

        // If there are no errors, you can proceed with the update logic for Landing Page tab
        const submitServicePageData = new FormData();
        submitServicePageData.append('service', 0);
        submitServicePageData.append('title', formData.title0);
        submitServicePageData.append('titleDescription', formData.titleDescription0);
        submitServicePageData.append('serviceMediaFileData', formData.imageUpload0);
        console.log(formData);
        Axios.post(`https://manamvanam.com/api/ServicePage`, submitServicePageData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            console.log(response);
            if (response != null && response !== undefined && response.status === 200) {
                alert("Home Content updated successfully");
                if (response.data > 0)
                    navigate('/admin/services');
            } else {
                setErrors(response.errors);
            }
        })
            .catch(error => {

            });
    };

    const handleServicePageUpdate = () => {
        const newErrors = {};
        if (!formData.service) newErrors.service = 'Please select a service';
        if (!formData.title) newErrors.title = 'Please enter a title';
        if (!formData.titleDescription) newErrors.titleDescription = 'Please enter a title description';
        if (!formData.tagline) newErrors.tagline = 'Please enter a tagline';
        if (!formData.imageUpload) newErrors.imageUpload = 'Please upload an image';
        if (formData.imageUpload && formData.imageUpload.size > 3 * 1024 * 1024) {
            newErrors.imageUpload = 'Image size should be less than 3MB';
        }
        if (!formData.description) newErrors.description = 'Please enter a description';

        setErrors(newErrors);
        // If there are no errors, you can proceed with the update logic for Service Page tab
        const submitServiceData = new FormData();
        submitServiceData.append('service', formData.service);
        submitServiceData.append('title', formData.title);
        submitServiceData.append('titleDescription', formData.titleDescription);
        submitServiceData.append('description', formData.description);
        submitServiceData.append('tagline', formData.tagline);
        submitServiceData.append('serviceMediaFileData', formData.imageUpload);
        console.log(formData);
        Axios.post(`https://manamvanam.com/api/ServiceDetails`, submitServiceData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            console.log(response);
            if (response != null && response !== undefined && response.status === 200) {
                alert("Service Content updated successfully");
                if (response.data > 0)
                    navigate('/admin/services');
            } else {
                setErrors(response.errors);
            }
        })
            .catch(error => {

            });

    };

    const handleTabChange = (tab) => setActiveTab(tab);

    return (
        <>
            <AdminNavbar />
            <div className="events">
                <div className="res-container sm-pt-10">
                    <div className="row">
                        <div className="col-3">
                            <div className="card p-0 mb-20" style={{ height: '80vh' }}>
                                <div className="row">
                                    <div className="col-md-12 p-0">
                                        <div className="card common-card">
                                            <div className="card-header d-flex jc-sb">
                                                <h3 className="head m-0">Add Service Content</h3>
                                            </div>
                                            <div className="card-body admin-service">
                                                <ul className="nav flex-column">
                                                    <li className="nav-item">
                                                        <a href
                                                            className={`nav-link ${activeTab === 'landingpage' ? 'active' : ''}`}
                                                            onClick={() => handleTabChange('landingpage')}
                                                        >
                                                            Home Page
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a href
                                                            className={`nav-link ${activeTab === 'servicepage' ? 'active' : ''}`}
                                                            onClick={() => handleTabChange('servicepage')}
                                                        >
                                                            Service Page
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-9">
                            <div className="tab-content">
                                <div className={`tab-pane ${activeTab === 'landingpage' ? 'active' : ''}`} id="landingpage">
                                    <div className="card p-0 mb-20" style={{ height: '80vh' }}>
                                        <div className="row">
                                            <div className="col-md-12 p-0">
                                                <div className="card common-card">
                                                    <div className="card-header d-flex jc-sb">
                                                        <h3 className="head m-0">Home Page Content</h3>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group mb-20">
                                                                    <label>Title</label>
                                                                    <input
                                                                        type="text"
                                                                        className={`form-control mb-0 ${errors.title0 ? 'is-invalid' : ''}`}
                                                                        name="title0"
                                                                        onChange={handleInputChange}
                                                                    />
                                                                    {errors.title0 && <div className="invalid-feedback">{errors.title0}</div>}
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group mb-20">
                                                                    <label>Title Description</label>
                                                                    <input
                                                                        type="text"
                                                                        className={`form-control mb-0 ${errors.titleDescription0 ? 'is-invalid' : ''}`}
                                                                        name="titleDescription0"
                                                                        onChange={handleInputChange}
                                                                    />
                                                                    {errors.titleDescription0 && (
                                                                        <div className="invalid-feedback">{errors.titleDescription0}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group mb-20">
                                                                    <label>Image Upload</label>
                                                                    <input
                                                                        type="file"
                                                                        className={`form-control mb-0 ${errors.imageUpload0 ? 'is-invalid' : ''}`}
                                                                        name="imageUpload0"
                                                                        onChange={handleInputChange}
                                                                    />
                                                                    {errors.imageUpload0 && <div className="invalid-feedback">{errors.imageUpload0}</div>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col text-right">
                                                                <button type="button" className="btn btn-primary" onClick={handleLandingPageUpdate}>
                                                                    Update
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`tab-pane ${activeTab === 'servicepage' ? 'active' : ''}`} id="servicepage">
                                    <div className="card p-0 mb-20" style={{ height: '80vh' }}>
                                        <div className="row">
                                            <div className="col-md-12 p-0">
                                                <div className="card common-card">
                                                    <div className="card-header d-flex jc-sb">
                                                        <h3 className="head m-0">Service Page Content</h3>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-4 mb-15">
                                                                <label>
                                                                    Select Service :
                                                                    <span className="red">
                                                                        <strong> *</strong>
                                                                    </span>
                                                                </label>
                                                                <select
                                                                    name="service"
                                                                    className={`form-control mb-0 ${errors.service ? 'is-invalid' : ''}`}
                                                                    value={formData.service}
                                                                    onChange={handleInputChange}
                                                                >
                                                                    <option value="">Select Service</option>
                                                                    {categories.map((category) => (
                                                                        <option key={category.id} value={category.id}>
                                                                            {category.category}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                {errors.service && <div className="invalid-feedback">{errors.service}</div>}
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="form-group mb-20">
                                                                    <label>
                                                                        Title
                                                                        <span className="red">
                                                                            <strong> *</strong>
                                                                        </span>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="title"
                                                                        className={`form-control mb-0 ${errors.title ? 'is-invalid' : ''}`}
                                                                        value={formData.title}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                    {errors.title && <div className="invalid-feedback">{errors.title}</div>}
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="form-group mb-20">
                                                                    <label>
                                                                        Title Description
                                                                        <span className="red">
                                                                            <strong> *</strong>
                                                                        </span>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="titleDescription"
                                                                        className={`form-control mb-0 ${errors.titleDescription ? 'is-invalid' : ''}`}
                                                                        value={formData.titleDescription}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                    {errors.titleDescription && (
                                                                        <div className="invalid-feedback">{errors.titleDescription}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-8">
                                                                <div className="form-group mb-20">
                                                                    <label>
                                                                        Tagline
                                                                        <span className="red">
                                                                            <strong> *</strong>
                                                                        </span>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="tagline"
                                                                        className={`form-control mb-0 ${errors.tagline ? 'is-invalid' : ''}`}
                                                                        value={formData.tagline}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                    {errors.tagline && <div className="invalid-feedback">{errors.tagline}</div>}
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="form-group mb-20">
                                                                    <label>
                                                                        Image Upload
                                                                        <span className="red">
                                                                            <strong> *</strong>
                                                                        </span>
                                                                    </label>
                                                                    <input
                                                                        type="file"
                                                                        name="imageUpload"
                                                                        accept="image/jpeg, image/png"
                                                                        className={`form-control mb-0 ${errors.imageUpload ? 'is-invalid' : ''}`}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                    {errors.imageUpload && <div className="invalid-feedback">{errors.imageUpload}</div>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group mb-20">
                                                                    <label>
                                                                        Description
                                                                        <span className="red">
                                                                            <strong> *</strong>
                                                                        </span>
                                                                    </label>
                                                                    <textarea
                                                                        style={{ height: '20vh' }}
                                                                        name="description"
                                                                        className={`form-control mb-0 ${errors.description ? 'is-invalid' : ''}`}
                                                                        value={formData.description}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                    {errors.description && <div className="invalid-feedback">{errors.description}</div>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col text-right">
                                                                <button type="button" className="btn btn-primary" onClick={handleServicePageUpdate}>
                                                                    Update
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}