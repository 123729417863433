import React, { useState, useEffect } from 'react'; // Import useState
import AdminNavbar from '../components/adminNavbar';
import clock from '../../images/clock.png';
import location from '../../images/location.png';
import editicon from '../../images/edit.png';
import deleteicon from '../../images/delete.png';
import publishicon from '../../images/publish.png';
//import eventData from '../../components/events.json';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const API_URL = 'https://manamvanam.com/api/events';
export default function AdminEvents() {
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [eventData, setData] = useState([]);
    const eventsPerPage = 9;

    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        },
    };

    // Calculate start and end indices for events on the current page
    const startIndex = (currentPage - 1) * eventsPerPage;
    const endIndex = startIndex + eventsPerPage;
    //var currentEvents = [];
    //var totalPages = 1;
    const getEventsData = () => {
        // Perform AJAX call using Axios here
        axios.get(`${API_URL}`)
            .then(response => {
                console.log(response);
                const fetchedData = response.data;

                setData(fetchedData); // Store the fetched data in state
                // Process the data
                // Sort the events by eventDate in ascending order
                console.log(eventData);
                //eventData.events.sort((a, b) => new Date(a.EventStartDate) - new Date(b.EventStartDate));

                // Sort the events so that "Completed" events appear at the end
                //eventData.events.sort((a, b) => {
                //    if (a.eventStatus === 'Completed' && b.eventStatus !== 'Completed') {
                //        return 1;
                //    } else if (a.eventStatus !== 'Completed' && b.eventStatus === 'Completed') {
                //        return -1;
                //    } else {
                //        return 0;
                //    }
                //});
                // currentEvents = eventData.events.slice(startIndex, endIndex);

                //totalPages = Math.ceil(eventData.events.length / eventsPerPage);
            })
            .catch(error => {
                // Handle errors
            });
    }
    useEffect(() => {
        getEventsData();
    }, []); // The empty array means this effect will run only once (on mount)

    

     

     // Slice the sorted events array to get the events for the current page
    

     // Function to handle page change
     const handlePageChange = (newPage) => {
          setCurrentPage(newPage);
     };

     const formatDate = (dateString) => {
          const date = new Date(dateString);
          const monthNames = [
               'Jan', 'Feb', 'March', 'April', 'May', 'June', 'July',
               'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
          ];
          const month = monthNames[date.getMonth()];
          const day = String(date.getDate()).padStart(2, '0');
          const year = date.getFullYear();
          return { month, day, year };
     };

    const handleDelete = (eventId) => {
        axios.delete(`${API_URL}/${eventId}`, null, config)
            .then(response => {
                console.log(response);
                if (response != null && response !== undefined && response.status === 200) {
                    getEventsData();
                    //navigate('/admin/events');
                }
            })
            .catch(error => {

            });
        
    }
     return (
          <>
               <AdminNavbar />
               <div className="events">
                    <div className="res-container sm-pt-10">
                         <div className="card p-0">
                              <div className="row">
                                   <div className="col-md-12 p-0">
                                        <div className="card common-card">
                                             <div className="card-header d-flex jc-sb">
                                                  <h3 className="head m-0">Events</h3>
                                                  <Link to="/admin/addevents" className="btn btn-primary">
                                                       Add Events
                                                  </Link>
                                             </div>
                                             <div className="card-body">
                                                  <div className="row">
                                             {eventData.map((event) => (
                                                            <div className="col-md-4" key={event.Id}>
                                                     <div className={`cust-e card mb-20 ${event.EventStatus === 'Completed' ? 'completed-event' : ''}`}>
                                                                      {event.EventStatus === 'Completed' && (
                                                                           <div class="cust-ribbon ribbon-top-left"><span>Completed</span></div>
                                                                      )}
                                                                      <div className="row">
                                                                           <div className="small-blog-img col-md-3 p-0 p-relative">
                                                                 <div className="event-banner " style={{ backgroundImage: `url(${event.EventImage})` }}>
                                                                                     <span className="head">{formatDate(event.EventStartDate).month}</span>
                                                                     <h1 className="head">{formatDate(event.EventStartDate).day}</h1>
                                                                     <span className="head">{formatDate(event.EventStartDate).year}</span>
                                                                                </div>
                                                                           </div>
                                                                           <div className="col-md-9 p-20">
                                                                                {/* <div className="d-flex">
                                                                                     <div className="d-flex mr-30"><p className="mb-10"><img className="c-icons mr-5" src={clock} alt="clock" />{event.eventStartTime}-{event.eventEndTime}</p></div>
                                                                                     <div className="d-flex mr-30">
                                                                         <p className="mb-10"><img className="c-icons mr-5" src={location} alt="location" />{event.EventCity}</p>
                                                                                     </div>
                                                                                </div> */}
                                                                                <h4 class="card-title">{event.EventName}</h4>
                                                                                <p className="mb-10">{event.EventMotto}</p>
                                                                                <div className="event-footer d-flex jc-sa">
                                                                     <Link to={`/admin/editevents/${event.Id}`}><img className="action-img" src={editicon} alt="edit" /></Link>
                                                                     <button onClick={() => handleDelete(event.Id)}><img className="action-img del" src={deleteicon} alt="delete"  /></button>
                                                                                     <button><img className="action-img" src={publishicon} alt="Publish" /></button>
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       ))}
                                                  </div>

                                                  {/* Pagination */}
                                                  <div className="pagination">
                                                       {Array.from({ length: 1 }, (_, index) => (
                                                            <button
                                                                 key={index}
                                                                 className={currentPage === index + 1 ? 'active' : ''}
                                                                 onClick={() => handlePageChange(index + 1)}
                                                            >
                                                                 {index + 1}
                                                            </button>
                                                       ))}
                                                  </div>

                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </>
     );
}
