import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../images/logo.png';
import paymentfailed from '../images/payment-failed.png';


export default function PaymentFailed() {
     return (
          <>
               <section className="header">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                         <div className="container-fluid">
                              <NavLink className="navbar-brand logo" to="/">
                                   <img src={logo} alt="Logo" />
                              </NavLink>
                         </div>
                    </nav>
               </section>
               <div className="d-flex jc-sb" style={{ alignContent: 'center', flexWrap: 'wrap', height: '100vh' }}>
                    <div className="res-container" style={{ maxWidth: '70%' }}>
                         <div className="row">
                              <div className="col-6 d-flex jc-sb" style={{ alignContent: 'center', flexWrap: 'wrap' }}>
                                   <div classNAme="d-grid">
                                        <h1 className="head mb-20">Transaction Failed</h1>
                                        <p className="mb-10">Your transaction has been declined. Please contact your bank for further details.</p>
                                        <p className="mb-40">Or, to proceed with payment, You can retry by choosing other payment methods.</p>
                                 <NavLink className="btn btn-primary mr-20" to="/guest-login">Back to Donation</NavLink>
                                        <NavLink className="btn btn-outline" to="/">Home</NavLink>
                                   </div>
                              </div>
                              <div className="col-6">
                                   <img src={paymentfailed} alt="payment" style={{ width: '75vh', padding: '0 30% 0 0' }} />
                              </div>
                         </div>
                    </div>
               </div>
          </>
     )
}

