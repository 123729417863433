import React, { useState, useEffect } from 'react';
import AdminNavbar from '../components/adminNavbar';
import { Link } from 'react-router-dom';
//import expenseData from '../../components/expense.json'; // Import the JSON data
import axios from 'axios';

const API_URL = 'https://manamvanam.com/api/Expenses';

export default function AdminExpense({ setToken }) {
    const token = localStorage.getItem('token');
    const [expenseData, setData] = useState([]);
     const itemsPerPage = 10;
     const [currentPage, setCurrentPage] = useState(1);
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        },
    };

    const getExpensesData = () => {
        // Perform AJAX call using Axios here
        axios.get(`${API_URL}`)
            .then(response => {
                console.log(response);
                const fetchedData = response.data;
                setData(fetchedData); // Store the fetched data in state
            })
            .catch(error => {
                // Handle errors
            });
    }
    useEffect(() => {
        getExpensesData();
    }, []); // The empty array means this effect will run only once (on mount)

     // Calculate the total number of pages based on data length and items per page
     const totalPages = Math.ceil(expenseData.length / itemsPerPage);

     // Function to slice the data based on the current page
     const getPaginatedData = () => {
          const startIndex = (currentPage - 1) * itemsPerPage;
          const endIndex = startIndex + itemsPerPage;
          return expenseData.slice(startIndex, endIndex);
     };

     const handlePageChange = (page) => {
          setCurrentPage(page);
     };

     return (
          <>
               <AdminNavbar setToken={setToken} />
               <div className="events">
                    <div className="res-container sm-pt-10">
                         <div className="card p-0">
                              <div className="row">
                                   <div className="col-md-12 p-0">
                                        <div className="card common-card">
                                             <div className="card-header d-flex jc-sb">
                                                  <div className="row d-flex w-100">
                                                       <div className="col-4">
                                                            <h3 className="head m-0">Expense</h3>
                                                       </div>
                                                       <div className="col-8 d-flex jc-e">
                                                            <div>
                                                                 <Link to="/admin/addexpense" className="btn btn-primary">
                                                                      Add Expense
                                                                 </Link>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className="card-body">
                                                  <table className="table">
                                                       <thead>
                                                            <tr>
                                                                 <th>Sl No.</th>
                                                                 <th>Type</th>
                                                                 <th>Amount</th>
                                                                 <th>Description</th>
                                                                 <th>Date</th>
                                                                 <th>By whom</th>
                                                                 <th>Attachments</th>
                                                                 <th>Category</th>
                                                                 <th>Vendor Name</th>
                                                                 <th>Payment Method</th>
                                                                 <th>Receipt Number</th>
                                                                 <th>Service</th>
                                                                 <th>Event Name</th>
                                                            </tr>
                                                       </thead>
                                                       <tbody>
                                                            {getPaginatedData().map((expense, index) => (
                                                                 <tr key={index}>
                                                                      <td>{expense.id}</td>
                                                                    <td>{expense.expenseType}</td>
                                                                    <td>{expense.expenseAmount}</td>
                                                                    <td>{expense.expenseDescription}</td>
                                                                    <td>{expense.expenseDate}</td>
                                                                    <td>{expense.expenseBy}</td>
                                                                    {/*<td>{expense.attachments.join(', ')}</td>*/}
                                                                    <td><a href={expense.attachments[0]} target="_blank" rel="noopener noreferrer">
                                                                        View Document
                                                                    </a></td>
                                                                    <td>{expense.expenseCategory}</td>
                                                                      <td>{expense.vendorName}</td>
                                                                      <td>{expense.paymentMethod}</td>
                                                                      <td>{expense.receiptNumber}</td>
                                                                      <td>{expense.service}</td>
                                                                      <td>{expense.eventName}</td>
                                                                 </tr>
                                                            ))}
                                                       </tbody>
                                                  </table>
                                                  {/* Pagination buttons */}
                                                  <nav aria-label="Page navigation">
                                                       <ul className="pagination">
                                                            {Array.from({ length: totalPages }, (_, index) => (
                                                                 <li
                                                                      key={index}
                                                                      className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                                                                 >
                                                                      <button
                                                                           className="page-link"
                                                                           onClick={() => handlePageChange(index + 1)}
                                                                      >
                                                                           {index + 1}
                                                                      </button>
                                                                 </li>
                                                            ))}
                                                       </ul>
                                                  </nav>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </>
     );
}
