import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import EventTabs from '../components/EventTabs';
import './Main.css';

export default function Events() {
     return (
          <>
               <Navbar />
               <div className="events">
                    <div className="row">
                    <div className="col-12">
                    <EventTabs />
                    </div>
                    </div>
               </div>
               <Footer />
          </>
     )
}
