import React, { useState, useEffect } from "react";
import clock from '../images/clock.png';
import rs from '../images/rs.png';
import mail from '../images/mail.png';
import hand from '../images/hand.png';
import calender from '../images/calender.png';
import phone from '../images/call.png';
import location from '../images/location.png';
import facebook from '../images/facebook.png';
import linedin from '../images/linkedin.png';
import twitter from '../images/twitter.png';
import google from '../images/google.png';
import EnrollForm from './enrollForm.js';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import jsonData from './events.json';
import Moment from 'react-moment';
import 'moment-timezone';

const API_URL = 'https://manamvanam.com/api/Events';

const EventSingle = () => {
     const { eventId } = useParams(); // Get the eventId from the URL params
     const token = localStorage.getItem('token');
     //const event = jsonData.events.find((event) => event.id === parseInt(eventId));

     const config = {
          headers: {
               Authorization: `Bearer ${token}`
          },
     };
     const [event, setEventData] = useState({
          EventDetailId: eventId,
          EventName: '',
          EventStartTime: '',
          EventStartDate: '',
          EventPhoneno: '',
          EventMail: '',
          EventVenue: '',
          EventCity: '',
          EventDescription: '',
          EventImage: '', // You can handle file uploads separately
          EventStatus: 'Ongoing',
          EventLocations: []
     });

     useEffect(() => {

          if (eventId > 0) {
               // Perform AJAX call using Axios here
               axios.get(`${API_URL}/${eventId}`, config)
                    .then(response => {
                         console.log(response);
                         const fetchedData = response.data;
                         setEventData(fetchedData); // Store the fetched data in state
                         // Process the data
                    })
                    .catch(error => {
                         // Handle errors
                    });
          }
     }, [setEventData]);

     if (!event) {
          return <div>Event not found!</div>;
     }


     const handleCityClick = (index) => {
          const updatedLocations = event.EventLocations.map((loc, i) => ({
               ...loc,
               visible: i === index,
          }));

          setEventData((prevEvent) => ({
               ...prevEvent,
               EventLocations: updatedLocations,
          }));
     };


     return (
          <>

               <div className="res-container">
                    <div className="" style={{ position: 'relative' }}>
                         <div className="row">
                              <div className="col-md-12 p-0">
                                   <div className="b-0">
                                        <div className=" p-0">
                                             <div className="row">
                                                  <div className="col">
                                                       <div className="common-card">
                                                            <div className="card-body">
                                                                 <div className="row">
                                                                      <h1>{event.EventName}</h1>
                                                                      <p>{event.EventMotto}</p>
                                                                 </div>
                                                                 <div className="row">
                                                                      <div className="col" >
                                                                           <div className="single-banner" style={{ backgroundImage: `url(${event.EventBanner})` }}>
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                                 <div className="row solution-page" style={{ paddingTop: '0', height: 'auto' }}>
                                                                      <div className='card common-card'>
                                                                           <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                                                <li className="nav-item" role="presentation">
                                                                                     <a className="nav-link active" id="description-tab" data-bs-toggle="tab" href="#description" role="tab" aria-controls="description" aria-selected="true"><h4 className="head">Description</h4></a>
                                                                                </li>
                                                                                <li className="nav-item" role="presentation">
                                                                                     <a className="nav-link" id="event-gallery-tab" data-bs-toggle="tab" href="#event-gallery" role="tab" aria-controls="event-gallery" aria-selected="false"><h4 className="head">Gallery</h4></a>
                                                                                </li>
                                                                                <li className="nav-item" role="presentation">
                                                                                     <a className="nav-link" id="event-video-tab" data-bs-toggle="tab" href="#event-video" role="tab" aria-controls="event-video" aria-selected="false"><h4 className="head">Videos</h4></a>
                                                                                </li>
                                                                           </ul>
                                                                           <div className="card-body p-0 event-gallery-content">
                                                                                <div className="tab-content" id="myTabContent">
                                                                                     <div className="tab-pane fade show active" id="description" role="tabpanel" aria-labelledby="description-tab">
                                                                                          <p className="p-5 m-0">{event.EventDescription}</p>
                                                                                     </div>
                                                                                     <div className="tab-pane fade" id="event-gallery" role="tabpanel" aria-labelledby="event-gallery-tab">
                                                                                          <div className="row">
                                                                                               {event.hasOwnProperty('EventGalleryImages') && event.EventGalleryImages.map((image, index) => (
                                                                                                    <div className="col-3 gallery-outer" key={index}>
                                                                                                         <div
                                                                                                              className="gallery-img"
                                                                                                              style={{ backgroundImage: `url(${image.Path})` }}
                                                                                                         >
                                                                                                         </div>
                                                                                                    </div>
                                                                                               ))}
                                                                                          </div>
                                                                                     </div>
                                                                                     <div className="tab-pane fade" id="event-video" role="tabpanel" aria-labelledby="event-video-tab">
                                                                                          <div className="row">
                                                                                               {event.hasOwnProperty('EventGalleryVideos') && event.EventGalleryVideos.map((video, index) => (
                                                                                                    <div className="col-3 gallery-outer">
                                                                                                         <video key={index} controls>
                                                                                                              <source src={video.Path} type="video/mp4" />
                                                                                                              Your browser does not support the video tag.
                                                                                                         </video>
                                                                                                    </div>
                                                                                               ))}
                                                                                          </div>
                                                                                     </div>
                                                                                </div>
                                                                           </div>

                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  </div>
                                                  <div className="col-3">
                                                       <div className="row mb-20">
                                                            <div className="box row" style={{ padding: '1.5vh 1.5vh 0 1.5vh' }}>
                                                                 <div className="card c-card">
                                                                      <div className="card-header">
                                                                           <div className="card-title">
                                                                                <h4 className="head">Event Details</h4>
                                                                           </div>
                                                                      </div>
                                                                      <div className="card-body event-details1">
                                                                           <div className="col">

                                                                                <div className="col">
                                                                                     <div className="row mb-5">
                                                                                          <div className="col p-0 d-flex">
                                                                                               <p className="m-0"><img src={clock} alt="Clock" className="filter-green c-icons mr-10" /></p>
                                                                                               <p><b>Event Time :</b><br />{event.EventStartTime}</p>
                                                                                          </div>
                                                                                     </div>
                                                                                     <div className="row mb-5">
                                                                                          <div className="col p-0 d-flex">
                                                                                               <p className="m-0"><img src={calender} alt="Clock" className="filter-green c-icons mr-10" /></p>
                                                                             {/* <p><b>Event Date :</b><br />{event.EventStartDate}</p>*/}
                                                                             <p><b>Event Date :</b><br />  <Moment date={event.EventStartDate} format="MMMM Do YYYY" /></p>
                                                                                          </div>
                                                                                     </div>
                                                                                     <div className="row mb-5">
                                                                                          <div className="col p-0 d-flex">
                                                                                               <p className="m-0"><img src={phone} alt="Clock" className="filter-green c-icons mr-10" /></p>
                                                                                               <p><b>Event Organizer No :</b><br />{event.EventPhoneno}</p>
                                                                                          </div>
                                                                                     </div>
                                                                                     <div className="row mb-5">
                                                                                          <div className="col p-0 d-flex">
                                                                                               <p className="m-0"><img src={mail} alt="Clock" className="filter-green c-icons mr-10" /></p>
                                                                                               <p><b>Event Organizer Email :</b><br />{event.EventMail}</p>
                                                                                          </div>
                                                                                     </div>
                                                                                     <div className="row mb-20">
                                                                                          <div className="col p-0 d-flex">
                                                                                               <p className="m-0">
                                                                                                    <img src={location} alt="Clock" className="filter-green c-icons mr-10" />
                                                                                               </p>
                                                                                               <p className="w-100">
                                                                                                    <b>Event Locations :</b><br /><small><i>Click on city text to view address    </i></small>
                                                                                                    {event.EventLocations.map((eventLocation, index) => (
                                                                                                         <div key={index} className="city-head">
                                                                                                              <div
                                                                                                                   className={`location-city ${eventLocation.visible ? 'active' : ''}`}
                                                                                                                   onClick={() => handleCityClick(index)}
                                                                                                              >
                                                                                                                   {eventLocation.city}
                                                                                                              </div>
                                                                                                              <div className={`location-address ${eventLocation.visible ? 'visible' : ''}`}>
                                                                                                                   {eventLocation.address}
                                                                                                              </div>
                                                                                                         </div>
                                                                                                    ))}
                                                                                               </p>
                                                                                          </div>
                                                                                     </div>
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                                 <div className="card p-0" style={{
                                                                      background: 'transparent', border: '0'
                                                                 }}>
                                                                      <div className="card-body" style={{
                                                                           height: '9vh',
                                                                           padding: '25px 15px'
                                                                      }}>
                                                                           <div className="row mb-5">
                                                                                <div className="col p-0 text-center">
                                                                                     <a href={event.EventFacebookLink} target="_blank" rel="noopener noreferrer">
                                                                                          <img src={facebook} alt="Clock" className="mr-5" />
                                                                                     </a>
                                                                                </div>
                                                                                <div className="col p-0 text-center">
                                                                                     <a href={event.EventLinkedInLink} target="_blank" rel="noopener noreferrer">
                                                                                          <img src={linedin} alt="Clock" className="mr-5" />
                                                                                     </a>
                                                                                </div>
                                                                                <div className="col p-0 text-center">
                                                                                     <a href={event.EventFacebookLink} target="_blank" rel="noopener noreferrer">
                                                                                          <img src={twitter} alt="Clock" className="mr-5" />
                                                                                     </a>
                                                                                </div>
                                                                                <div className="col p-0 text-center">
                                                                                     <a href={event.EventFacebookLink} target="_blank" rel="noopener noreferrer">
                                                                                          <img src={google} alt="Clock" className="mr-5" />
                                                                                     </a>
                                                                                </div>
                                                                           </div>
                                                                           <img src={hand} className="hand" />
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  </div>
                                                  <div className="col-3">
                                                       <div className="row mb-20">
                                                            <div className="card c-card">
                                                                 <div className="card-header">
                                                                      <div className="card-title">
                                                                           <h4 className="head">Enroll No</h4>
                                                                      </div>
                                                                 </div>
                                                                 <div className="card-body single-form">
                                                         <EnrollForm className="col-12" showBloodGroup={true} showVolunteerEvent={true} eventId={eventId} />
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>

          </>
     );
};

export default EventSingle;