import React, { useState, useEffect } from 'react';
import AdminNavbar from '../components/adminNavbar';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const BASE_API_URL = 'https://manamvanam.com/api/';

export default function AdminAddOfflineCompanyDonation() {
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    //const [servicesData, setServicesData] = useState([]);
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        },
    };

    const [donationData, setDonationData] = useState({
        CompanyName: '',
        PaymentMode: '',
        PaymentDate: '',
        PaymentTxnNo: '',
        CompanyEmailId: '',
        CompanyMobile: '',
        DonationAmount: '',
        DonationCurrency: 'INR',
        ServiceDonations: [],
    });

    useEffect(() => {
        getServicesData();
    }, []);

    const getServicesData = () => {
        axios.get(`${BASE_API_URL}ServiceDetails`, config)
            .then(response => {
                console.log(response);
                const fetchedData = response.data;
                //setServicesData(fetchedData); // Store the fetched data in state
                const ServiceDonationsDatas = fetchedData.map((item) => {
                    return {
                        ServiceId: item.ServiceDetailId,
                        ServiceTitle: item.ServiceTitle,
                        EventId: null,
                       // EventTitle: '',
                        ServiceDonationAmount: ''
                    };
                });
                //setServiceDonationData(ServiceDonationsDatas);
                setDonationData((prevState) => ({
                    ...prevState,
                    ServiceDonations: ServiceDonationsDatas
                }));
            })
            .catch(error => {
                // Handle errors
            });
    }
     //const handleChange = (e) => {
     //     const { name, value } = e.target;
     //     setDonationData({ ...donationData, [name]: value });
     //};
    const handleChange = (event) => {
        const { name, value } = event.target;
        setDonationData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleServiceDonationsChange = (event, serviceId) => {
        const { name, value } = event.target;
        // Find the service amount object with the matching service ID
        const matchingServiceDonations = donationData.ServiceDonations.find(ServiceDonations => ServiceDonations.ServiceId === serviceId);

        // Update the service amount object
        if (matchingServiceDonations) {
            matchingServiceDonations[name] = value;
        }

        // Update the state with the modified service amount array
        setDonationData((prevState) => ({
            ...prevState,
            ServiceDonations: [...prevState.ServiceDonations]
        }));
    };

     const handleSubmit = (e) => {
          e.preventDefault();
          // Handle form submission, e.g., send data to the server
         console.log(donationData);
         axios.post(`${BASE_API_URL}OfflineCompanyDonations`, donationData, config).then(response => {
             console.log(response);
             if (response != null && response !== undefined && response.status === 200) {
                 navigate('/admin/donation');
             }
         })
             .catch(error => {

             });
     };

     return (
          <>
               <AdminNavbar />
               <div className="events">
                    <div className="res-container sm-pt-10">
                         <div className="card p-0 mb-20">
                              <div className="row">
                                   <div className="col-md-12 p-0">
                                        <div className="card common-card">
                                             <div className="card-header d-flex jc-sb">
                                                  <h3 className="head m-0">Add Offline Company Donation</h3>
                                                  <Link to="/admin/donation" className="btn btn-primary">
                                                       Back
                                                  </Link>
                                             </div>
                                             <div className="card-body">
                                                  <form onSubmit={handleSubmit}>
                                                       <div className="row">
                                                            <div className="col">
                                                                 <div className="form-group">
                                                                      <label>Company Name<span className="red"><strong> *</strong></span></label>
                                                                      <input
                                                                           type="text"
                                                                           className="form-control"
                                                             name="CompanyName"
                                                             value={donationData.CompanyName}
                                                                           onChange={handleChange}
                                                                      />
                                                                 </div>
                                                 </div>
                                                 
                                                            <div className="col">
                                                                 <div className="form-group">
                                                                      <label>Payment Mode<span className="red"><strong> *</strong></span></label>
                                                                      <select
                                                                           className="form-control"
                                                                           name="PaymentMode"
                                                                           value={donationData.PaymentMode}
                                                                           onChange={handleChange}
                                                                      >
                                                                           <option value="">Select Payment Mode</option>
                                                                           <option value="Cash">Cash</option>
                                                                           <option value="UPI">UPI</option>
                                                                           <option value="Netbanking">Netbanking</option>
                                                                      </select>
                                                                 </div>
                                                            </div>
                                                            <div className="col">
                                                                 <div className="form-group">
                                                                      <label>Payment Date<span className="red"><strong> *</strong></span></label>
                                                                      <input
                                                                           type="date"
                                                                           className="form-control"
                                                                           name="PaymentDate"
                                                                           value={donationData.PaymentDate}
                                                                           onChange={handleChange}
                                                                      />
                                                                 </div>
                                                 </div>
                                                 <div className="col">
                                                     <div className="form-group">
                                                         <label>Payment Transaction<span className="red"><strong> *</strong></span></label>
                                                         <input
                                                             type="text"
                                                             className="form-control"
                                                             name="PaymentTxnNo"
                                                             value={donationData.PaymentTxnNo}
                                                             onChange={handleChange}
                                                         />
                                                     </div>
                                                 </div>
                                                            <div className="col">
                                                                 <div className="form-group">
                                                                      <label>Company Email</label>
                                                                      <input
                                                                           type="email"
                                                                           className="form-control"
                                                             name="CompanyEmailId"
                                                             value={donationData.CompanyEmailId}
                                                                           onChange={handleChange}
                                                                      />
                                                                 </div>
                                                            </div>
                                                            <div className="col">
                                                                 <div className="form-group">
                                                         <label>Company Phone Number</label>
                                                                      <input
                                                                           type="text"
                                                                           className="form-control"
                                                             name="CompanyMobile"
                                                             value={donationData.CompanyMobile}
                                                                           onChange={handleChange}
                                                                      />
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  </form>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className="card p-0 mb-20">
                              <div className="row">
                                   <div className="col-md-12 p-0">
                                        <div className="card common-card">
                                             <div className="card-header d-flex jc-sb">
                                                  <h3 className="head m-0">Add Donation Amount</h3>
                                             </div>
                                             <div className="card-body">
                                                  <form onSubmit={handleSubmit}>
                                                       <div className="row">

                                                            <div className="col">
                                                                 <div className="form-group">
                                                                      <label>Donation Amount<span className="red"><strong> *</strong></span></label>
                                                                      <input
                                                                           type="text"
                                                                           className="form-control"
                                                                           name="DonationAmount"
                                                                           value={donationData.DonationAmount}
                                                                           onChange={handleChange}
                                                                      />
                                                                 </div>
                                                            </div>
                                                       </div>
                                             <div className="row">
                                                 {donationData.ServiceDonations.map((service) => (
                                                     <div className="col">
                                                                 <div className="form-group">
                                                             <label>{service.ServiceTitle}</label>
                                                             <input
                                                                 type="hidden"
                                                                 className="form-control"
                                                                 name="ServiceId"
                                                                 value={service.ServiceId}
                                                             />
                                                                      <input
                                                                           type="text"
                                                                           className="form-control"
                                                                            name="ServiceDonationAmount"
                                                                            value={service.ServiceDonationAmount}
                                                                         //onChange={handleServiceDonationsChange}
                                                                 onChange={(event) => handleServiceDonationsChange(event, service.ServiceId)}
                                                                      />
                                                                 </div>
                                                            </div>
                                                 ))}

                                                       </div>
                                                  </form>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                     <div className="col-md-12 text-right">
                         <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                                   Save
                              </button>
                         </div>
                    </div>
               </div>
          </>
     );
}
