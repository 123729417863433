import React from 'react';
import CustomerNavbar from '../components/CustomerNavbar';
import Footer from '../../components/Footer';
import EventCommonSingle from '../../components/EventCommonSingle';

const CustomerEventSingle = ({ setToken }) => {

     return (
          <>
               <CustomerNavbar setToken={setToken} />
               <div className="events">

                    <EventCommonSingle />

               </div>
               {/* <Footer /> */}
          </>
     );
};

export default CustomerEventSingle;