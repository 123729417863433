import React, { useState,  useEffect } from "react";
import AdminNavbar from '../components/adminNavbar';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
const API_URL = 'https://manamvanam.com/api/Events';

export default function AdminEditEvents() {
    const { id } = useParams();
    const token = localStorage.getItem('token');
    const[servicesData, setServicesData] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const navigate = useNavigate();
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        },
    };

    const [eventData, setEventData] = useState({
        EventDetailId: id,
         EventName: '',
         EventStartTime: '',
         EventStartDate: '',
         EventPhoneno: '',
         EventMail: '',
         EventVenue: '',
         EventCity: '',
         EventDescription: '',
         EventImage: '', // You can handle file uploads separately
         EventStatus: 'Ongoing',
     });
   
     const handleChange = (e) => {
          const { name, value } = e.target;
         setEventData({ ...eventData, [name]: value });
         if (e.target.files !== undefined && e.target.files !== null
             && e.target.files.length > 0) {
             setSelectedFile(e.target.files[0]);
         }
     };

     const handleSubmit = (e) => {
          e.preventDefault();

          // Handle form submission for editing (e.g., send updated data to the server)
          console.log(eventData);
    };

    const handleUpdate = () => {
     
        //setProfileData(profileData)
        const submitEventData = new FormData();
        submitEventData.append('MasterServiceId', 1);
        submitEventData.append('EventTitle', eventData.EventName);
        submitEventData.append('EventDescription', eventData.EventDescription);
        submitEventData.append('EventMotto', eventData.EventName);
        submitEventData.append('EventImageLink', "");
       // submitEventData.append('EventBanner', eventData.eventBanner);
        submitEventData.append('EventStatus', eventData.EventStatus);

        submitEventData.append('EventStartDate', eventData.EventStartDate);
        submitEventData.append('EventEndDate', eventData.EventStartDate);
        submitEventData.append('EventLocation', eventData.EventVenue);
        submitEventData.append('EventCity', eventData.EventCity);
        submitEventData.append('EventFacebookLink', "");
        submitEventData.append('EventLinkedInLink', "");
        submitEventData.append('EventContactMail', eventData.EventMail);
        submitEventData.append('EventContactPhone', eventData.EventPhoneno);
        submitEventData.append('RevenueTarget', 1000);
        //submitEventData.append('PublishDate', new Date());
        //submitEventData.append('PublishedBy', "Admin");
        //submitEventData.append('IsPublished', false);
        submitEventData.append('eventMediaFileData', selectedFile);
        axios.put(`${API_URL}/${eventData.EventDetailId}`, eventData, config)
            .then(response => {
                console.log(response);
                if (response != null && response !== undefined && response.status === 200) {
                    navigate('/admin/events');
                }
            })
            .catch(error => {

            });
        
    };

    useEffect(() => {
        
        if (id>0) {
            // Perform AJAX call using Axios here
            axios.get(`${API_URL}/${id}`, config)
                .then(response => {
                    console.log(response);
                    const fetchedData = response.data;
                    setEventData(fetchedData); // Store the fetched data in state
                    // Process the data
                })
                .catch(error => {
                    // Handle errors
                });
        }
    }, [setEventData]);

     return (
          <>
               <AdminNavbar />
               <div className="events">
                    <div className="res-container sm-pt-10">
                         <div className="card p-0">
                              <div className="row">
                                   <div className="col-md-12 p-0">
                                        <div className="card common-card">
                                             <div className="card-header d-flex jc-sb">
                                                  <h3 className="head m-0">Edit Events</h3>
                                                  <Link to="/admin/events" className="btn btn-primary">
                                                       Back
                                                  </Link>
                                             </div>
                                             <div className="card-body">
                                                  <form onSubmit={handleSubmit}>
                                                       <div className="row">
                                                            <div className="col-md-3">
                                                                 <div className="form-group">
                                                                      <label>Event Name<span className="red"><strong> *</strong></span></label>
                                                                      <input
                                                                           type="text"
                                                                           className="form-control"
                                                             name="EventName"
                                                                           value={eventData.EventName}
                                                                           onChange={handleChange}
                                                                           required
                                                                      />
                                                                 </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                 <div className="form-group">
                                                                      <label>Event Time<span className="red"><strong> *</strong></span></label>
                                                                      <input
                                                                           type="text"
                                                                           className="form-control"
                                                             name="EventStartTime"
                                                             value={eventData.EventStartTime}
                                                                           onChange={handleChange}
                                                                           required
                                                                      />
                                                                 </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                 <div className="form-group">
                                                                      <label>Event Date<span className="red"><strong> *</strong></span></label>
                                                                      <input
                                                                           type="date"
                                                                           className="form-control"
                                                             name="EventStartDate"
                                                             value={eventData.EventStartDate}
                                                                           onChange={handleChange}
                                                                           required
                                                                      />
                                                                 </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                 <div className="form-group">
                                                                      <label>Phone Number<span className="red"><strong> *</strong></span></label>
                                                                      <input
                                                                           type="text"
                                                                           className="form-control"
                                                             name="EventPhoneno"
                                                             value={eventData.EventPhoneno}
                                                                           onChange={handleChange}
                                                                           required
                                                                      />
                                                                 </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                 <div className="form-group">
                                                                      <label>Email<span className="red"><strong> *</strong></span></label>
                                                                      <input
                                                                           type="email"
                                                                           className="form-control"
                                                             name="EventMail"
                                                             value={eventData.EventMail}
                                                                           onChange={handleChange}
                                                                           required
                                                                      />
                                                                 </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                 <div className="form-group">
                                                                      <label>Address<span className="red"><strong> *</strong></span></label>
                                                                      <input
                                                                           type="text"
                                                                           className="form-control"
                                                             name="EventVenue"
                                                             value={eventData.EventVenue}
                                                                           onChange={handleChange}
                                                                           required
                                                                      />
                                                                 </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                 <div className="form-group">
                                                                      <label>City<span className="red"><strong> *</strong></span></label>
                                                                      <input
                                                                           type="text"
                                                                           className="form-control"
                                                             name="EventCity"
                                                             value={eventData.EventCity}
                                                                           onChange={handleChange}
                                                                           required
                                                                      />
                                                                 </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                 <div className="form-group">
                                                                      <label>Event Description<span className="red"><strong> *</strong></span></label>
                                                                      <textarea
                                                                           className="form-control"
                                                             name="EventDescription"
                                                             value={eventData.EventDescription}
                                                                           onChange={handleChange}
                                                                           required
                                                                      ></textarea>
                                                                 </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                 <div className="form-group">
                                                                      <label>Event Status</label>
                                                                      <select
                                                                           className="form-control"
                                                             name="EventStatus"
                                                             value={eventData.EventStatus}
                                                                           onChange={handleChange}
                                                                      >
                                                                           <option value="Ongoing">Ongoing</option>
                                                                           <option value="Completed">Completed</option>
                                                                      </select>
                                                                 </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                 <div className="form-group">
                                                                      <label>Event Banner (Upload)<span className="red"><strong> *</strong></span></label>
                                                                      <input
                                                                           type="file"
                                                                           className="form-control-file"
                                                             name="EventImage"
                                                                           onChange={handleChange}
                                                                      />
                                                                 </div>
                                                            </div>
                                                            <div className="col-md-6 text-right">
                                                                 <button type="submit" className="btn btn-primary">
                                                                      Update
                                                                 </button>
                                                            </div>
                                                       </div>
                                                  </form>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </>
     );
}
