import React, { Component } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Home from './pages/Home';
import HomeTwo from './pages/HomeTwo';
import AboutUs from './pages/AboutUs';
import ManamVanamServiceComponent from './components/ManamVanamServiceComponent';
import RythuBandham from './pages/RythuBandham';
import GirijanaBandham from './pages/GirijanaBandham';
import GramaBandham from './pages/GramaBandham';
import JanahitaBandham from './pages/JanahitaBandham';
import ParyavaranamBandham from './pages/ParyavaranamBandham';
import Events from './pages/Events';
import Gallery from './pages/Gallery';
import Login from './pages/Login';
import ForgetPassword from './pages/ForgetPassword';
import ResetPassword from './pages/ResetPassword';
import ContactUs from './pages/ContactUs';
import Volunteer from './pages/Volunteer';
import PaymentSuccess from './pages/PaymentSuccess';
import PaymentFailed from './pages/PaymentFailed';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndCondition from './pages/TermsAndCondition';
import Guest from './pages/Guest';
import Guestt from './pages/Guestt';
import EventSingle from './components/EventSingle';
import AdminDashboard from './admin/pages/adminDashboard';
import AdminEvents from './admin/pages/adminEvents';
import AdminAddEvents from './admin/pages/adminAddEvents';
import AdminEditEvents from './admin/pages/adminEditEvents';
import AdminDonation from './admin/pages/adminDonation';
import AdminAddOfflineDonation from './admin/pages/adminAddOfflineDonation';
import AdminAddOfflineCompanyDonation from './admin/pages/adminAddOfflineCompanyDonation';
import AdminServices from './admin/pages/adminServices';
import AdminMedia from './admin/pages/adminMedia';
import AdminAddMedia from './admin/pages/adminAddMedia';
import AdminExpense from './admin/pages/adminExpense';
import AdminAddExpense from './admin/pages/adminAddExpense';
import CustomerDashboard from './customer/pages/CustomerDashboard';
import CustomerEvents from './customer/pages/CustomerEvents';
import CustomerEventSingle from './customer/pages/CustomerEventSingle';

import NoPage from './pages/NoPage';
import './pages/Main.css';
import { TabProvider } from './components/TabContext'; // Import the TabProvider
import PaymentSuccessful from './pages/PaymentSuccessful';


export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: localStorage.getItem('token'),
            user: localStorage.getItem('user'),
        };
    }
    setToken = (token) => {
        this.setState({ token: token });
        localStorage.setItem('token', token);
    }

    setUser = (user) => {
        this.setState({ user: user });
        localStorage.setItem('user', user);
    }

    handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        this.setState({ token: null });
        this.setState({ user: null });
    };
    render() {

        return (
            <>
                <TabProvider>
                    <Routes>
                        <Route index element={<Home />} />
                        <Route path="/home2" element={<HomeTwo />} />
                        <Route path="/aboutus" element={<AboutUs />} />
                        <Route path="/rythubandham_component" element={<ManamVanamServiceComponent serviceName="1" />} />
                        <Route path="/girijanabandham_component" element={<ManamVanamServiceComponent serviceName="2" />} />
                        <Route path="/gramabandham_component" element={<ManamVanamServiceComponent serviceName="3" />} />
                        <Route path="/janahitabandham_component" element={<ManamVanamServiceComponent serviceName="4" />} />
                        <Route path="/paryavaranambandham_component" element={<ManamVanamServiceComponent serviceName="5" />} />
                        <Route path="/rythu_bandham" element={<ManamVanamServiceComponent serviceName="1" />} />
                        <Route path="/girijana_bandham" element={<ManamVanamServiceComponent serviceName="2" />} />
                        <Route path="/grama_bandham" element={<ManamVanamServiceComponent serviceName="3" />} />
                        <Route path="/janahitha_bandham" element={<ManamVanamServiceComponent serviceName="4" />} />
                        <Route path="/paryavaranam_bandham" element={<ManamVanamServiceComponent serviceName="5" />} />
                        <Route path="/rythubandham" element={<RythuBandham />} />
                        <Route path="/girijanabandham" element={<GirijanaBandham />} />
                        <Route path="/gramabandham" element={<GramaBandham />} />
                        <Route path="/janahitabandham" element={<JanahitaBandham />} />
                        <Route path="/paryavaranambandham" element={<ParyavaranamBandham />} />
                        <Route path="/events" element={<Events />} />
                        <Route path="/events/:eventId" element={<EventSingle />} />
                        <Route path="/gallery" element={<Gallery />} />
                        <Route path="/login" element={<Login setToken={this.setToken} setUser={this.setUser} />} />
                        <Route path="/forgetpassword" element={<ForgetPassword />} />
                        <Route path="/resetpassword/:passwordToken" element={<ResetPassword />} />
                        <Route path="/contactus" element={<ContactUs />} />
                        <Route path="/volunteer" element={<Volunteer />} />
                        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                        <Route path="/terms-and-condition" element={<TermsAndCondition />} />
                        <Route path="/guest-login" element={<Guest />} />
                        <Route path="/guestt" element={<Guestt />} />
                        <Route path="/paymentsuccess" element={<PaymentSuccess />} />
                        <Route path="/paymentsuccessful" element={<PaymentSuccessful />} />
                        <Route path="/paymentfailed" element={<PaymentFailed />} />

                        {/* Admin Routes */}
                        <Route path="/admin/dashboard" element={<AdminDashboard setToken={this.setToken} />} />
                        <Route path="/admin/events" element={<AdminEvents />} />
                        <Route path="/admin/addevents" element={<AdminAddEvents />} />
                        <Route path="/admin/editevents/:id" element={<AdminEditEvents />} />
                        <Route path="/admin/donation" element={<AdminDonation />} />
                        <Route path="/admin/offlinedonation" element={<AdminAddOfflineDonation />} />
                        <Route path="/admin/offlinecompanydonation" element={<AdminAddOfflineCompanyDonation />} />
                        <Route path="/admin/services" element={<AdminServices />} />
                        <Route path="/admin/media" element={<AdminMedia />} />
                        <Route path="/admin/addmedia" element={<AdminAddMedia />} />
                        <Route path="/admin/expense" element={<AdminExpense />} />
                        <Route path="/admin/addexpense" element={<AdminAddExpense />} />

                        {/* Customer Routes */}
                        <Route path="/customer/dashboard" element={<CustomerDashboard setToken={this.setToken} />} />
                        <Route path="/customer/events" element={<CustomerEvents setToken={this.setToken} />} />
                        <Route path="/customer/events/:eventId" element={<CustomerEventSingle setToken={this.setToken} />} />

                        {/* Catch-all route for 404 */}
                        <Route path="*" element={<NoPage />} />
                    </Routes>
                </TabProvider>
            </>
        );
    }
}