import React from 'react';
import donationbg from '../images/donation.png';
import Donation from '../components/Donation';
import Navbar from '../components/Navbar';


export default function Guest() {
     return (
          <>
               <Navbar />
               <div className="donation">
                    <div className="row no-gutters w-100">
                         <div className="bg-left col-lg-6 donation-left">


                              <img className="bottom-image2" src={donationbg} alt="Kids" />

                         </div>
                         <div className="bg-right col-lg-6 donation-right">
                              <div className="donation-form d-grid">
                                   {/* <div className="mb-20 d-flex jc-c">
                                        <span className="tagline">Guest Donation</span>
                                   </div> */}
                                   <Donation showPersonalDetails={true} showServiceSelection={true} showRythuBandhamEvents={false} showLogin={true} /> {/* Pass both props to show the forms */}

                              </div>
                         </div>
                    </div>
               </div>
          </>
     )
}

