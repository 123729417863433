import React, { useState, useEffect } from 'react';
import AdminNavbar from '../components/adminNavbar';
import { Link, useNavigate } from 'react-router-dom';
import Axios from 'axios'; // Import Axios


const API_URL = 'https://manamvanam.com/api/Expenses';
export default function AdminAddExpense({ setToken }) {
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const [categories, setCategories] = useState([]); // Store categories from the API
    const [allEvents, setAllEvents] = useState([]); // Store all events from the API
    const [filteredEvents, setFilteredEvents] = useState([]); // Filtered events based on service
    const [service, setService] = useState('');
    const [eventId, setEvent] = useState('');

    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        },
    };
     const [formValues, setFormValues] = useState({
          expensetype: '',
          service: '',
          event: '',
          amount: '',
          description: '',
          date: '',
          byWhom: '',
          category: '',
          vendorName: '',
          paymentMethod: '',
          receiptNumber: '',
          attachments: null,
     });

    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        // Fetch categories from the API when the component mounts
        Axios.get('https://manamvanam.com/api/ServiceData')
            .then((response) => {
                setCategories(response.data);
            })
            .catch((error) => {
                console.error('Error fetching categories: ', error);
            });

        // Fetch all events from the API when the component mounts
        Axios.get('https://manamvanam.com/api/events')
            .then((response) => {
                setAllEvents(response.data);
                setFilteredEvents([]); // Initialize filtered events as empty
            })
            .catch((error) => {
                console.error('Error fetching events: ', error);
            });
    }, []);

    const handleServiceChange = (e) => {
        setService(e.target.value);
        handleChange(e);
        // Filter events based on the selected service
        const selectedServiceEvents = allEvents.filter(
            (event) => event.EventCategoryId.toString() === e.target.value
        );
        setFilteredEvents(selectedServiceEvents);
        // Clear the selected event
        setEvent('');
    };
    const handleEventChange = (e) => {
        setEvent(e.target.value);
        handleChange(e);
    }

     const handleChange = (e) => {
          const { name, value } = e.target;
          setFormValues({
               ...formValues,
               [name]: value,
          });

          // Remove the error message for the corresponding field as the user starts typing
          const updatedErrors = { ...formErrors };
          delete updatedErrors[name];
          setFormErrors(updatedErrors);
     };

     const handleFileChange = (e) => {
          setFormValues({
               ...formValues,
               attachments: e.target.files[0],
          });
     };

     const handleSubmit = (e) => {
          e.preventDefault();
          const errors = {};

          // Validate required fields
          if (!formValues.expensetype) errors.expensetype = 'Select Type is required';
          if (!formValues.service) errors.service = 'Select Service is required';
          if (!formValues.event) errors.event = 'Select Event is required';
          if (!formValues.amount) errors.amount = 'Amount is required';
          if (!formValues.description) errors.description = 'Description is required';
          if (!formValues.date) errors.date = 'Date is required';
          if (!formValues.byWhom) errors.byWhom = 'ByWhom is required';
          if (!formValues.category) errors.category = 'Category is required';
          if (!formValues.vendorName) errors.vendorName = 'Vendor Name is required';
          if (!formValues.paymentMethod) errors.paymentMethod = 'Payment Method is required';
          if (!formValues.receiptNumber) errors.receiptNumber = 'Receipt Number is required';
          if (!formValues.attachments) errors.attachments = 'Attachments is required';

          setFormErrors(errors);

          if (Object.keys(errors).length === 0) {
               // Form is valid, you can proceed with submitting the data
               // You can make an API call or handle form submission here
              console.log('Form is valid. Submitting data:', formValues);
              const submitExpenseData = new FormData();
             
              submitExpenseData.append('expenseType', formValues.expensetype);
              submitExpenseData.append('serviceId', formValues.service);
              submitExpenseData.append('eventId', formValues.event);
              submitExpenseData.append('amount', formValues.amount);
              submitExpenseData.append('description', formValues.description);
              submitExpenseData.append('byWhom', formValues.byWhom);
              submitExpenseData.append('category', formValues.category);

              submitExpenseData.append('vendorName', formValues.vendorName);
              submitExpenseData.append('paymentMethod', formValues.paymentMethod);
              submitExpenseData.append('receiptNumber', formValues.receiptNumber);
              submitExpenseData.append('expenseDate', formValues.date);
              submitExpenseData.append('expenseFileData', formValues.attachments);
              Axios.post(`${API_URL}`, submitExpenseData, {
                  headers: {
                      'Content-Type': 'multipart/form-data'
                  }
              }).then(response => {
                  console.log(response);
                  if (response != null && response !== undefined && response.status === 200) {
                      if (response.data > 0)
                          navigate('/admin/expense');
                  }
              })
                  .catch(error => {

                  });
          }
     };

     return (
          <>
               <AdminNavbar setToken={setToken} />
               <div className="events">
                    <div className="res-container sm-pt-10">
                         <div className="card p-0">
                              <div className="row">
                                   <div className="col-md-12 p-0">
                                        <div className="card common-card">
                                             <div className="card-header d-flex jc-sb">
                                                  <h3 className="head m-0">Add Expense</h3>
                                                  <Link to="/admin/expense" className="btn btn-outline">
                                                       Back
                                                  </Link>
                                             </div>
                                             <div className="card-body">
                                                  <form onSubmit={handleSubmit}>
                                                       <div className="row">
                                                            <div className="col-md-3">
                                                                 <div className="form-group">
                                                                      <label>
                                                                           Select Type<span className="red"><strong> *</strong></span>
                                                                      </label>
                                                                      <select
                                                                           className="form-control mb-0"
                                                                           name="expensetype"
                                                                           value={formValues.expensetype}
                                                                           onChange={handleChange}
                                                                      >
                                                                           <option value="">Select Type</option>
                                                                           <option value="1">Expense</option>
                                                                           <option value="2">Spend</option>
                                                                      </select>
                                                                      {formErrors.expensetype && (
                                                                           <div className="text-danger mb-0">{formErrors.expensetype}</div>
                                                                      )}
                                                                 </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                 <div className="form-group">
                                                                      <label>
                                                                           Select Service<span className="red"><strong> *</strong></span>
                                                                      </label>
                                                         <select
                                                             name="service"
                                                                             className="form-control mb-0"
                                                                             value={service}
                                                                             onChange={handleServiceChange}
                                                                         >
                                                                             <option value="">Select Service</option>
                                                                             {categories.map((category) => (
                                                                                 <option key={category.categoryId} value={category.categoryId}>
                                                                                     {category.category}
                                                                                 </option>
                                                                             ))}
                                                                         </select>
                                                                      {formErrors.service && (
                                                                           <div className="text-danger mb-0">{formErrors.service}</div>
                                                                      )}
                                                                 </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                 <div className="form-group">
                                                                      <label>
                                                                           Select Event<span className="red"><strong> *</strong></span>
                                                                      </label>
                                                         <select
                                                             name="event"
                                                                         className="form-control mb-0"
                                                                         value={eventId}
                                                                         onChange={handleEventChange}
                                                                     >
                                                                         <option value="">Select Event</option>
                                                                         {filteredEvents.map((event) => (
                                                                             <option key={event.Id} value={event.Id}>
                                                                                 {event.EventName}
                                                                             </option>
                                                                         ))}
                                                                     </select>
                                                                      {formErrors.event && (
                                                                           <div className="text-danger mb-0">{formErrors.event}</div>
                                                                      )}
                                                                 </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                 <div className="form-group">
                                                                      <label>
                                                                           Amount<span className="red"><strong> *</strong></span>
                                                                      </label>
                                                                      <input
                                                                           type="text"
                                                                           className="form-control mb-0"
                                                                           name="amount"
                                                                           value={formValues.amount}
                                                                           onChange={handleChange}
                                                                      />
                                                                      {formErrors.amount && (
                                                                           <div className="text-danger mb-0">{formErrors.amount}</div>
                                                                      )}
                                                                 </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                 <div className="form-group">
                                                                      <label>
                                                                           Description<span className="red"><strong> *</strong></span>
                                                                      </label>
                                                                      <input
                                                                           type="text"
                                                                           className="form-control mb-0"
                                                                           name="description"
                                                                           value={formValues.description}
                                                                           onChange={handleChange}
                                                                      />
                                                                      {formErrors.description && (
                                                                           <div className="text-danger mb-0">{formErrors.description}</div>
                                                                      )}
                                                                 </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                 <div className="form-group">
                                                                      <label>
                                                                           Date<span className="red"><strong> *</strong></span>
                                                                      </label>
                                                                      <input
                                                                           type="date"
                                                                           className="form-control mb-0"
                                                                           name="date"
                                                                           value={formValues.date}
                                                                           onChange={handleChange}
                                                                      />
                                                                      {formErrors.date && (
                                                                           <div className="text-danger mb-0">{formErrors.date}</div>
                                                                      )}
                                                                 </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                 <div className="form-group">
                                                                      <label>
                                                                           ByWhom<span className="red"><strong> *</strong></span>
                                                                      </label>
                                                                      <input
                                                                           type="text"
                                                                           className="form-control mb-0"
                                                                           name="byWhom"
                                                                           value={formValues.byWhom}
                                                                           onChange={handleChange}
                                                                      />
                                                                      {formErrors.byWhom && (
                                                                           <div className="text-danger mb-0">{formErrors.byWhom}</div>
                                                                      )}
                                                                 </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                 <div className="form-group">
                                                                      <label>
                                                                           Category<span className="red"><strong> *</strong></span>
                                                                      </label>
                                                                      <input
                                                                           type="text"
                                                                           className="form-control mb-0"
                                                                           name="category"
                                                                           value={formValues.category}
                                                                           onChange={handleChange}
                                                                      />
                                                                      {formErrors.category && (
                                                                           <div className="text-danger mb-0">{formErrors.category}</div>
                                                                      )}
                                                                 </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                 <div className="form-group">
                                                                      <label>
                                                                           Vendor Name<span className="red"><strong> *</strong></span>
                                                                      </label>
                                                                      <input
                                                                           type="text"
                                                                           className="form-control mb-0"
                                                                           name="vendorName"
                                                                           value={formValues.vendorName}
                                                                           onChange={handleChange}
                                                                      />
                                                                      {formErrors.vendorName && (
                                                                           <div className="text-danger mb-0">{formErrors.vendorName}</div>
                                                                      )}
                                                                 </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                 <div className="form-group">
                                                                      <label>
                                                                           Payment Method<span className="red"><strong> *</strong></span>
                                                                      </label>
                                                                      <input
                                                                           type="text"
                                                                           className="form-control mb-0"
                                                                           name="paymentMethod"
                                                                           value={formValues.paymentMethod}
                                                                           onChange={handleChange}
                                                                      />
                                                                      {formErrors.paymentMethod && (
                                                                           <div className="text-danger mb-0">{formErrors.paymentMethod}</div>
                                                                      )}
                                                                 </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                 <div className="form-group">
                                                                      <label>
                                                                           Receipt Number<span className="red"><strong> *</strong></span>
                                                                      </label>
                                                                      <input
                                                                           type="text"
                                                                           className="form-control mb-0"
                                                                           name="receiptNumber"
                                                                           value={formValues.receiptNumber}
                                                                           onChange={handleChange}
                                                                      />
                                                                      {formErrors.receiptNumber && (
                                                                           <div className="text-danger mb-0">{formErrors.receiptNumber}</div>
                                                                      )}
                                                                 </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                 <div className="form-group">
                                                                      <label>
                                                                           Attachments<span className="red"><strong> *</strong></span>
                                                                      </label>
                                                                      <input
                                                                           type="file"
                                                                           className="form-control mb-0"
                                                                           name="attachments"
                                                                           onChange={handleFileChange}
                                                                      />
                                                                      {formErrors.attachments && (
                                                                           <div className="text-danger mb-0">{formErrors.attachments}</div>
                                                                      )}
                                                                 </div>
                                                            </div>
                                                            <div className="col-md-9 text-right">
                                                                 <button type="submit" className="btn btn-primary mt-20">
                                                                      Save
                                                                 </button>
                                                            </div>
                                                       </div>
                                                  </form>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </>
     );
}
