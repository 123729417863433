import React, { useState, useEffect } from 'react'; // Import useState and useEffect
import Navbar from '../components/Navbar';
import Donation from '../components/Donation';
import view from '../images/view.png';
import video from '../images/video.png';
import location from '../images/location.png';
import { NavLink } from 'react-router-dom'; // Update the import statement
import serviceData from '../components/services_data.json';
import eventsData from '../components/events.json';
import { Link } from 'react-router-dom'; // Import Link from React Router
import ImageModal from '../components/ImageModal'; // Import the ImageModal component
import VideoModal from '../components/VideoModal'; // Import the VideoModal component
import Labelicon from '../images/label.png';
import play from '../images/play.png';


import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';

export default function ParyavaranamBandham() {
     // Filter the services with category 'paryavaranambandham'
     const paryavaranamBandhamServices = serviceData.services.filter(
          (service) => service.category === 'Paryavaranambandham'
     );

     // Combine the "Spent" and "Raised" data from all services
     const spentData = paryavaranamBandhamServices.reduce((total, service) => {
          return total.map((val, index) => val + service.spend_data.datasets[0].data[index]);
     }, paryavaranamBandhamServices[0].spend_data.datasets[0].data.map((val) => 0));

     const raisedData = paryavaranamBandhamServices.reduce((total, service) => {
          return total.map((val, index) => val + service.raised_data.datasets[0].data[index]);
     }, paryavaranamBandhamServices[0].raised_data.datasets[0].data.map((val) => 0));

     const data = {
          labels: paryavaranamBandhamServices[0].spend_data.labels,
          datasets: [{
               label: 'Spent',
               data: spentData,
               backgroundColor: '#7fb432',
               hoverBackgroundColor: '#7fb432',
               barThickness: 10,
          },
          {
               label: 'Raised',
               data: raisedData,
               backgroundColor: '#f7b70d',
               hoverBackgroundColor: '#f7b70d',
               barThickness: 10,
          }],
     };

     const options = {
          plugins: {
               datalabels: {
                    display: true,
                    color: 'white',
                    font: {
                         weight: 'bold',
                    },
                    formatter: (value) => (value >= 1000 ? `${value / 1000}K` : value),

               },
               legend: {
                    display: false,
               },
          },

          elements: {
               bar: {
                    borderRadius: {
                         topLeft: 25,
                         topRight: 25,
                    },
                    borderSkipped: 'bottom', // Skip the bottom border to achieve rounded top corners

               },
          },
     };

     const legendItems = data.datasets.map((dataset, index) => (
          <li key={index}>
               <span
                    style={{
                         display: 'inline-block',
                         width: '12px',
                         height: '12px',
                         backgroundColor: dataset.backgroundColor,
                         marginRight: '5px',
                    }}
               ></span>
               {dataset.label}
          </li>
     ));

     // Inside your component function
     const [showVideoModal, setShowVideoModal] = useState(false);
     const [selectedVideo, setSelectedVideo] = useState(null);

     const openVideoModal = (video) => {
          setSelectedVideo(video);
          setShowVideoModal(true);
     };

     const closeVideoModal = () => {
          setShowVideoModal(false);
          setSelectedVideo(null);
     };

     const compareEventDates = (event1, event2) => {
          const date1 = new Date(event1.eventDate);
          const date2 = new Date(event2.eventDate);
          return date1 - date2;
     };


     // Filter events with category "solution2"
     const solution2Events = eventsData.events.filter((event) => event.eventCategory === 'solution5');




     // Inside your component function
     const [sortedEvents, setSortedEvents] = useState([]); // State to store sorted events

     // Sort the events based on event dates
     useEffect(() => {
          const sorted = [...solution2Events].sort(compareEventDates);
          setSortedEvents(sorted);
     }, [solution2Events]);

     // Split sorted events into chunks of 2 for the carousel
     const chunkSize = 2;
     const eventChunks = [];
     for (let i = 0; i < sortedEvents.length; i += chunkSize) {
          eventChunks.push(sortedEvents.slice(i, i + chunkSize));
     }


     const formatDate = (dateString) => {
          const date = new Date(dateString);
          const monthNames = [
               'Jan', 'Feb', 'March', 'April', 'May', 'June', 'July',
               'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
          ];
          const month = monthNames[date.getMonth()];
          const day = String(date.getDate()).padStart(2, '0');
          const year = date.getFullYear();
          return { month, day, year };
     };



     const itemsPerPage = 6;
     const [currentPage, setCurrentPage] = useState(1);
     const [showImageModal, setShowImageModal] = useState(false);
     const [selectedImage, setSelectedImage] = useState(null);
     const videosPerPage = 2; // Number of videos to display per page

     // Filter events with category "solution2"
     // Filter events with category "solution2"
     // Filter events with category "solution2"
     const filteredSolution2Events = eventsData.events.filter(
          (event) => event.eventCategory === 'solution5'
     );

     // Flatten the array of video objects from filteredSolution2Events
     const solution2Videos = filteredSolution2Events.flatMap((event) => event.eventGalleryVideos);

     // Calculate the start and end positions for the current page
     const startPosition = (currentPage - 1) * videosPerPage;
     const endPosition = startPosition + videosPerPage;

     // Create an array of videos to display for the current page
     const videosToDisplay = solution2Videos.slice(startPosition, endPosition);

     // Calculate the total number of pages
     const numberOfPages = Math.ceil(solution2Videos.length / videosPerPage);

     const openImageModal = (image) => {
          const parentEvent = solution2Events.find((event) =>
               event.eventGalleryImages.some((img) => img.id === image.id)
          );

          setSelectedImage({
               path: image.path,
               headline: image.headline,
               eventCategory: parentEvent.eventCategory,
               eventName: parentEvent.eventName,
               id: parentEvent.id,
          });
          setShowImageModal(true);
     };





     const closeImageModal = () => {
          setSelectedImage(null);
          setShowImageModal(false);
     };

     const startIndex = (currentPage - 1) * itemsPerPage;
     const endIndex = startIndex + itemsPerPage;

     const imagesToDisplay = solution2Events
          .flatMap((event) => event.eventGalleryImages)
          .slice(startIndex, endIndex);

     const totalPages = Math.ceil(
          solution2Events.flatMap((event) => event.eventGalleryImages).length / itemsPerPage
     );

     const handlePageChange = (newPage) => {
          setCurrentPage(newPage);
     };

     return (
          <>
               <Navbar />
               <div className="solution-page events d-grid">
                    <div className="text-left">
                         <div className="res-container">
                              <div className="row">
                                   <div className="col-md-4">
                                        <div className="row">
                                             <h1 className="head m-0">Paryavaranam Bandham</h1>
                                             {paryavaranamBandhamServices.map((service, index) => (
                                                  <div key={index}>
                                                       <p>{service.service_tagline}</p>
                                                  </div>
                                             ))}
                                        </div>
                                        <div className="row form-group">
                                             {paryavaranamBandhamServices.map((service, index) => (
                                                  <div className="common-card text-center" key={index}>
                                                       <img src={service.service_banner_image} alt={service.service_headline} className="service-banner" />
                                                  </div>
                                             ))}
                                        </div>
                                        <div className="row">
                                             <div className='card common-card'>
                                                  <div className="card-header">
                                                       <h4 className="head m-0">Spend & Raise Graph</h4>
                                                  </div>
                                                  <div className="card-body">
                                                       <div id="spent" className="chart-container">
                                                            <Bar data={data} options={options} />
                                                            <ul style={{ listStyle: 'none', padding: '0', marginBottom: '0' }}>{legendItems}</ul>
                                                       </div>

                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                                   <div className="col-md-4">
                                        <div className="card-mid">
                                             <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                  <li className="nav-item" role="presentation">
                                                       <a className="nav-link active" id="events-tab" data-bs-toggle="tab" href="#events" role="tab" aria-controls="events" aria-selected="true"><h4 className="head">Events</h4></a>
                                                  </li>
                                                  <li className="nav-item" role="presentation">
                                                       <a className="nav-link" id="description-tab" data-bs-toggle="tab" href="#description" role="tab" aria-controls="description" aria-selected="false"><h4 className="head">Description</h4></a>
                                                  </li>
                                                  <li className="nav-item" role="presentation">
                                                       <a className="nav-link" id="image-tab" data-bs-toggle="tab" href="#image" role="tab" aria-controls="image" aria-selected="false"><h4 className="head">Images</h4></a>
                                                  </li>
                                                  <li className="nav-item" role="presentation">
                                                       <a className="nav-link" id="video-tab" data-bs-toggle="tab" href="#video" role="tab" aria-controls="video" aria-selected="false"><h4 className="head">Video</h4></a>
                                                  </li>
                                             </ul>
                                             <div className="tab-content" id="myTabContent">
                                                  <div className="tab-pane fade show active pt-20" id="events" role="tabpanel" aria-labelledby="events-tab">
                                                       {eventChunks.length === 1 ? (
                                                            // If there are only 1 or 2 events, display them normally
                                                            eventChunks[0].map((event) => (
                                                                 <>
                                                                      <div className="card mb-20 o-hidden">
                                                                           <div className="card-header o-hidden event-img2" style={{ backgroundImage: `url(${event.eventImage})` }}>
                                                                                {/* <div className="overlay-video">
                                                                                     <img src={video} alt="Video" />
                                                                                </div> */}
                                                                                <div className="overlay">
                                                                                     <div className="row">
                                                                                          {/* <div className="col text-center">
                                                                                               <p className="cust-time">
                                                                                                    <img src={view} alt="View" className="mr-5" />
                                                                                                    {event.eventStartTime}-{event.eventEndTime}
                                                                                               </p>
                                                                                          </div> */}
                                                                                          <div className="col  text-center col-middle">
                                                                                               <p className="card-text date-p">
                                                                                                    <span className="head">{formatDate(event.eventDate).month}</span>
                                                                                                    <h1 className="head">{formatDate(event.eventDate).day}</h1>
                                                                                                    <span className="head">{formatDate(event.eventDate).year}</span>
                                                                                               </p>
                                                                                          </div>
                                                                                          {/* <div className="col text-center">
                                                                                               <p className="cust-location">
                                                                                                    <img src={location} alt="View" className="mr-5" />
                                                                                                    {event.eventCity}
                                                                                               </p>
                                                                                          </div> */}
                                                                                     </div>
                                                                                </div>
                                                                                {/* <img src={event.eventImage} className="card-img-top" alt={event.eventName} /> */}
                                                                                <div className="overlay2"></div>
                                                                           </div>

                                                                           <div className="card-body">
                                                                                <h4 className="card-title">{event.eventName}</h4>
                                                                                <p>{event.eventMotto}</p>
                                                                                <Link to={`/events/${event.id}`} className="btn btn-tertiary">
                                                                                     <img src={view} alt="View" />
                                                                                </Link>
                                                                           </div>
                                                                      </div>
                                                                      {/* <div className="text-center">
                                                                           <NavLink className="btn btn-primary btn-sm" to="/events">View All</NavLink>
                                                                      </div> */}
                                                                 </>
                                                            ))
                                                       ) : (
                                                            // If there are more than 2 events, display them in a carousel
                                                            <div id="eventCarousel" className="carousel slide" data-bs-ride="carousel">
                                                                 <div className="carousel-inner">
                                                                      {eventChunks.map((events, index) => (
                                                                           <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}>
                                                                                {events.map((event) => (
                                                                                     <div className="card mb-20 o-hidden">
                                                                                          <div className="card-header o-hidden event-img2" style={{ backgroundImage: `url(${event.eventBanner})` }}>
                                                                                               {/* <div className="overlay-video" onClick={() => openVideoModal(event.eventVideos[0])}>
                                                                                                    <img src={video} alt="Video" />
                                                                                               </div> */}
                                                                                               <div className="overlay">
                                                                                                    <div className="row">
                                                                                                         {/* <div className="col text-center">
                                                                                                              <p className="cust-time">
                                                                                                                   <img src={view} alt="View" className="mr-5" />
                                                                                                                   {event.eventStartTime}
                                                                                                              </p>
                                                                                                         </div> */}
                                                                                                         <div className="col  text-center col-middle">
                                                                                                              <p className="card-text date-p">
                                                                                                                   <span className="head">{formatDate(event.eventDate).month}</span>
                                                                                                                   <h1 className="head">{formatDate(event.eventDate).day}</h1>
                                                                                                                   <span className="head">{formatDate(event.eventDate).year}</span>
                                                                                                              </p>
                                                                                                         </div>
                                                                                                         {/* <div className="col text-center">
                                                                                                              <p className="cust-location">
                                                                                                                   <img src={location} alt="View" className="mr-5" />
                                                                                                                   {event.eventCity}
                                                                                                              </p>
                                                                                                         </div> */}
                                                                                                    </div>
                                                                                               </div>
                                                                                               {/* <img src={event.eventBanner} className="card-img-top" alt={event.eventName} /> */}
                                                                                               <div className="overlay2"></div>
                                                                                          </div>

                                                                                          <div className="card-body">
                                                                                               <h4 className="card-title">{event.eventName}</h4>
                                                                                               <p>{event.eventMotto}</p>
                                                                                               <Link to={`/events/${event.id}`} className="btn btn-tertiary">
                                                                                                    <img src={view} alt="View" />
                                                                                               </Link>
                                                                                          </div>
                                                                                     </div>
                                                                                ))}
                                                                           </div>
                                                                      ))}
                                                                 </div>
                                                                 <div className="d-flex form-group m-0 solution-controls ">
                                                                      <div className="carousel-outer d-flex jc-c">
                                                                           <button className="carousel-control-prev" type="button" data-bs-target="#eventCarousel" data-bs-slide="prev">
                                                                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                                <span className="visually-hidden">Previous</span>
                                                                           </button>
                                                                           {/* <div className="carousel-indicators solution-indicators">
                                                                           {eventChunks.map((events, index) => (
                                                                                <button
                                                                                     key={index}
                                                                                     type="button"
                                                                                     data-bs-target="#eventCarousel"
                                                                                     data-bs-slide-to={index}
                                                                                     className={index === 0 ? "active" : ""}
                                                                                     aria-current={index === 0 ? "true" : "false"}
                                                                                     aria-label={`Slide ${index + 1}`}
                                                                                ></button>
                                                                           ))}
                                                                      </div> */}
                                                                           {/* <NavLink className="btn btn-primary btn-sm" to="/events">View All</NavLink> */}
                                                                           <button className="carousel-control-next" type="button" data-bs-target="#eventCarousel" data-bs-slide="next">
                                                                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                                                <span className="visually-hidden">Next</span>
                                                                           </button>
                                                                      </div>

                                                                 </div>
                                                            </div>
                                                       )}
                                                  </div>
                                                  <div className="tab-pane fade pt-20" id="description" role="tabpanel" aria-labelledby="description-tab">
                                                       {paryavaranamBandhamServices.map((service, index) => (
                                                            <div className="" key={index}>
                                                                 <p style={{ textAlign: 'justify' }}>{service.service_description}</p>
                                                            </div>
                                                       ))}
                                                  </div>
                                                  <div className="tab-pane fade pt-20" id="image" role="tabpanel" aria-labelledby="image-tab">
                                                       <div className="gallery-grid">
                                                            <div className="row" style={{ width: '100%' }}>
                                                                 {imagesToDisplay.map((image) => (
                                                                      <div className="col-6 gallery-outer4" key={image.id}>
                                                                           <div
                                                                                className="gallery-image4"
                                                                                style={{ backgroundImage: `url(${image.path})` }}
                                                                                onClick={() => openImageModal(image)}
                                                                           ></div>
                                                                      </div>
                                                                 ))}
                                                            </div>

                                                       </div>
                                                       <div className="pagination">
                                                            {Array.from({ length: totalPages }, (_, index) => (
                                                                 <button
                                                                      key={index}
                                                                      className={`page-button ${index + 1 === currentPage ? 'active' : ''}`}
                                                                      onClick={() => handlePageChange(index + 1)}
                                                                 >
                                                                      {index + 1}
                                                                 </button>
                                                            ))}
                                                       </div>
                                                  </div>
                                                  <ImageModal
                                                       showModal={showImageModal}
                                                       selectedImage={selectedImage}
                                                       closeModal={closeImageModal}
                                                  />




                                                  <div className="tab-pane fade pt-20" id="video" role="tabpanel" aria-labelledby="video-tab">
                                                       {videosToDisplay.length === 0 ? (
                                                            <p>No videos to display.</p>
                                                       ) : (
                                                            videosToDisplay.map((video, index) => {
                                                                 const parentEvent = filteredSolution2Events.find(
                                                                      (event) => event.eventGalleryVideos.includes(video)
                                                                 );

                                                                 return (
                                                                      <div className="col-12" key={index}>
                                                                           <div className="card mb-20 o-hidden">
                                                                                <div className="video-bg" style={{ backgroundImage: `url(${parentEvent.eventBanner})` }}>
                                                                                     <button className="btn-transparent" onClick={() => openVideoModal(video)}>
                                                                                          <img src={play} />
                                                                                     </button>
                                                                                </div>
                                                                                <div className="gallery-text">
                                                                                     <p className="green mb-0">
                                                                                          <img src={Labelicon} className="label-icon mr-5" />
                                                                                          Category: {video.eventCategory}
                                                                                     </p>
                                                                                     <h4 className="head mb-5">{video.headline}</h4>
                                                                                     <p>Event Name: {parentEvent.eventName}</p>
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 );
                                                            })
                                                       )}
                                                       <div className="pagination">
                                                            {Array.from({ length: numberOfPages }, (_, index) => (
                                                                 <button
                                                                      key={index}
                                                                      className={`page-button ${index + 1 === currentPage ? 'active' : ''}`}
                                                                      onClick={() => handlePageChange(index + 1)}
                                                                 >
                                                                      {index + 1}
                                                                 </button>
                                                            ))}
                                                       </div>
                                                  </div>

                                                  <VideoModal showModal={showVideoModal} selectedVideo={selectedVideo} closeModal={closeVideoModal} />
                                             </div>
                                        </div>
                                   </div>
                                   <div className="col-md-4">
                                        <Donation showPersonalDetails={true} showParyavaranamBandhamEvents={true} showLogin={true} /> {/* Pass both props to show the forms */}
                                   </div>
                              </div>
                         </div>
                    </div>
               </div >
          </>
     );
}
