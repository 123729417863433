import { createContext, useContext, useState } from 'react';

const TabContext = createContext();

export function useTabContext() {
     return useContext(TabContext);
}

export function TabProvider({ children }) {
     const [activeTab, setActiveTab] = useState('home');
     

     const handleTabSelect = (eventKey) => {
          setActiveTab(eventKey);
     };

     return (
         <TabContext.Provider value={{ activeTab, handleTabSelect}}>
               {children}
          </TabContext.Provider>
     );
}
