import React from 'react';
import { useNavigate } from 'react-router-dom';
import FNF from '../images/404.png';

export default function NoPage() {
     const navigate = useNavigate();

     const handleGoBack = () => {
          navigate(-1);
     };

     return (
          <>
               <div className="new-page d-grid">
                    <div className="text-center">
                         <img src={FNF} alt="404" className="mb-10" width="500" />
                         <h1 className="head mb-30">Error 404: Not Found</h1>
                         <button className="btn btn-primary" onClick={handleGoBack}>
                              Go Back
                         </button>
                    </div>
               </div>

               <h2></h2>
          </>
     );
}
