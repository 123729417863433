import React, { useState, useRef } from 'react';
import kids from '../images/kids.png';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import spinner from '../images/spinner.gif';

// Authentication API URL
const API_URL = 'https://manamvanam.com/api/Auth';

const LoginContent = ({ onToggle, setToken, setUser }) => {
    const navigate = useNavigate();
    const inputs = useRef([]);
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [otp, setOtp] = useState('');
    const [tokenId, setTokenId] = useState('');
    const [showOtpInput, setShowOtpInput] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({
        email: '',
        password: '',
        otp: '',
        loginMessage: ''
    });

    const handleKeyPress = (e) => {
        // Allow only numeric characters (0-9) and Backspace
        if (!/^\d$/.test(e.key) && e.key !== 'Backspace') {
            e.preventDefault();
        }
    };

    const handleGetOTP = async () => {
        const emailError = validateInput('email', email);
        const passwordError = validateInput('password', pass);

        if (emailError || passwordError) {
            setErrors({
                email: emailError,
                password: passwordError
            });
            return;
        }
        try
        {
            setIsLoading(true); 
            const response = await axios.post(`${API_URL}/login`, { Username: email, Password: pass, OTP: otp });
            const usertokenid = response.data;

            if (!usertokenid && usertokenid < 0) {
                setErrors({ loginMessage: 'Invalid Username, Password' });
                return;
            }
            setTokenId(usertokenid);
            // Logic to request OTP goes here
            setShowOtpInput(true);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setErrors({ loginMessage: 'Invalid Username, Password' });
            } else {
                setErrors({ loginMessage: 'Something went wrong. Please try again later.' });
            }
            console.error('Login failed:', error);
        } finally {
            setIsLoading(false); // Hide loader
        }
    };

    const handleLogin = async () => {
        const otpError = validateInput('otp', otp);

        if (otpError) {
            setErrors({ otp: otpError });
            return;
        }
        setIsLoading(true); // Show loader
        // Proceed with login logic
        try {
            const response = await axios.post(`${API_URL}/verify-otp`, { UserId: tokenId,  OTP: otp });
            const token = response.data.Token;
            if (!token) {
                setErrors({ loginMessage: 'Invalid Username, Password, or OTP' });
                return;
            }

            setToken(token);
            const user = jwtDecode(token);
            const roleClaim = user['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
            const emailClaim = user['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'];
            const givenNameClaim = user['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'];
            const nameIdentifierClaim = user['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
            const surnameClaim = user['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname'];
            const userDetails = {
                Email: emailClaim,
                FirstName: givenNameClaim,
                LastName: surnameClaim,
                UserId: nameIdentifierClaim,
                Role: roleClaim
            };
            setUser(JSON.stringify(userDetails));

            console.log(user);
            if (roleClaim === "2") {
                navigate('/customer/dashboard');
            } else {
                navigate('/admin/dashboard');
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setErrors({ loginMessage: 'Invalid Username, Password, or OTP' });
            } else {
                setErrors({ loginMessage: 'Something went wrong. Please try again later.' });
            }
            console.error('Login failed:', error);
        } finally {
            setIsLoading(false); // Hide loader
        }
    };

    const validateInput = (name, value) => {
        const errorMessages = {
            email: 'Invalid email address.',
            password: 'Password is required',
            otp: 'OTP is required.'
        };

        switch (name) {
            case 'email':
                if (!/\S+@\S+\.\S+/.test(value)) {
                    return errorMessages.email;
                }
                break;
            case 'password':
                if (!value.trim()) {
                    return errorMessages.password;
                }
                break;
            case 'otp':
                if (!value.trim()) {
                    return errorMessages.otp;
                }
                break;
            default:
                break;
        }
        return '';
    };

    const handleOtpChange = (index, value) => {
        setOtp(prevOtp => {
            const newOtp = prevOtp.split('');
            newOtp[index] = value;
            return newOtp.join('');
        });

        if (value && index < inputs.current.length - 1) {
            inputs.current[index + 1].focus();
        } else if (!value && index > 0) {
            inputs.current[index - 1].focus();
        }
    };

    const handleBackButtonClick = () => {
        setShowOtpInput(false);
        setOtp('');
        setErrors({
            email: '',
            password: '',
            otp: '',
            loginMessage: ''
        });
    };

    return (
        <>
            {isLoading && (
                <div className="loader-main">
                    <div className="loader-sub">
                        <img src={spinner} alt="Loading..." />
                    </div>
                </div>
            )}
            <div className="row no-gutters w-100">
                <div className="bg-left col-lg-6">
                    <div className="background-image" >
                        <h3 className="centered-h2">"It's not how much you give, but how much love we put into giving"</h3>
                        <p className="centered-p"><b>- Mother Teresa</b></p>
                        <img className="bottom-image" src={kids} alt="Kids" />
                    </div>
                </div>
                <div className="bg-right col-lg-6">
                    <div className="login-form d-grid text-center">
                        <span className="tagline">Donate</span>
                        <h1>Signin to your account</h1>
                        {!showOtpInput && (
                            <>
                                <p className="mb-30">Not a member ? <NavLink className="cust-link" onClick={onToggle}><b>Register Now</b></NavLink></p>
                                <form onSubmit={(e) => { e.preventDefault(); handleGetOTP(); }} className="mb-40">
                                    <div className="form-group text-left credential">
                                        <input value={email} onChange={(e) => setEmail(e.target.value)} type="email"
                                            className={`form-control mb-0 ${errors.email ? 'is-invalid' : ''}`} placeholder="Email" id="email" name="email" autoComplete="off" />
                                        {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                    </div>
                                    <div className="form-group text-left credential">
                                        <input value={pass} onChange={(e) => setPass(e.target.value)} type="password"
                                            className={`form-control mb-0 ${errors.password ? 'is-invalid' : ''}`} placeholder="Password" id="password" name="password" autoComplete="off" />
                                        {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                                    </div>
                                    <div className="d-flex jc-sb">
                                        <NavLink to="/forgetpassword" style={{ textDecoration: "underline", color: 'var(--green)', padding: '10px 0' }}>
                                            Forget Password
                                        </NavLink>
                                        <button className="btn btn-primary" type="submit">
                                            Get OTP
                                        </button>
                                    </div>
                                </form>
                            </>
                        )}
                        {showOtpInput && (
                            <>
                                <form onSubmit={(e) => { e.preventDefault(); handleLogin(); }} className="mb-40">
                                    <label className="mt-30 mb-10">An OTP has been sent your email</label>
                                    <div className="otp-input-container mb-40">
                                        {[...Array(6)].map((_, index) => (
                                            <input
                                                key={index}
                                                ref={(el) => (inputs.current[index] = el)}
                                                type="text"
                                                maxLength="1"
                                                onChange={(e) => handleOtpChange(index, e.target.value)}
                                                onKeyPress={handleKeyPress}
                                                required
                                            />
                                        ))}
                                    </div>
                                    <div className="form-group text-left otp">
                                        {errors.otp && <div className="invalid-feedback" style={{ display: "block" }}>{errors.otp}</div>}
                                    </div>
                                    <div className="d-flex jc-sb">
                                        <NavLink onClick={handleBackButtonClick} style={{ textDecoration: "underline", color: 'var(--green)', padding: '10px 0' }}>
                                            Back
                                        </NavLink>
                                        <button className="btn btn-primary" type="submit">
                                            Login
                                        </button>
                                    </div>
                                </form>
                            </>
                        )}
                        {errors.loginMessage && <div className="invalid-feedback" style={{ display: "block" }}>{errors.loginMessage}</div>}
                        <div className="row mb-40">
                            <div className="col"><hr /></div>
                            <div className="col-md-2">OR</div>
                            <div className="col"><hr /></div>
                        </div>
                        <div className="row text-center d-flex">
                            <label className="mb-5">Donate as Guest</label>
                            <NavLink to="/guest-login" style={{ textDecoration: "none" }}>
                                <button className="btn btn-outline w-100" type="submit">
                                    Guest
                                </button></NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LoginContent;