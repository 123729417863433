import React, { useState, useEffect } from 'react';
import axios from 'axios';

const API_URL = 'https://manamvanam.com/api/Events';

const Guestt = () => {
     const [events, setEvents] = useState([]);
     const [loading, setLoading] = useState(true);
     const [error, setError] = useState(null);
     const [showAddress, setShowAddress] = useState(false);
     const [selectedCity, setSelectedCity] = useState('');

     useEffect(() => {
          const fetchData = async () => {
               try {
                    const response = await axios.get(API_URL);
                    const eventData = response.data;
                    setEvents(eventData);
                    setLoading(false);
               } catch (error) {
                    setError(error);
                    setLoading(false);
               }
          };

          fetchData();
     }, []);

     const handleCityClick = (city) => {
          setShowAddress(true);
          setSelectedCity(city);
     };

     if (loading) {
          return <div>Loading...</div>;
     }

     if (error) {
          return <div>Error: {error.message}</div>;
     }

     return (
          <div>
               {events.map((singleEvent) => (
                    <div key={singleEvent.Id}>
                         <p>
                              <strong>Event Name: </strong>
                              {singleEvent.EventName}
                         </p>
                         {singleEvent.EventLocations && singleEvent.EventLocations.length > 0 && (
                              <div>
                                   <p>
                                        <strong>Event Address: </strong>
                                   </p>
                                   {singleEvent.EventLocations.map((location, index) => (
                                        <div key={index}>
                                             <p>
                                                  <div onClick={() => handleCityClick(location.city)}>
                                                       {location.city}
                                                  </div>
                                                  {showAddress && selectedCity === location.city && (
                                                       <div>{location.address}</div>
                                                  )}
                                             </p>
                                             {index !== singleEvent.EventLocations.length - 1 && <hr />}
                                        </div>
                                   ))}
                              </div>
                         )}
                         <hr />
                    </div>
               ))}
          </div>
     );
};

export default Guestt;
